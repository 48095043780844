import { createApiSlice } from 'app/createApiSlice'
import { API_CATCHES } from 'utils/apiList'

export const catchesListApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'catchesList/api',
  endpoints(builder) {
    return {
      fetchCatches: builder.query({
        query({ page, s, size = 10, from, to, filterwpp }) {
          return {
            url: API_CATCHES,
            params: {
              page,
              s,
              size,
              from,
              to,
              filterwpp,
            },
          }
        },
      }),
      fetchUserCatches: builder.query({
        query({ id, page = 1, size = 10 }) {
          return {
            url: API_CATCHES,
            params: {
              userId: id,
              page,
              size,
            },
          }
        },
      }),
    }
  },
})

export const {
  useFetchCatchesQuery,
  useLazyFetchCatchesQuery,
  useFetchUserCatchesQuery,
} = catchesListApiSlice
