import BreadCrumbs from 'components/breadcrumbs'
import PMBSelect from 'components/PMBSelect'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Container,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import { useFetchFisheriesQuery } from '../list-fisheries/fisheriesListApiSlice'
import { clearAlert, setAlert } from '../list-fisheries/fisheriesListSlice'
import {
  useCreationFisheriesMutation,
  useFetchAllCompaniesQuery,
  useFetchAllGearsQuery,
  useFetchAllSpeciesQuery,
  useUpdateFisheriesMutation,
} from './formApiSlice'
import { setPayloadSlice } from './formSlice'
import './style.css'

const FormAddFisheries = ({ row, onHandleOpenCloseModalEdit }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { tag } = useSelector((s) => s.fisheriesList)

  const [creationFishery, { isLoading: isLoadingCreate }] =
    useCreationFisheriesMutation()
  const [updateFishery, { isLoading: isLoadingUpdate }] =
    useUpdateFisheriesMutation()

  const [payload, setPayload] = useState({})
  const [isClick, setIsClick] = useState(false)
  const [searchCompany, setSearchCompany] = useState('')
  const [selectedSpecies, setSelectedSpecies] = useState([])
  const [selectedGear, setSelectedGear] = useState(null)
  const [supportingOffices, setSupportingOffices] = useState([{}])

  const { refetch: refetchListFisheries } = useFetchFisheriesQuery({
    page: 0,
    size: 10,
    searchvalue: '',
  })

  // GET ALL SPECIES
  const { data: dataSpecies } = useFetchAllSpeciesQuery()
  // GET ALL GEARS
  const { data: dataGears } = useFetchAllGearsQuery()
  // GET DATA COMPANIES
  const { data: dataCompanies, refetch: refetchCompanies } =
    useFetchAllCompaniesQuery({
      page: 0,
      size: 50,
      searchvalue: searchCompany,
    })

  // HANDLE FOR SEARCHING COMPANY
  const handleCompanySearch = (text) => {
    if (text?.trim() !== '' && text?.length > 3) {
      setSearchCompany(text)
      refetchCompanies()
    } else {
      setSearchCompany('')
      refetchCompanies()
    }
  }

  // HANDLE FOR ADD NEW COMPANY
  const handleAddSupportingOffice = () => {
    setSupportingOffices([...supportingOffices, {}])
  }

  // HANDLE CHANGES ON DROPDOWN OPTION BASED ON INDEX
  const handleSupportingOfficeChange = (selectedOption, index) => {
    const updatedOffices = [...supportingOffices]
    updatedOffices[index] = selectedOption
    setSupportingOffices(updatedOffices)
  }

  // REMOVE COMPANY BASED ON INDEX
  const handleRemoveSupportingOffice = (index) => {
    const updatedOffices = [...supportingOffices]
    updatedOffices.splice(index, 1)
    setSupportingOffices(updatedOffices)
  }

  // VALIDATE ALREADY SELECTED OR NOT
  const validateSupportingOffices = () => {
    return supportingOffices.some((office) => !office?.value)
  }

  // FILTER REMOVE FROM OPTIONS IF THE DATA ALREADY SELECTED
  const getFilteredOptions = () => {
    const selectedCompanyNames = supportingOffices
      .map((office) => office?.value)
      .filter(Boolean)
    return (
      dataCompanies?.items?.filter(
        (company) => !selectedCompanyNames.includes(company.name),
      ) || []
    )
  }

  const handleHasAnswered = () => {
    const fieldRequireds = ['fisheryCode', 'fisheryName', 'baitSpecies']

    const fieldRequired = fieldRequireds

    const data = fieldRequired?.map((e) => {
      return Object.prototype.hasOwnProperty.call(payload, e) && payload[e]
    })
    return data.every((e) => e)
  }

  const isEdit = row?.id

  const handleSubmit = async () => {
    try {
      const adjustedPayload = {
        code: payload.fisheryCode ?? null,
        name: payload.fisheryName ?? null,
        targetSpecies: selectedSpecies?.length
          ? selectedSpecies?.map((item) => item?.value)?.join(',')
          : null,
        baitSpecies: payload.baitSpecies ?? null,
        gears: selectedGear?.value ?? null,
        supportingOffice: supportingOffices?.length
          ? supportingOffices?.map((item) => item?.value)?.join(',')
          : null,
      }

      const result = row
        ? await updateFishery({ payload: adjustedPayload, id: row?.id })
        : await creationFishery(adjustedPayload)

      if (result?.error) {
        dispatch(
          setAlert({
            type: 'danger',
            message: result?.error?.data?.meta?.message,
          }),
        )
      } else if (!result?.error) {
        if (isEdit) {
          onHandleOpenCloseModalEdit()
        }
        await refetchListFisheries()

        dispatch(
          setAlert({
            type: 'success',
            message: `Fishery berhasil  ${isEdit ? 'dirubah' : 'ditambahkan'}!`,
          }),
        )

        setTimeout(() => {
          dispatch(clearAlert())
        }, 3000)

        navigate('/fisheries')
      }
    } catch (error) {
      console.warn(error)
      dispatch(
        setAlert({
          type: 'danger',
          message: 'Gagal menambahkan fishery!',
        }),
      )
    }
  }

  useMemo(() => {
    if (row) {
      // SET SPECIES
      const speciesArray = row?.targetSpecies?.split(',')?.map((species) => ({
        value: species,
        label: species,
      }))
      setSelectedSpecies(speciesArray || [])
      // SET GEARS
      setSelectedGear({
        value: row?.gears,
        label: row?.gears,
      })
      // SET SUPPORTING OFFICE
      const officesArray = row?.supportingOffice?.split(',')?.map((office) => ({
        value: office,
        label: office,
      }))
      setSupportingOffices(officesArray)

      setPayload(() => ({
        fisheryCode: row?.code ?? '',
        fisheryName: row?.name ?? '',
        baitSpecies: row?.baitSpecies ?? '',
      }))
    }
  }, [row])

  // SPECIES OPTIONS
  const speciesOptions =
    dataSpecies?.items?.map((species) => ({
      value: species.attribute.code,
      label: species.attribute.code,
    })) || []

  // GEARS OPTIONS
  const gearsOptions =
    dataGears?.items?.map((gear) => ({
      value: gear.name,
      label: gear.name,
    })) || []

  const handleSpeciesChange = (selectedOptions) => {
    setSelectedSpecies(selectedOptions)
  }

  const handleGearsChange = (selectedOptions) => {
    setSelectedGear(selectedOptions)
  }

  return (
    <Container className="custom-container d-flex flex-column gap-3 py-3">
      {!isEdit && (
        <BreadCrumbs
          breadCrumbParent2="Data Fishery"
          breadCrumbActive="Tambah Fishery"
        />
      )}

      <Card>
        <CardBody className="d-flex flex-column gap-2">
          <h5 className="mb-2">{isEdit ? 'Ubah Fishery' : 'Tambah Fishery'}</h5>

          <FormGroup>
            <Label className="text-sm">Fishery Code</Label>
            <Input
              placeholder="Masukkan fishery code"
              autoComplete="off"
              name="fisheryCode"
              id="fisheryCode"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.fisheryCode ?? ''}
              invalid={isClick && !payload?.fisheryCode}
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">Fishery Name</Label>
            <Input
              placeholder="Masukkan fishery name"
              autoComplete="off"
              name="fisheryName"
              id="fisheryName"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value?.toUpperCase(),
                }))
              }}
              value={payload?.fisheryName ?? ''}
              invalid={isClick && !payload?.fisheryName}
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">Target Species</Label>
            <PMBSelect
              value={selectedSpecies}
              onChange={handleSpeciesChange}
              options={speciesOptions}
              isMulti={true}
              isInvalid={isClick && selectedSpecies.length === 0}
              placeholder="Pilih target species"
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">Bait Species</Label>
            <Input
              placeholder="Masukkan bait species"
              autoComplete="off"
              name="baitSpecies"
              id="baitSpecies"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.baitSpecies ?? ''}
              invalid={isClick && !payload?.baitSpecies}
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">Gear</Label>
            <PMBSelect
              value={selectedGear}
              onChange={handleGearsChange}
              options={gearsOptions}
              isInvalid={isClick && !selectedGear}
              placeholder="Pilih gear yang digunakan"
            />
          </FormGroup>

          {/* SUPPORTING OFFICE */}
          <FormGroup>
            <Label className="text-sm">Supporting Office</Label>
            {supportingOffices?.map((office, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-between mb-2"
              >
                <div style={{ flexGrow: 1 }}>
                  <PMBSelect
                    value={office}
                    onChange={(selectedOption) =>
                      handleSupportingOfficeChange(selectedOption, index)
                    }
                    options={getFilteredOptions()?.map((company) => ({
                      value: company.name,
                      label: company.name,
                    }))}
                    onInputChange={handleCompanySearch}
                    placeholder="Pilih perusahaan..."
                    isInvalid={isClick && !office?.value}
                  />
                </div>
                <Button
                  color="danger"
                  className="ms-2"
                  onClick={() => handleRemoveSupportingOffice(index)}
                  disabled={supportingOffices.length === 1}
                >
                  X
                </Button>
              </div>
            ))}

            {/* ADD NEW SUPPORTING OFFICE */}
            <Button
              color="primary"
              onClick={handleAddSupportingOffice}
              className="mt-2 text-sm"
              disabled={validateSupportingOffices()}
            >
              Tambah Supporting Office
            </Button>
          </FormGroup>
        </CardBody>
      </Card>

      <span className="text-sm fw-semibold">
        Dengan {isEdit ? 'merubah' : 'mendaftarkan'} fishery, saya menjamin
        kebenaran data yang dimasukkan dan bertanggung jawab atas
        ketidaksesuaian data.
      </span>

      <div className="d-flex gap-3">
        <Button
          disabled={isLoadingCreate || isLoadingUpdate}
          onClick={() => {
            const res = handleHasAnswered()
            setIsClick(true)
            if (res) {
              setIsClick(false)
              dispatch(setPayloadSlice(payload))
              handleSubmit()
            }
          }}
          color="primary"
          block
        >
          {row ? 'Simpan Perubahan' : 'Tambahkan Fishery'}
        </Button>
      </div>
    </Container>
  )
}

export default FormAddFisheries
