import BreadCrumbs from 'components/breadcrumbs'
import { ButtonIcon } from 'components/Buttons'
import CustomAlertMessage from 'components/CustomAlertMessage'
import { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown, Edit, MoreVertical, Search, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from 'reactstrap'
import {
  useDeleteCompanyByIdMutation,
  useFetchCompaniesQuery,
} from './companiesListApiSlice'
import { clearAlert, setAlert, setFetchOption } from './companiesListSlice'
import ModalEdit from './ModalEdit'
import ModalRemove from './ModalRemove'

const CompaniesTable = () => {
  const { tag, alertMessage, alertType } = useSelector((s) => s.companiesList)

  const [deleteCompanyById, { isLoading: isLoadingDelete }] =
    useDeleteCompanyByIdMutation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  let [page, setPage] = useState({ page: tag.page })
  const [dropdownOpenMap, setDropdownOpenMap] = useState({})
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false)
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  let [dataRow, setDataRow] = useState(null)
  const dropdownRef = useRef(null)
  const [search, setSearch] = useState('')
  const [searchTemp, setSearchTemp] = useState('')
  const debounceTimer = useRef(0)

  const onHandleOpenCloseModalEdit = () => {
    setIsOpenModalEdit((prev) => !prev)
  }

  const onHandleOpenCloseModalDelete = () => {
    setIsOpenModalDelete((prev) => !prev)
  }

  const menuItems = [
    {
      label: 'Ubah',
      icon: <Edit size={16} />,
      action: (row) => {
        setDataRow(row)
        onHandleOpenCloseModalEdit()
      },
    },
    {
      label: 'Hapus',
      icon: <Trash2 size={16} />,
      action: (row) => {
        setDataRow(row)
        onHandleOpenCloseModalDelete()
      },
    },
  ]

  const toggleDropdownMenu = (id) => {
    setDropdownOpenMap((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpenMap(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const { data, isLoading, refetch, isSuccess } = useFetchCompaniesQuery({
    page: page?.page,
    size: 10,
    searchvalue: searchTemp,
  })

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const columns = [
    {
      name: 'Id',
      cell: (row) => {
        return <span>{row?.id ?? '-'}</span>
      },
    },
    {
      name: 'Kode',
      cell: (row) => {
        return <span>{row?.code ?? '-'}</span>
      },
    },
    {
      name: 'Nama Perusahaan',
      cell: (row) => {
        return <span>{row?.name ?? '-'}</span>
      },
    },
    {
      name: 'ABBR',
      cell: (row) => {
        return <span>{row?.abbr ?? '-'}</span>
      },
    },
    {
      name: 'IUU',
      cell: (row) => {
        return <span>{row?.iuuList === true ? 'Yes' : 'No'}</span>
      },
    },
    {
      name: 'Code of Conduct',
      cell: (row) => {
        return <span>{row?.codeOfConduct === true ? 'Yes' : 'No'}</span>
      },
    },
    {
      name: '',
      width: '80px',
      cell: (row) => {
        return (
          <div
            style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}
          >
            <Dropdown
              isOpen={dropdownOpenMap[row.id]}
              toggle={() => toggleDropdownMenu(row.id)}
              direction="down"
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpenMap[row.id]}
                style={{ cursor: 'pointer' }}
              >
                <MoreVertical size={20} />
              </DropdownToggle>
              <DropdownMenu className="m-0 p-0" container="body">
                {menuItems.map((item, index) => (
                  <DropdownItem
                    className="my-1"
                    key={index}
                    onClick={() => item.action(row)}
                  >
                    <span>{item.icon}</span>
                    <span className="ms-2 text-sm">{item.label}</span>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    rows: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    table: {
      style: {},
    },
  }

  const onRemoveDataCompanyById = async (id) => {
    try {
      if (!id) {
        dispatch(
          setAlert({
            type: 'danger',
            message: 'Perusahaan gagal dihapus!',
          }),
        )
        return
      }

      const res = await deleteCompanyById({ id }).unwrap()

      if (res) {
        dispatch(
          setAlert({
            type: 'success',
            message: 'Perusahaan berhasil di hapus!',
          }),
        )

        refetch()
        onHandleOpenCloseModalDelete()
      }
    } catch (error) {
      console.warn('Error saat menghapus vessel', error)
      dispatch(
        setAlert({
          type: 'danger',
          message: 'Perusahaan gagal dihapus!',
        }),
      )
      onHandleOpenCloseModalDelete()
    }
  }

  // CLEAR ALERT MESSAGE
  useEffect(() => {
    if (alertMessage) {
      scrollToTop()
      setTimeout(() => {
        dispatch(clearAlert())
      }, 3000)
    }
  }, [alertMessage])

  const handleKeyDown = (e) => {
    if (e.key === ' ' && search.length === 0) {
      e.preventDefault()
    }
  }

  const onHandleSearchChange = (text) => {
    setSearch(text)
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(() => {
      if (text.trim() !== '') {
        setSearchTemp(text)
        refetch()
      } else {
        setSearch('')
        setSearchTemp('')
        refetch()
      }
    }, 3000)
  }

  return (
    <div className="w-100 px-3">
      <BreadCrumbs breadCrumbActive="Data Perusahaan" />

      {/* SHOW ALERT */}
      {alertMessage && (
        <CustomAlertMessage type={alertType} message={alertMessage} />
      )}

      {/* MODAL EDIT */}
      <ModalEdit
        row={dataRow}
        isOpenModalEdit={isOpenModalEdit}
        onHandleOpenCloseModalEdit={onHandleOpenCloseModalEdit}
      />

      {/* MODAL REMOVE */}
      <ModalRemove
        isOpen={isOpenModalDelete}
        onClose={() => onHandleOpenCloseModalDelete()}
        data={dataRow}
        isLoading={isLoadingDelete}
        onRemoveData={(id) => onRemoveDataCompanyById(id)}
      />

      <Card className="my-4">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading || !isSuccess}
            responsive
            persistTableHead
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada daftar perusahaan yang didaftarkan
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <CardFooter
                className="border border-top-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-sm">
                    {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                  </div>
                  <div className="d-flex flex-centered gap-1">
                    <ButtonIcon
                      btnTitle="Sebelumnya"
                      btnProps={{
                        disabled: page.page === 0,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setPage((prev) => ({ page: prev.page - 1 }))
                          dispatch(setFetchOption({ page: page.page - 1 }))
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                    <ButtonIcon
                      btnTitle="Selanjutnya"
                      btnProps={{
                        disabled:
                          page.page ===
                          Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setPage((prev) => ({ page: prev.page + 1 }))
                          dispatch(setFetchOption({ page: page.page + 1 }))
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                  </div>
                </div>
              </CardFooter>
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Perusahaan</h5>

                  <Button
                    onClick={() => navigate('/companies/add')}
                    size="sm"
                    color="primary"
                  >
                    + Tambah
                  </Button>
                </div>

                <div className="d-flex justify-content-between align-items-center my-4">
                  <InputGroup style={{ borderColor: '#D0D5DD' }}>
                    <InputGroupText
                      style={{
                        backgroundColor: 'white',
                        borderRightColor: 'white',
                      }}
                    >
                      <Search color="#667085" size={16} />
                    </InputGroupText>
                    <Input
                      placeholder="Cari nama company..."
                      onChange={(e) => onHandleSearchChange(e.target.value)}
                      onKeyDown={handleKeyDown}
                      style={{ maxWidth: '250px' }}
                      value={search}
                    />
                  </InputGroup>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default CompaniesTable
