import CustomButton from 'components/Buttons/CustomButton'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import { createOrder } from '../sellCommodityAction'
import { toggleModal } from '../sellCommoditySlice'

function ModalTrx() {
  const { isOpenModal, orders } = useSelector((s) => s.sellCommodity)
  const { profile } = useSelector((s) => s.dashboard)
  const dispatch = useDispatch()

  const toggle = useCallback(() => {
    dispatch(toggleModal())
  }, [dispatch])

  const navigate = useNavigate()
  useEffect(() => {
    if (orders.isSuccess) {
      toggle()
      navigate('/sell-commodity-success')
    }
  }, [orders, navigate, toggle])

  const payloadOrderModified = (orders) => {
    return {
      ...orders,
      item: orders?.item?.map((e) => {
        return {
          ...e,
          price: Number(
            e?.price
              ?.replace?.(/\./g, '')
              ?.replace?.(/\,/g, '.')
              ?.slice?.(0, 15),
          ),
          amount: Number(
            e?.amount
              ?.replace?.(/\./g, '')
              ?.replace?.(/\,/g, '.')
              ?.slice?.(0, 15),
          ),
        }
      }),
    }
  }

  const handleSavedContacts = (e) => {
    const saved =
      JSON.parse(localStorage.getItem(`savedContacts-${profile?.userId}`)) ?? []
    let newListComodities = [...saved]

    const updatedOptions = newListComodities.filter(
      (item) => item.userId !== e.userId,
    )

    updatedOptions.unshift(e)

    if (updatedOptions.length > 5) {
      const removedItem = updatedOptions.pop()

      newListComodities = {
        removedItem,
      }
    }

    localStorage.setItem(
      `savedContacts-${profile?.userId}`,
      JSON.stringify(updatedOptions),
    )
  }

  console.log(orders)

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggle}
      centered
      style={{ maxWidth: '400px' }}
    >
      <ModalBody>
        <div className="text-left">
          <h5 className="fw-bold text-md">Buat Transaksi</h5>
          <p className="text-sm">
            Pastikan data yang kamu masukkan sudah benar.
          </p>
        </div>

        <div className="border-0 flex-centered flex-row gap-2 w-100">
          <CustomButton
            color="secondary"
            onClick={() => {
              toggle()
            }}
            outline
            className="w-50"
          >
            <span className="text-normal">Batal</span>
          </CustomButton>
          <CustomButton
            color="primary"
            onClick={() => {
              let payloadOrder = { ...orders }
              delete payloadOrder.step
              delete payloadOrder.isLoading
              dispatch(createOrder(payloadOrderModified(orders)))
              handleSavedContacts(orders?.buyer)
            }}
            className="w-50"
          >
            <span className="text-normal">Lanjutkan</span>
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalTrx
