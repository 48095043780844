import IcCheckCircle from 'assets/icons/ic_check_circle.svg'
import LogoPasmik from 'assets/images/pmg-logo-beta.png'
import axios from 'axios'
import CustomButton from 'components/Buttons/CustomButton'
import { apiEndpoint } from 'configs'
import { useState } from 'react'
import { Circle, Eye, EyeOff, MinusCircle } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from 'reactstrap'
import { URL_FORGET_PASSWORD_STEP_4 } from 'utils/pathUrl'
import './style.scss'

const StepThreeForgetPassword = ({ token }) => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [inputVisibility, setInputVisibility] = useState(false)
  const [inputVisibilityConfirm, setInputVisibilityConfirm] = useState(false)
  const [isPasswordMatch, setIsPasswordMatch] = useState(false)

  const [isOneNumber, setIsOneNumber] = useState(false)
  const [isUpperCasePassword, setIsUpperCasePassword] = useState(false)
  const [isLowerCasePassword, setIsLowerCasePassword] = useState(false)
  const [isMinCharOrMaxChar, setIsMinCharOrMaxChar] = useState(false)
  const [isOneSimbol, setIsOneSimbol] = useState(false)
  const [isEmptyConfirmPassword, setIsEmptyConfirmPassword] = useState(false)

  const [errorMessage, setErroMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const nav = useNavigate()
  // ** Renders Icon Based On Visibility
  const renderIcon = () => {
    const size = 14
    if (inputVisibility === false) {
      return <Eye size={size} />
    } else {
      return <EyeOff size={size} />
    }
  }

  const renderIconConfirm = () => {
    const size = 14
    if (inputVisibilityConfirm === false) {
      return <Eye size={size} />
    } else {
      return <EyeOff size={size} />
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (confirmPassword.length < 8) return setIsEmptyConfirmPassword(true)

    if (
      newPassword === confirmPassword &&
      newPassword.length > 0 &&
      confirmPassword.length > 0
    ) {
      setIsPasswordMatch(true)
      handleChangepassword(newPassword)
    } else {
      setIsPasswordMatch(false)
    }
  }

  const handleChangepassword = async (password) => {
    try {
      setIsLoading(true)
      const res = await axios.post(
        `${apiEndpoint}/api/v1/bussines/public/resetpassword`,
        {
          link: token,
          newpassword: password,
        },
      )
      if (res.status === 200) {
        nav(URL_FORGET_PASSWORD_STEP_4)
      }
    } catch (error) {
      setErroMessage(error.response?.data?.message)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Container fluid>
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Card style={{ maxWidth: '600px', width: '100%' }}>
          <CardBody>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                style={{ width: '100%', maxWidth: '200px', height: 'auto' }}
                src={LogoPasmik}
                alt="PasarMIKRO logo"
              />
            </div>
            <CardTitle className="d-flex justify-content-center mb-4" tag="h4">
              Masukkan password baru anda
            </CardTitle>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Password baru</Label>
                <InputGroup>
                  <Input
                    type={inputVisibility === false ? 'password' : 'text'}
                    minLength={8}
                    value={newPassword}
                    placeholder="Masukkan password baru"
                    onChange={(e) => {
                      setIsPasswordMatch(true)
                      setErroMessage('')
                      setIsEmptyConfirmPassword(false)
                      const value =
                        e.target.value.length > 0
                          ? e.target.value.trim().replace(/\s/g, '')
                          : ''

                      let regexOneNumber = /\d/
                      let regexLowercase = /[a-z]/
                      let regexUppercase = /[A-Z]/
                      let regexMinLenghtAndMaxLenght = /^.{8,50}$/
                      let regexWithoutNumAndAlphabet = /[^a-zA-Z0-9]/
                      if (/['"]/.test(value)) {
                        return
                      }
                      setIsOneNumber(regexOneNumber.test(value))
                      setIsUpperCasePassword(regexUppercase.test(value))
                      setIsLowerCasePassword(regexLowercase.test(value))
                      setIsMinCharOrMaxChar(
                        regexMinLenghtAndMaxLenght.test(value),
                      )
                      setIsOneSimbol(regexWithoutNumAndAlphabet.test(value))

                      setNewPassword(value)
                    }}
                    autoComplete="off"
                    name="password"
                    className="passwd"
                  />

                  <InputGroupText
                    onClick={() => setInputVisibility(!inputVisibility)}
                    style={{ cursor: 'pointer' }}
                    className="passwd"
                  >
                    {renderIcon()}
                  </InputGroupText>
                </InputGroup>
              </FormGroup>

              <div className="row row-cols-1 my-2 mb-3">
                <Col className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                  <InfoValidatePassword
                    icon={
                      isLowerCasePassword ? (
                        <img
                          src={IcCheckCircle}
                          width={14}
                          height={14}
                          alt="checked-password Minimal satu huruf kecil"
                        />
                      ) : (
                        <Circle size={14} />
                      )
                    }
                    title="Minimal satu huruf kecil"
                  />
                </Col>

                <Col className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                  <InfoValidatePassword
                    icon={
                      isUpperCasePassword ? (
                        <img
                          src={IcCheckCircle}
                          width={14}
                          height={14}
                          alt="checked-password Minimal satu huruf besar"
                        />
                      ) : (
                        <Circle size={14} />
                      )
                    }
                    title="Minimal satu huruf besar"
                  />
                </Col>

                <Col className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                  <InfoValidatePassword
                    icon={
                      isMinCharOrMaxChar ? (
                        <img
                          src={IcCheckCircle}
                          width={14}
                          height={14}
                          alt="checked-password Minimal 8 karakter"
                        />
                      ) : (
                        <Circle size={14} />
                      )
                    }
                    title="Minimal 8 karakter"
                  />
                </Col>

                <Col className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                  <InfoValidatePassword
                    icon={
                      isOneNumber ? (
                        <img
                          src={IcCheckCircle}
                          width={14}
                          height={14}
                          alt="checked-password Minimal 1 angka"
                        />
                      ) : (
                        <Circle size={14} />
                      )
                    }
                    title="Minimal 1 angka"
                  />
                </Col>

                <Col className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                  <InfoValidatePassword
                    icon={
                      isOneSimbol ? (
                        <img
                          src={IcCheckCircle}
                          width={14}
                          height={14}
                          alt="checked-password Minimal 1 simbol"
                        />
                      ) : (
                        <Circle size={14} />
                      )
                    }
                    title="Minimal 1 simbol"
                  />
                </Col>
              </div>
              <FormGroup>
                <Label for="examplePassword">Konfirmasi password baru</Label>
                <InputGroup>
                  <Input
                    placeholder="Masukkan ulang password baru"
                    type={
                      inputVisibilityConfirm === false ? 'password' : 'text'
                    }
                    minLength={8}
                    value={confirmPassword}
                    onChange={(e) => {
                      setIsPasswordMatch(true)
                      setErroMessage('')
                      setIsEmptyConfirmPassword(false)

                      const value =
                        e.target.value.length > 0
                          ? e.target.value.trim().replace(/\s/g, '')
                          : ''

                      if (/['"]/.test(value)) {
                        return
                      }
                      setConfirmPassword(value)
                    }}
                    autoComplete="off"
                    name="confirmPassword"
                    invalid={
                      (!isPasswordMatch &&
                        newPassword.length > 0 &&
                        confirmPassword.length > 0) ||
                      isEmptyConfirmPassword
                    }
                    className="step2"
                  />
                  <InputGroupText
                    onClick={() =>
                      setInputVisibilityConfirm(!inputVisibilityConfirm)
                    }
                    style={{ cursor: 'pointer' }}
                    className={`${
                      (!isPasswordMatch &&
                        newPassword.length > 0 &&
                        confirmPassword.length > 0) ||
                      isEmptyConfirmPassword
                        ? 'icon-step-2 step-2-invalid'
                        : 'icon-step-2'
                    }`}
                  >
                    {renderIconConfirm()}
                  </InputGroupText>
                </InputGroup>
                {!isPasswordMatch &&
                  newPassword.length > 0 &&
                  confirmPassword.length > 0 && (
                    <span className="text-danger">
                      Konfirmasi password tidak sama.
                    </span>
                  )}
                {isEmptyConfirmPassword && (
                  <span className="text-sm text-danger">
                    Konfirmasi password belum diisi.
                  </span>
                )}
              </FormGroup>

              <CustomButton
                disabled={
                  newPassword.length < 8 ||
                  !isOneNumber ||
                  !isOneSimbol ||
                  !isLowerCasePassword ||
                  !isUpperCasePassword ||
                  !isMinCharOrMaxChar ||
                  isLoading
                }
                className="my-1 mb-2"
                block
                color="primary"
                type="submit"
              >
                Lanjut
              </CustomButton>
            </Form>

            {errorMessage && (
              <span className="text-sm text-danger">{errorMessage}</span>
            )}
          </CardBody>
        </Card>
      </div>
    </Container>
  )
}

export default StepThreeForgetPassword

const InfoValidatePassword = ({ icon, title }) => {
  return (
    <div
      className="d-flex flex-row justify-content-center align-items-center"
      style={{ gap: '0.25rem' }}
    >
      {icon ? icon : <MinusCircle size={14} />}
      <span>{title}</span>
    </div>
  )
}
