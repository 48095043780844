import CustomButton from 'components/Buttons/CustomButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card, CardBody, Container } from 'reactstrap'
import successTrx from '../../assets/images/success-trx.png'

const SuccesTransaction = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dataState = location.state

  const handleClick = () => {
    navigate('/')
  }

  return (
    <Container fluid className="custom-container mt-5">
      <Card>
        <CardBody>
          <div className="d-flex justify-content-center">
            <img
              style={{ width: '280px', height: '182px' }}
              alt="success transaction"
              src={successTrx}
            />
          </div>
          <h6 style={{ fontWeight: 'bold' }} className="text-center mt-3">
            {dataState?.title
              ? dataState?.title
              : 'Pembayaran anda sudah diterima!'}
          </h6>
          <p className="text-center">
            {dataState?.desc
              ? dataState?.desc
              : 'Silakan tunggu penjual memproses pesanan anda.'}
          </p>
          <div className="d-flex justify-content-center">
            <CustomButton onClick={handleClick} color="primary">
              Kembali ke Beranda
            </CustomButton>
          </div>
        </CardBody>
      </Card>
    </Container>
  )
}

export default SuccesTransaction
