import ILDefaultAva from 'assets/icons/default-ava-profile-user.svg'
import CustomButton from 'components/Buttons/CustomButton'
import InputPhoneNumber from 'components/InputPhoneNumber'
import { useState } from 'react'
import { AlertCircle, CheckCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Badge,
  Card,
  CardText,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Spinner,
} from 'reactstrap'
import { useLazyFetchFarmersQuery } from '../farmers-list/farmersListApiSlice'
import { setBannerMsg, toggleBanner } from '../farmers-list/farmersListSlice'
import {
  useCheckFarmerMutation,
  useInviteFarmerMutation,
} from './checkFarmerApiSlice'

const CheckFarmers = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const dataState = location.state
  const { tag } = useSelector((s) => s.farmersList)

  let [page, setPage] = useState({ page: tag.page })
  const [phoneNumber, setPhoneNumber] = useState('')
  const [dataFarmer, setDataFarmer] = useState(null)
  const [errMsg, setErrMsg] = useState('')
  const [preCheckFarmer, res] = useCheckFarmerMutation()
  const [inviteFarmer, resInvite] = useInviteFarmerMutation()

  const [fetchFarmers] = useLazyFetchFarmersQuery()
  const [fetchPendingFarmers] = useLazyFetchFarmersQuery()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await preCheckFarmer({ identity: phoneNumber }).unwrap()
      if (data?.data?.meta?.error) {
        throw data
      } else {
        setDataFarmer(data)
      }
    } catch (error) {
      setErrMsg(error?.data?.meta?.message)
    }
  }

  const handleInvite = async () => {
    try {
      const { data } = await inviteFarmer({ identity: phoneNumber }).unwrap()
      if (data?.data?.meta?.error) {
        throw data
      } else {
        fetchFarmers({
          page: page?.page,
          status: 'JOIN',
          groupCode: dataState?.cod,
        })
        fetchPendingFarmers({
          page: page?.page,
          status: 'PENDING',
          groupCode: dataState?.code,
        })
        dispatch(toggleBanner())
        dispatch(setBannerMsg('Anggota berhasil ditambahkan.'))
        navigate(`/farmers/groups/${dataState?.code}`)
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.data?.meta?.message)
    }
  }

  return (
    <Container className="custom-container my-3">
      <Card className="p-4" body>
        <CardText tag="h5" className="fw-semibold">
          Tambah Anggota
        </CardText>
        <Form onSubmit={handleSubmit}>
          <FormGroup className="mt-3">
            <Label for="phone" className="text-sm">
              Nomor Telepon
            </Label>
            <InputGroup>
              <InputPhoneNumber
                placeholder="Masukkan nomor telepon"
                value={phoneNumber}
                setValue={(e) => {
                  setPhoneNumber(e)
                  setErrMsg('')
                  setDataFarmer(null)
                }}
                name="phoneNumber"
                id="phoneNumber"
              />

              <CustomButton
                color="transparent"
                className="border text-normal"
                type="submit"
              >
                Cek
              </CustomButton>
            </InputGroup>
            <span className="text-xs">Contoh: 0813xxxx atau 62813xxxx</span>
          </FormGroup>
        </Form>
        {dataFarmer?.status === 'UNREGISTERED' ? (
          <div className="bg-warning-25 rounded p-3 border">
            <p className="text-warning-700 fw-semibold">
              Nomor ini belum memiliki akun
            </p>
            <span>
              Untuk menambahkan nomor ini, anda perlu mendaftarkan akunnya
              dengan mengisi data yang diperlukan di langkah berikutnya.
            </span>
          </div>
        ) : (
          <></>
        )}
        {dataFarmer?.status === 'REGISTERED' ? (
          <>
            <div className="bg-success-25 rounded p-3 border">
              <p className="text-success-700 fw-semibold d-flex align-items-center gap-2">
                <CheckCircle size={20} /> Nomor ini sudah memiliki akun
              </p>
              <Card body style={{ maxHeight: 68 }}>
                <div className="flex-centered justify-content-between">
                  <div className="flex-centered gap-1 ">
                    <img
                      src={dataFarmer?.profileImg ?? ILDefaultAva}
                      alt="ava"
                      width={36}
                      height={35}
                      className="rounded-circle"
                    />
                    <span className="text-sm fw-semibold">
                      {dataFarmer?.name}
                    </span>
                  </div>
                  {dataFarmer?.profileStatus === 'REGISTERED' && (
                    <Badge size="sm" disabled className="badge-verify">
                      Terdaftar
                    </Badge>
                  )}
                  {dataFarmer?.profileStatus === 'VERIFIED' && (
                    <Badge size="sm" disabled className="badge-verify">
                      Terverifikasi
                    </Badge>
                  )}
                  {dataFarmer?.profileStatus === 'Belum Terverifikasi' && (
                    <Badge size="sm" disabled className="badge-warning">
                      {dataFarmer?.profileStatus}
                    </Badge>
                  )}
                </div>
              </Card>
            </div>
            <p className="fw-600 mt-3">Catatan:</p>
            <span>
              Anggota yang sudah memiliki akun akan masuk ke dalam status
              pending terlebih dahulu. Anggota tersebut perlu melakukan
              konfirmasi via WhatsApp agar dapat bergabung secara penuh.
            </span>
          </>
        ) : (
          <></>
        )}
        {errMsg && (
          <div>
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </div>
        )}
        {res?.isLoading ? (
          <div className="w-100 flex-centered">
            <Spinner
              style={{
                height: '3rem',
                width: '3rem',
              }}
              color="primary"
            />
          </div>
        ) : (
          <></>
        )}
      </Card>
      {res?.isLoading ? (
        <></>
      ) : (
        <CustomButton
          onClick={() => {
            if (dataFarmer?.status === 'UNREGISTERED') {
              navigate('/farmers/registrasi', { state: { ...dataState } })
            } else {
              handleInvite()
            }
          }}
          className="mt-4"
          block
          color="primary"
          disabled={
            !phoneNumber ||
            !dataFarmer ||
            resInvite?.isLoading ||
            res?.isLoading
          }
        >
          {dataFarmer?.status === 'UNREGISTERED'
            ? 'Daftarkan Anggota'
            : 'Tambah Anggota'}
        </CustomButton>
      )}
    </Container>
  )
}

export default CheckFarmers
