import BreadCrumbs from 'components/breadcrumbs'
import { ButtonIcon } from 'components/Buttons'
import PMBProgressBarExportFile from 'components/PMBProgressBarExportFile'
import * as FileSaver from 'file-saver'
import moment from 'moment-timezone'
import { useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown, Filter, Search } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
} from 'reactstrap'
import VesselNotFound from '../../../../assets/icons/vessel-not-found.png'
import { useFetchCatchesQuery } from './catchesListApiSlice'
import { setFetchOption, setToggleSidebarFilter } from './catchesListSlice'
import SidebarFilter from './SidebarFilter'

const CatchesTable = () => {
  const { tag, isOpenSidebarFilter } = useSelector((s) => s.catchesList)

  const currentYear = new Date().getFullYear()
  const startDateDefault = new Date(currentYear, 0, 1)
  const endDateDefault = new Date(currentYear, 11, 31)

  const [search, setSearch] = useState('')
  const [selectedDate, setSelectedDate] = useState([
    startDateDefault,
    endDateDefault,
  ])
  const [selectedWPP, setSelectedWPP] = useState(null)
  // STATE DOWNLOAD REPORT
  const [isDialogShow, setIsDialogShow] = useState(false)
  const [firstRows, setFirstRows] = useState(0)
  const [totalRows, setTotalRows] = useState(0)

  const debounceTimer = useRef(0)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  let [page, setPage] = useState({ page: tag.page })

  const { data, isLoading, refetch, isSuccess } = useFetchCatchesQuery({
    page: page?.page,
    s: search,
    size: 10,
    from: moment(selectedDate[0]).format('YYYY-MM-DD') ?? '',
    to: moment(selectedDate[1])?.format('YYYY-MM-DD') ?? '',
    filterwpp: selectedWPP?.map((wpp) => wpp?.value).join(',') ?? '',
  })

  const handleSearchChange = (text) => {
    setSearch(text)
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(() => {
      if (text.trim() !== '') {
        refetch()
      } else {
        setSearch('')
        refetch()
      }
    }, 3000)
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const columns = [
    {
      name: 'Tanggal Perjalanan',
      cell: (row) => {
        const startDate = moment(row?.data?.startFishing).format('DD MMMM YYYY')
        const endDate = moment(row?.data?.endFishing).format('DD MMMM YYYY')
        return (
          <span>
            {startDate ?? ''} - {endDate ?? ''}
          </span>
        )
      },
    },
    {
      name: 'Kapal',
      cell: (row) => {
        return (
          <Row className="align-items-center mb-2 d-flex flex-grow-1">
            <Col xs="3">
              <img
                src={row?.data?.vessel?.linkImage ?? VesselNotFound}
                alt={row?.data?.vessel?.vesselName}
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '4px',
                }}
                loading="lazy"
              />
            </Col>

            <Col className="align-items-center" xs="8">
              <p className="fw-bold text-primary m-0">
                {row?.data?.vessel?.shipName}
              </p>
            </Col>
          </Row>
        )
      },
    },
    {
      name: 'Nama Nelayan',
      cell: (row) => {
        const nelayanName = row?.data?.crews?.length
          ? row.data.crews.map((item) => item.name).join(', ')
          : '-'
        return <span>{nelayanName}</span>
      },
    },
    {
      name: 'Hasil Tangkapan',
      cell: (row) => {
        const fishes = row?.data?.fishes ?? []

        const firstFish = fishes[0]
        const firstFishName = firstFish?.selectedFish?.nameItem
        const firstFishCode = firstFish?.selectedFish?.attribute?.code ?? ''
        const firstFishAmount = firstFish?.amount ?? 0

        const additionalFishesCount =
          fishes?.length > 1 ? fishes?.length - 1 : 0

        const unit = fishes?.[0]?.selectedFish?.unit ?? 'kg'

        return (
          <div>
            <div className="my-1">
              <span className="fw-semibold">
                {firstFishName} <em>({firstFishCode})</em>
              </span>
            </div>
            <div className="my-1">
              <span className="text-primary">
                {firstFishAmount} {unit}
              </span>
            </div>

            {additionalFishesCount > 0 && (
              <Badge className="badge-gray my-1">
                + {additionalFishesCount} tangkapan lainnya
              </Badge>
            )}
          </div>
        )
      },
    },
    {
      name: 'Total Penangkapan (kg)',
      cell: (row) => {
        const unit =
          row?.data?.fishes?.length > 0
            ? row?.data?.fishes?.[0]?.selectedFish?.unit
            : 'kg'

        return (
          <span>
            {row?.data?.totalAmount} {unit}
          </span>
        )
      },
    },
    {
      name: 'WPP',
      cell: (row) => {
        const catchAreas = [
          ...new Set(row?.data?.fishes?.map((fish) => fish?.catchArea)),
        ].join(', ')

        return <span>{catchAreas}</span>
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <div className="d-flex gap-2">
            <span
              style={{ cursor: 'pointer' }}
              className="fw-600 text-primary"
              onClick={() => {
                navigate(`/catches/${row?.userId}`)
              }}
            >
              Lihat Detail
            </span>
          </div>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        // marginTop: '8px',
        // marginBottom: '8px',
      },
    },
    rows: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    table: {
      style: {},
    },
  }

  const handleDownloadCSV = () => {
    setIsDialogShow(true)
    setFirstRows(0)
    const totalItems = data?.totalItems || 0
    setTotalRows(totalItems)

    const separator = ','

    // HEADER CSV
    let csvString = `Lama Trip${separator}Keberangkatan${separator}Koordinat Keberangkatan${separator}WPP${separator}Nama Kapal${separator}Kapten${separator}Nomor Kapal${separator}Total Tangkapan${separator}Total Alih Muat${separator}Berat Bersih${separator}FAO Code${separator}Jumlah Ikan${separator}Waktu Tangkapan${separator}Koordinat Penangkapan\n`

    // START EXPORTING
    exportFile(
      0,
      (chunk) => {
        chunk.forEach((row) => {
          const {
            startFishing,
            endFishing,
            vessel,
            fishes,
            moveReceipment,
            isFishMove,
            totalAmount,
          } = row?.data || {}

          const formattedStartFishing =
            moment(startFishing).format('DD MMMM YYYY HH:mm')
          const formattedEndFishing =
            moment(endFishing).format('DD MMMM YYYY HH:mm')

          const departureCoordinates =
            fishes?.[0]?.latitude && fishes?.[0]?.longitude
              ? `${fishes[0].latitude} - ${fishes[0].longitude}`
              : '-'

          const tripDuration = moment
            .duration(moment(endFishing).diff(moment(startFishing)))
            .humanize()

          const catchAreas =
            row?.data?.fishes?.length > 0
              ? [
                  ...new Set(row?.data?.fishes?.map((fish) => fish?.catchArea)),
                ].join(', ')
              : '-'

          const totalTransferredAmount = isFishMove
            ? moveReceipment?.reduce((total, receip) => {
                return (
                  total +
                  receip.fishes.reduce(
                    (subTotal, fish) => subTotal + fish.amount,
                    0,
                  )
                )
              }, 0)
            : 0

          const unit = fishes?.[0]?.selectedFish?.unit ?? 'kg'
          const totalNetWeight = isFishMove
            ? totalAmount + totalTransferredAmount
            : totalAmount

          // Add fishes to CSV
          fishes?.forEach((fish) => {
            const { nameItem, attribute } = fish?.selectedFish || {}
            const faoCode = attribute?.code ?? '-'
            const fishAmount = fish.amount ?? 0
            const formattedFishingDate = moment(fish?.createdAt).format(
              'DD MMMM YYYY HH:mm',
            )
            const fishCoordinates =
              fish?.latitude && fish?.longitude
                ? `${fish.latitude} - ${fish.longitude}`
                : '-'

            csvString += `${tripDuration}${separator}${formattedStartFishing} - ${formattedEndFishing}${separator}${departureCoordinates}${separator}${catchAreas}${separator}${vessel?.shipName ?? '-'}${separator}${vessel?.captain ?? '-'}${separator}${vessel?.shipNumber ?? '-'}${separator}${totalAmount}${separator}${totalTransferredAmount}${separator}${totalNetWeight}${separator}${faoCode}${separator}${fishAmount}${separator}${formattedFishingDate}${separator}${fishCoordinates}\n`
          })
        })
      },
      () => {
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
        FileSaver.saveAs(
          blob,
          `Data_Tangkapan_Report_${moment().format('YYYY-MM-DD')}.csv`,
        )
        setIsDialogShow(false)
      },
      (error) => {
        console.error('Export error:', error)
        setIsDialogShow(false)
      },
    )
  }

  const exportFile = (currentPage, onReceiveChunk, onFinished, onError) => {
    const perPage = 10
    const totalPages = Math.ceil(totalRows / perPage)

    // Simulate fetching data in chunks
    setTimeout(() => {
      const chunk = data?.items.slice(
        currentPage * perPage,
        (currentPage + 1) * perPage,
      )
      setFirstRows((currentPage + 1) * perPage)
      onReceiveChunk(chunk)

      if (currentPage < totalPages - 1) {
        exportFile(currentPage + 1, onReceiveChunk, onFinished, onError)
      } else {
        onFinished()
      }
    }, 3000)
  }

  return (
    <div className="w-100 px-3">
      <BreadCrumbs breadCrumbActive="Data Tangkapan" />
      <SidebarFilter
        open={isOpenSidebarFilter}
        handleFilter={() => dispatch(setToggleSidebarFilter())}
        toggle={() => dispatch(setToggleSidebarFilter())}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleSearchChange={handleSearchChange}
        selectedWPP={selectedWPP}
        setSelectedWPP={setSelectedWPP}
      />

      <Card className="my-4">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading || !isSuccess}
            responsive
            persistTableHead
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada daftar tangkapan nelayan yang didaftarkan
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <CardFooter
                className="border border-top-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-sm">
                    {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                  </div>
                  <div className="d-flex flex-centered gap-1">
                    <ButtonIcon
                      btnTitle="Sebelumnya"
                      btnProps={{
                        disabled: page.page === 0,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setPage((prev) => ({ page: prev.page - 1 }))
                          dispatch(setFetchOption({ page: page.page - 1 }))
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                    <ButtonIcon
                      btnTitle="Selanjutnya"
                      btnProps={{
                        disabled:
                          page.page ===
                          Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setPage((prev) => ({ page: prev.page + 1 }))
                          dispatch(setFetchOption({ page: page.page + 1 }))
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                  </div>
                </div>
              </CardFooter>
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Tangkapan Nelayan</h5>
                  <Button onClick={handleDownloadCSV} size="sm" color="primary">
                    + Download Report
                  </Button>
                </div>

                <div className="d-flex justify-content-between align-items-center my-4">
                  <InputGroup style={{ borderColor: '#D0D5DD' }}>
                    <InputGroupText
                      style={{
                        backgroundColor: 'white',
                        borderRightColor: 'white',
                      }}
                    >
                      <Search color="#667085" size={16} />
                    </InputGroupText>
                    <Input
                      placeholder="Cari nama nelayan, kapal..."
                      onChange={(e) => handleSearchChange(e.target.value)}
                      style={{ maxWidth: '250px' }}
                      value={search}
                    />
                  </InputGroup>
                  <ButtonIcon
                    btnTitle="Filter"
                    icon={<Filter size={20} />}
                    btnProps={{
                      className: 'w-auto d-flex me-2',
                      size: 'sm',
                      onClick: () => dispatch(setToggleSidebarFilter()),
                    }}
                  />
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>

      {/* Progress Bar Modal */}
      <PMBProgressBarExportFile
        isDialogShow={isDialogShow}
        firstRows={firstRows}
        totalRows={totalRows}
        onCancel={() => {
          setIsDialogShow(false)
        }}
      />
    </div>
  )
}

export default CatchesTable
