import LogoPasmik from 'assets/images/pmg-logo-beta.png'
import CustomButton from 'components/Buttons/CustomButton'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody, CardTitle, Container } from 'reactstrap'
import { URL_FORGET_PASSWORD_STEP_1, URL_LOGIN } from 'utils/pathUrl'

const StepTwoForgetPassword = () => {
  const nav = useNavigate()

  return (
    <Container fluid>
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Card style={{ maxWidth: '600px', width: '100%' }}>
          <CardBody>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                style={{ width: '100%', maxWidth: '200px', height: 'auto' }}
                src={LogoPasmik}
                alt="PasarMIKRO logo"
              />
            </div>
            <CardTitle className="d-flex justify-content-center mb-4" tag="h4">
              Atur ulang password
            </CardTitle>
            <CardTitle className="" tag="p">
              Kami telah mengirim anda email. Ikuti instruksi untuk mengatur
              ulang password anda.
            </CardTitle>

            <CustomButton
              disabled={false}
              className="my-1 mb-2"
              block
              color="primary"
              type="submit"
              onClick={() => nav(URL_LOGIN, { replace: true })}
            >
              Kembali ke Login
            </CustomButton>
            <CustomButton
              className="my-1 mb-2"
              block
              color="secondary"
              type="submit"
              outline
              onClick={() => nav(URL_FORGET_PASSWORD_STEP_1, { replace: true })}
            >
              Masukkan Ulang Email
            </CustomButton>
          </CardBody>
        </Card>
      </div>
    </Container>
  )
}

export default StepTwoForgetPassword

export const loader = (props) => {
  const { request } = props
  let token = request.url?.split('?')[1]?.split('&')[0]?.split('=')[1]
  let email = request.url?.split('?')[1]?.split('&')[1]?.split('=')[1]
  console.log(token)

  return {
    email,
    token,
  }
}
