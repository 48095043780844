import { createApiSlice } from 'app/createApiSlice'
import { API_PORTS } from 'utils/apiList'

export const portsListApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'portsList/api',
  endpoints(builder) {
    return {
      fetchPorts: builder.query({
        query({ page, size = 10, searchvalue }) {
          return {
            url: API_PORTS,
            params: {
              page,
              size,
              searchvalue,
            },
          }
        },
      }),
    }
  },
})

export const { useFetchPortsQuery, useLazyFetchPortsQuery } = portsListApiSlice
