import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  tag: {
    size: 10,
    page: 0,
  },
  details: null,
  alertMessage: null,
  alertType: 'success',
}

export const fisheriesListSlice = createAppSlice({
  name: 'fisheriesList',
  initialState,
  reducers: (create) => ({
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
    setAlert: create.reducer((state, { payload }) => {
      state.alertMessage = payload.message
      state.alertType = payload.type
    }),
    clearAlert: create.reducer((state) => {
      state.alertMessage = null
      state.alertType = 'success'
    }),
  }),
})

export const { setFetchOption, setAlert, clearAlert } =
  fisheriesListSlice.actions

export default fisheriesListSlice.reducer
