// ** React Imports
import { Link } from 'react-router-dom'

// ** Third Party Components
import Proptypes from 'prop-types'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

const BreadCrumbs = (props) => {
  // ** Props
  const {
    breadCrumbParent,
    breadCrumbParentLink,
    breadCrumbParent2,
    breadCrumbParent2Link,
    breadCrumbParent3,
    breadCrumbParent3Link,
    breadCrumbActiveRaw,
    breadCrumbActive,
  } = props

  return (
    <div className="d-flex align-items-center pt-3">
      <Breadcrumb>
        {breadCrumbParent3 || breadCrumbParent3Link ? (
          <>
            <BreadcrumbItem tag="li" active className="text-primary">
              ...
            </BreadcrumbItem>
            {breadCrumbParent3 ? (
              <BreadcrumbItem tag="li">
                {breadCrumbParent3Link ? (
                  <Link
                    to={breadCrumbParent3Link}
                    className="text-decoration-none text-secondary"
                  >
                    <span className="text-secondary  ms-2">
                      {breadCrumbParent3}
                    </span>
                  </Link>
                ) : (
                  <span className="text-secondary  ms-2">
                    {breadCrumbParent3}
                  </span>
                )}
              </BreadcrumbItem>
            ) : (
              ''
            )}
            <BreadcrumbItem tag="li" active className="text-primary">
              {breadCrumbActiveRaw ? (
                breadCrumbActive
              ) : (
                <span className="text-primary  ms-2">{breadCrumbActive}</span>
              )}
            </BreadcrumbItem>
          </>
        ) : (
          <>
            <BreadcrumbItem tag="li">
              <Link to="/" className="text-decoration-none text-secondary">
                <span className="text-secondary">Beranda</span>
              </Link>
            </BreadcrumbItem>
            {breadCrumbParent ? (
              <BreadcrumbItem tag="li">
                {breadCrumbParentLink ? (
                  <Link
                    to={breadCrumbParentLink}
                    className="text-decoration-none text-secondary"
                  >
                    {' '}
                    <span className="text-secondary ms-2">
                      {breadCrumbParent}
                    </span>{' '}
                  </Link>
                ) : (
                  <span className="text-secondary  ms-2">
                    {breadCrumbParent}
                  </span>
                )}
              </BreadcrumbItem>
            ) : (
              ''
            )}
            {breadCrumbParent2 ? (
              <BreadcrumbItem tag="li">
                {breadCrumbParent2Link ? (
                  <Link
                    to={breadCrumbParent2Link}
                    className="text-decoration-none text-secondary"
                  >
                    <span className="text-secondary  ms-2">
                      {breadCrumbParent2}
                    </span>
                  </Link>
                ) : (
                  <span className="text-secondary  ms-2">
                    {breadCrumbParent2}
                  </span>
                )}
              </BreadcrumbItem>
            ) : (
              ''
            )}
            <BreadcrumbItem tag="li" active className="text-primary">
              {breadCrumbActiveRaw ? (
                breadCrumbActive
              ) : (
                <span className="text-primary  ms-2">{breadCrumbActive}</span>
              )}
            </BreadcrumbItem>
          </>
        )}
      </Breadcrumb>
    </div>
  )
}
export default BreadCrumbs

// ** PropTypes
BreadCrumbs.propTypes = {
  breadCrumbActive: Proptypes.string.isRequired,
}
