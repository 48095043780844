import { createApiSlice } from 'app/createApiSlice'
import {
  API_FARMERS_LIST,
  API_VESSELS_DELETE,
  API_VESSELS_LIST,
} from 'utils/apiList'

export const vesselsListApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'vesselsList/api',
  endpoints(builder) {
    return {
      fetchFarmers: builder.query({
        query({ page, status, size = 10 }) {
          return {
            url: API_FARMERS_LIST,
            params: {
              page,
              status,
              size,
            },
          }
        },
      }),
      fetchVessels: builder.query({
        query({ page, size = 10, searchvalue }) {
          return {
            url: API_VESSELS_LIST,
            params: {
              page,
              size,
              searchvalue,
            },
          }
        },
      }),
      fetchVesselsDetail: builder.query({
        query({ id }) {
          return `${API_VESSELS_LIST}/${id}`
        },
      }),
      deleteVesselById: builder.mutation({
        query({ id }) {
          return {
            url: `${API_VESSELS_DELETE}/${id}`,
            method: 'DELETE',
          }
        },
      }),
    }
  },
})

export const {
  useFetchFarmersQuery,
  useFetchVesselsQuery,
  useLazyFetchVesselsQuery,
  useLazyFetchVesselsDetailQuery,
  useFetchVesselsDetailQuery,
  useDeleteVesselByIdMutation,
} = vesselsListApiSlice
