import axios from 'axios'
import { ButtonIcon } from 'components/Buttons'
import SidebarDetails from 'components/SidebarDetails'
import BreadCrumbs from 'components/breadcrumbs'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ArrowDown, ArrowRight, CheckCircle, ChevronDown } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap'
import VesselNotFound from '../../../../assets/icons/vessel-not-found.png'
import {
  useFetchFarmersDetailQuery,
  useFetchFarmersPlotQuery,
  useFetchUserCatchesQuery,
  useFetchUserVesselQuery,
} from '../farmers-list/farmersListApiSlice'
import { setFetchOption } from '../farmers-list/farmersListSlice'
import ModalChangeRole from '../staffList/ModalChangeRole'
import { toggleModal as toggleModalChangeRole } from '../staffList/staffListSlice'
import AboutFarmersCard from './AboutFarmersCard'
import DetailModal from './DetailModal'
import ModalChangeProfil from './ModalChangeProfil'
import ModalLandPlot from './ModalLandPlot'
import {
  setBannerMsg,
  toggleBannerDetail,
  toggleModalChangeProfile,
} from './detailSlice'

const DetailFarmers = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const dispatch = useDispatch()
  const userId = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )
  const { data, refetch } = useFetchFarmersDetailQuery({ id: userId })
  const resp = useFetchFarmersPlotQuery({ id: userId })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedPlot, setSelectedPlot] = useState({})
  const [isClick, setIsClick] = useState(false)
  const [imgSelfieSrc, setImgSelfieSrc] = useState('')
  const [imgKtpSrc, setImgKtpSrc] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenDetail, setIsOpenDetail] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const { isOpenBanner, bannerMsg } = useSelector((s) => s.farmersDetail)
  const { tag } = useSelector((s) => s.farmersList)
  const { token } = useSelector((s) => s.auth)
  let [page, setPage] = useState(tag?.page ?? 0)

  const regions = isClick
    ? resp?.data?.data?.items
    : !isClick && resp?.data?.data?.items.length > 3
      ? resp?.data?.data?.items.slice(0, -(resp?.data?.data?.items.length - 3))
      : resp?.data?.data?.items

  const getSrc = (link, type) => {
    setIsLoading(true)
    const imageUrl = link
    const bearerToken = token

    axios
      .get(imageUrl, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const imgUrl = URL.createObjectURL(response.data)
        if (type === 'img-ktp') {
          setImgKtpSrc(imgUrl)
        } else {
          setImgSelfieSrc(imgUrl)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleFinishedChangeRole = () => {
    dispatch(setBannerMsg('Data akun berhasil diubah.'))
    dispatch(toggleBannerDetail())
    refetch()
  }

  useEffect(() => {
    if (data?.data?.imgSelfie) {
      getSrc(data?.data?.imgSelfie, 'img-selfie')
    }
    if (data?.data?.imgKTP) {
      getSrc(data?.data?.imgKTP, 'img-ktp')
    }
  }, [userId, data])

  // USER VESSELS
  const responseUser = useFetchUserVesselQuery({ id: userId })
  const dataUser = responseUser?.data?.items ?? []

  // USER CATCHES
  const responseUserCatches = useFetchUserCatchesQuery({
    id: userId,
    page: page,
    size: 10,
  })
  const dataUserCatches = responseUserCatches?.data

  // COLUMNS USER CATCHES
  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {},
    },
    rows: {
      style: {
        marginTop: '14px',
        marginBottom: '14px',
      },
    },
    table: {
      style: {
        minHeight: '250px',
      },
    },
  }

  const columns = [
    {
      name: 'Tanggal Perjalanan',
      cell: (row) => {
        const startDate = moment(row?.data?.startFishing).format('DD MMMM YYYY')
        const endDate = moment(row?.data?.endFishing).format('DD MMMM YYYY')
        return (
          <span>
            {startDate ?? ''} - {endDate ?? ''}
          </span>
        )
      },
    },
    {
      name: 'Hasil Tangkapan',
      cell: (row) => {
        const fishes = row?.data?.fishes ?? []

        const firstFish = fishes[0]
        const firstFishName = firstFish?.selectedFish?.nameItem
        const firstFishCode = firstFish?.selectedFish?.attribute?.code ?? ''
        const firstFishAmount = firstFish?.amount ?? 0

        const additionalFishesCount =
          fishes?.length > 1 ? fishes?.length - 1 : 0

        const unit = fishes?.[0]?.selectedFish?.unit ?? 'kg'

        return (
          <div>
            <div className="my-1">
              <span className="fw-semibold">
                {firstFishName} <em>({firstFishCode})</em>
              </span>
            </div>
            <div className="my-1">
              <span className="text-primary">
                {firstFishAmount} {unit}
              </span>
            </div>

            {additionalFishesCount > 0 && (
              <Badge className="badge-gray my-1">
                + {additionalFishesCount} tangkapan lainnya
              </Badge>
            )}
          </div>
        )
      },
    },
    {
      name: 'Kapal',
      cell: (row) => {
        return (
          <Row className="align-items-center mb-2 d-flex flex-grow-1">
            <Col xs="3">
              <img
                src={row?.data?.vessel?.linkImage ?? VesselNotFound}
                alt={row?.data?.vessel?.vesselName}
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '4px',
                }}
                loading="lazy"
              />
            </Col>

            <Col className="align-items-center" xs="8">
              <p className="fw-bold text-primary m-0">
                {row?.data?.vessel?.shipName}
              </p>
            </Col>
          </Row>
        )
      },
    },
    {
      name: 'Total Penangkapan (kg)',
      cell: (row) => {
        const unit =
          row?.data?.fishes?.length > 0
            ? row?.data?.fishes?.[0]?.selectedFish?.unit
            : 'kg'

        return (
          <span>
            {row?.data?.totalAmount} {unit}
          </span>
        )
      },
    },
    {
      name: 'WPP',
      cell: (row) => {
        const catchAreas = [
          ...new Set(row?.data?.fishes?.map((fish) => fish?.catchArea)),
        ].join(', ')

        return <span>{catchAreas}</span>
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <div className="d-flex gap-2">
            <span
              style={{ cursor: 'pointer' }}
              className="fw-semibold text-center"
              onClick={() => {
                setSelectedRow(row)
                setIsOpenDetail(true)
              }}
            >
              Lihat Detail
            </span>
          </div>
        )
      },
    },
  ]

  return (
    <Container className="pb-3">
      <BreadCrumbs
        breadCrumbActive={'Detail Anggota'}
        breadCrumbParent={'Daftar Anggota'}
        breadCrumbParent2={'Grup'}
        breadCrumbParent3={data?.data?.groupName ?? '-'}
      />
      <ModalLandPlot
        row={selectedPlot}
        isOpen={isModalOpen}
        toggle={toggleModal}
      />

      <ModalChangeRole
        isSubGroup
        row={data?.data}
        handleFinishedChangeRole={handleFinishedChangeRole}
      />

      <ModalChangeProfil row={data?.data} />

      {isOpenBanner ? (
        <div>
          <Alert
            isOpen={isOpenBanner}
            toggle={() => dispatch(toggleBannerDetail())}
            color="success"
            className="d-flex align-items-center my-4 w-100"
          >
            <span>
              <CheckCircle size={17} /> {bannerMsg}
            </span>
          </Alert>
        </div>
      ) : (
        <></>
      )}

      <div className="d-flex flex-column gap-3 ">
        {/* Data Tentang anggota */}
        <AboutFarmersCard row={{ ...data?.data }} />

        <Row>
          <Col>
            <div className="d-flex flex-column gap-3">
              {/* Data Akun */}
              <Card>
                <CardBody className="d-flex flex-column gap-2">
                  <h5>Data Akun</h5>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <span className="text-primary fw-semibold">
                        {data?.data?.fullName}
                      </span>
                      <p className="text-muted">{data?.data?.phoneNumber}</p>
                    </div>
                    <Badge
                      className={
                        data?.data?.status === 'VERIFIED'
                          ? 'badge-verify'
                          : 'badge-danger'
                      }
                    >
                      {data?.data?.status === 'VERIFIED'
                        ? 'Terverifikasi'
                        : 'Belum Terverifikasi'}
                    </Badge>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="fw-semibold">Peran</span>
                      <h6
                        onClick={() => dispatch(toggleModalChangeRole())}
                        style={{ cursor: 'pointer' }}
                        className="text-primary"
                      >
                        Ubah Peran
                      </h6>
                    </div>
                    <p className="text-muted">{data?.data?.roleName}</p>
                  </div>
                </CardBody>
              </Card>

              {/* Data Foto */}
              <Card>
                <CardBody className="d-flex flex-column gap-2">
                  <h5>Identitas</h5>
                  <div className="d-flex gap-2">
                    <div>
                      <p>Foto KTP</p>
                      {(isLoading && !imgKtpSrc) || !data ? (
                        <Spinner />
                      ) : (
                        <img
                          style={{ width: '180px', height: 'auto' }}
                          id="img-ktp"
                          className="img-fluid rounded"
                          src={imgKtpSrc}
                          alt="Foto Ktp"
                        />
                      )}
                    </div>
                    <div>
                      <p>Foto Selfie</p>
                      {(isLoading && !imgSelfieSrc) || !data ? (
                        <Spinner />
                      ) : (
                        <img
                          style={{ width: '180px', height: 'auto' }}
                          id="img-selfie"
                          className="img-fluid rounded"
                          src={imgSelfieSrc}
                          alt="Foto Selfie"
                        />
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>

              {/* Data Bank */}
              <Card>
                <CardBody className="d-flex flex-column gap-2">
                  <h5>Rekening Bank</h5>

                  <div>
                    <span className="fw-semibold">Nomor rekening</span>
                    <p className="text-muted">
                      {data?.data?.bankAccountNumber}
                    </p>
                  </div>
                  <div>
                    <span className="fw-semibold">Bank</span>
                    <p className="text-muted">{data?.data?.bankName}</p>
                  </div>
                  <div>
                    <span className="fw-semibold">Nama pemilik rekening</span>
                    <p className="text-muted">{data?.data?.bankAccountName}</p>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>

          <Col>
            {/* Data Diri */}
            <Card>
              <CardBody className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Data Diri</h5>
                  <h6
                    onClick={() => dispatch(toggleModalChangeProfile())}
                    style={{ cursor: 'pointer' }}
                    className="text-primary"
                  >
                    Ubah
                  </h6>
                </div>
                <div>
                  <span className="fw-semibold">Nama</span>
                  <p className="text-muted">{data?.data?.fullName}</p>
                </div>
                <div>
                  <span className="fw-semibold">Tanggal lahir</span>
                  <p className="text-muted">{data?.data?.dob}</p>
                </div>
                <div>
                  <span className="fw-semibold">Jenis Kelamin</span>
                  <p className="text-muted">
                    {data?.data?.sex == 0 ? 'Perempuan' : 'Laki-laki'}
                  </p>
                </div>
                <div>
                  <span className="fw-semibold">Email </span>
                  <p className="text-muted">{data?.data?.email}</p>
                </div>
                <div>
                  <span className="fw-semibold">NIK</span>
                  <p className="text-muted">{data?.data?.noKTP}</p>
                </div>
                <div>
                  <span className="fw-semibold">Provinsi</span>
                  <p className="text-muted">{data?.data?.province}</p>
                </div>
                <div>
                  <span className="fw-semibold">Kota</span>
                  <p className="text-muted">{data?.data?.city}</p>
                </div>
                <div>
                  <span className="fw-semibold">Kecamatan</span>
                  <p className="text-muted">{data?.data?.district}</p>
                </div>
                <div>
                  <span className="fw-semibold">Kelurahan</span>
                  <p className="text-muted">{data?.data?.subDistrict}</p>
                </div>
                <div>
                  <span className="fw-semibold">Kode pos</span>
                  <p className="text-muted">{data?.data?.postalCode}</p>
                </div>
                <div>
                  <span className="fw-semibold">Alamat</span>
                  <p className="text-muted">{data?.data?.address}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Data Wilayah */}
        <Card>
          <CardBody className="d-flex flex-column gap-2">
            <h5>Wilayah Komoditas</h5>

            {resp?.data?.data?.items.length < 1 ? (
              <span className="text-muted">Belum ada wilayah yang direkam</span>
            ) : (
              regions?.map((e, i) => {
                return (
                  <div
                    key={i}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <span className="text-primary fw-semibold">
                        {e?.landName ? e?.landName : '-'}
                      </span>
                      <p className="text-muted">
                        Perkiraan luas:{' '}
                        {`${intl.formatNumber(e?.size / 10000, {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })} Ha`}
                      </p>
                    </div>
                    <div
                      onClick={() => {
                        setSelectedPlot(e)
                        toggleModal()
                      }}
                      className="fw-semibold"
                      style={{ cursor: 'pointer' }}
                    >
                      Lihat Wilayah <ArrowRight />
                    </div>
                  </div>
                )
              })
            )}
            {resp?.data?.data?.items.length > 3 && !isClick ? (
              <Button
                onClick={() => setIsClick(true)}
                block
                outline
                color="primary"
              >
                <ArrowDown /> Muat Lebih Banyak
              </Button>
            ) : null}
          </CardBody>
        </Card>

        {/* PEMETAAN KAPAL */}
        {dataUser?.length > 0 && (
          <Card>
            <CardBody className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Pemetaan Kapal</h5>
                <h6
                  onClick={() => navigate('/vessels')}
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                >
                  Tambahkan pemetaan kapal
                </h6>
              </div>

              {dataUser?.map((item, index) => {
                return (
                  <Row key={index} className="align-items-center mb-2">
                    <Col xs="1">
                      <img
                        src={item?.data?.linkImage ?? VesselNotFound}
                        alt={item?.data?.vesselName}
                        style={{
                          width: '75px',
                          height: '48px',
                          borderRadius: '4px',
                        }}
                        loading="lazy"
                      />
                    </Col>

                    <Col className="align-items-center" xs="11">
                      <span className="fw-semibold m-0">
                        {item?.data?.vesselName}
                      </span>
                      <p className="text-muted m-0">
                        {item?.data?.licenseNumber}
                      </p>
                    </Col>
                  </Row>
                )
              })}
            </CardBody>
          </Card>
        )}

        {/* RIWAYAT OPERASI PENANGKAPAN */}
        {dataUserCatches?.items?.length > 0 && (
          <Card>
            <CardBody className="d-flex flex-column gap-2">
              <div>
                <h5>Riwayat Operasi Penangkapan</h5>
              </div>
            </CardBody>

            {dataUserCatches?.items?.length > 0 ? (
              <DataTable
                customStyles={customStyles}
                columns={columns}
                data={dataUserCatches?.items ?? []}
                progressPending={isLoading}
                responsive
                persistTableHead
                paginationTotalRows={dataUserCatches?.totalItems}
                progressComponent={<Spinner />}
                sortIcon={<ChevronDown />}
                sortServer
                className="border p-0 border-1 rounded-top"
                noDataComponent={
                  <p className="my-3 fw-semibold">
                    Belum ada pengguna yang didaftarkan
                  </p>
                }
                pagination
                paginationServer
                paginationComponent={(props) => {
                  return (
                    <CardFooter
                      className="border border-top-0"
                      style={{ backgroundColor: 'transparent' }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="text-sm">
                          {`Halaman ${page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                        </div>
                        <div className="d-flex flex-centered gap-1">
                          <ButtonIcon
                            btnTitle="Sebelumnya"
                            btnProps={{
                              disabled: page === 0,
                              size: 'sm',
                              color: 'secondary',
                              onClick: () => {
                                setPage((prev) => prev - 1)
                                dispatch(
                                  setFetchOption({
                                    page: page - 1,
                                  }),
                                )
                              },
                            }}
                            btnTitleProps={{
                              className: 'text-sm',
                            }}
                          />
                          <ButtonIcon
                            btnTitle="Selanjutnya"
                            btnProps={{
                              disabled:
                                page ===
                                Math.ceil(props.rowCount / props.rowsPerPage) -
                                  1,
                              size: 'sm',
                              color: 'secondary',
                              onClick: () => {
                                setPage((prev) => prev + 1)
                                dispatch(
                                  setFetchOption({
                                    page: page + 1,
                                  }),
                                )
                              },
                            }}
                            btnTitleProps={{
                              className: 'text-sm',
                            }}
                          />
                        </div>
                      </div>
                    </CardFooter>
                  )
                }}
              />
            ) : (
              <Card className="bg-light rounded border-0">
                <CardBody>
                  <div className="d-flex flex-column align-items-center gap-4">
                    <span>Belum ada riwayat operasi penangkapan.</span>
                    <Button
                      disabled
                      onClick={() => navigate('/vessels/')}
                      color="primary"
                    >
                      + Tambah Penangkapan
                    </Button>
                  </div>
                </CardBody>
              </Card>
            )}
          </Card>
        )}

        {/* MODAL DETAIL RIWAYAT OPERASI PENANGKAPAN */}
        {dataUserCatches?.items?.length > 0 && (
          <SidebarDetails
            open={isOpenDetail}
            title="Detail Operasi Informasi"
            size="lg"
            headerClassName="mb-1"
            contentClassName="pt-0"
            toggleSidebar={() => setIsOpenDetail(false)}
          >
            <DetailModal row={selectedRow} />
          </SidebarDetails>
        )}
      </div>
    </Container>
  )
}

export default DetailFarmers
