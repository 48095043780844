import BreadCrumbs from 'components/breadcrumbs'
import { PMBFormatDate } from 'helpers'
import moment from 'moment-timezone'
import { useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown } from 'react-feather'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, Spinner } from 'reactstrap'
import { getCatchesDetail } from '../list-catches/catchesListAction'
import { useFetchUserCatchesQuery } from '../list-catches/catchesListApiSlice'
import ModalMovement from './ModalMovement'
import { setDataMovement, setIsOpenModalDetailMovement } from './detailSlice'

const DetailCathces = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userId = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )

  const { data, isLoading } = useFetchUserCatchesQuery({ id: userId })

  // GET MASTER VESSEL BASED ON SHIPNUMBER (NOT FOR AT THE MOMENT / WAITING ADJUSTMENT FROM BE)
  // const shipNumber = data?.items?.[0]?.data?.vessel?.shipNumber
  // const encodedShipNumber = shipNumber ? encodeURIComponent(shipNumber) : null
  // const { data: dataVessel } = useFetchVesselByShipNumberQuery(
  //   { shipNumber: encodedShipNumber },
  //   { skip: !encodedShipNumber },
  // )

  const tripInfo = data?.items?.[0]?.data || {}
  const vesselInfo = tripInfo?.vessel || {}
  const unit = tripInfo?.fishes?.[0]?.selectedFish?.unit ?? 'kg'

  const tripDuration = moment
    .duration(moment(tripInfo.endFishing).diff(moment(tripInfo.startFishing)))
    .humanize()

  const departureCoordinates =
    tripInfo?.fishes?.[0]?.latitude && tripInfo?.fishes?.[0]?.longitude
      ? `${tripInfo?.fishes[0].latitude}, ${tripInfo?.fishes[0].longitude}`
      : '-'

  const catchAreasWPP =
    tripInfo?.fishes?.length > 0
      ? [...new Set(tripInfo?.fishes?.map((fish) => fish?.catchArea))].join(
          ', ',
        )
      : '-'

  useEffect(() => {
    if (userId) {
      dispatch(getCatchesDetail())
    }
  }, [userId, data])

  const calculateTransferredWeight = (fishId) => {
    // Find the movements that include this fish
    const movementFishes = tripInfo?.moveReceipment
      ?.flatMap((movement) => movement.fishes)
      ?.filter((fish) => fish.id === fishId)

    // Sum the weight of all matching fishes in the movement
    return movementFishes.reduce((total, fish) => total + (fish.weight || 0), 0)
  }

  const columns = [
    {
      name: 'Nama Spesies',
      cell: (row) => <span>{row?.selectedFish?.nameItem ?? '-'}</span>,
    },
    {
      name: 'Kode Tangkapan',
      cell: (row) => <span>{row?.id}</span>,
    },
    {
      name: 'FAO Code',
      cell: (row) => <span>{row?.selectedFish?.attribute?.code ?? '-'}</span>,
    },
    {
      name: 'Berat Tangkapan',
      cell: (row) => (
        <span>
          {row?.amount ?? 0} {unit}
        </span>
      ),
    },
    {
      name: 'Berat Dialihkan',
      cell: (row) => {
        const transferredWeight = tripInfo?.isFishMove
          ? `${calculateTransferredWeight(row.id)} ${unit}`
          : '-'
        return <span>{transferredWeight ?? '-'}</span>
      },
    },
    {
      name: 'Berat Bersih',
      cell: (row) => (
        <span>
          {row?.amount ?? 0} {unit}
        </span>
      ),
    },
    {
      name: 'Waktu Penangkapan',
      cell: (row) => <span>{PMBFormatDate(row?.createdAt)}</span>,
    },
    {
      name: 'Kordinat Penangkapan',
      cell: (row) => (
        <span>
          {row?.latitude ?? '-'}, {row?.longitude ?? '-'}
        </span>
      ),
    },
    {
      name: 'Transhipment',
      cell: (row) => {
        const movementFishes = tripInfo?.moveReceipment
          ?.flatMap((movement) => movement.fishes)
          ?.filter((fish) => fish?.id === row?.id)

        return (
          <Button
            disabled={row?.id !== movementFishes?.[0]?.id}
            onClick={() => {
              dispatch(setIsOpenModalDetailMovement())
              dispatch(setDataMovement(movementFishes))
            }}
            size="sm"
            color="primary"
          >
            Lihat Detail
          </Button>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '14px',
        marginBottom: '14px',
      },
    },
    rows: {
      style: {
        marginTop: '14px',
        marginBottom: '14px',
      },
    },
  }

  return (
    <>
      {/* MODAL MOVEMENT */}
      <ModalMovement />

      <div className="w-100 px-3 gap-10">
        <BreadCrumbs
          breadCrumbTitle="Beranda"
          breadCrumbParent2="Daftar Tangkapan"
          breadCrumbActive="Detail Tangkapan"
        />

        <div className="mt-1">
          <h5>Detail Tangkapan</h5>
        </div>

        <div className="col-12" style={{ display: 'flex', gap: '16px' }}>
          <InformationCard
            title="Informasi Perjalanan"
            info={[
              { label: 'Lama Trip', value: tripDuration || '-' },
              {
                label: 'Keberangkatan',
                value: PMBFormatDate(tripInfo.startFishing),
              },
              { label: 'Berlabuh', value: PMBFormatDate(tripInfo.endFishing) },
              { label: 'Koordinat Keberangkatan', value: '-' },
              { label: 'Port Berangkat', value: tripInfo.departurePort || '-' },
              { label: 'Port Berlabuh', value: tripInfo.arrivalPort || '-' },
              { label: 'WPP', value: catchAreasWPP },
            ]}
          />

          <InformationCard
            title="Informasi Kapal"
            info={[
              { label: 'Ship Name', value: vesselInfo.shipName || '-' },
              { label: 'Nama Nelayan', value: vesselInfo.captain || '-' },
              {
                label: 'Vessel Registration',
                value: vesselInfo.shipNumber || '-',
              },
              { label: 'UVI', value: vesselInfo.UVI || '-' },
              { label: 'Vessel Flag', value: vesselInfo.flag || '-' },
              { label: 'Gear Type', value: vesselInfo.gearType || '-' },
              { label: 'Vessel Type', value: vesselInfo.type || '-' },
            ]}
          />
        </div>

        {/* Table Fishes */}
        <Card>
          <CardBody className="d-flex flex-column gap-2">
            <DataTable
              customStyles={customStyles}
              columns={columns}
              data={tripInfo?.fishes || []}
              progressPending={isLoading}
              responsive
              persistTableHead
              paginationTotalRows={data?.totalItems}
              progressComponent={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '100px 0px',
                  }}
                >
                  <Spinner />
                </div>
              }
              sortIcon={<ChevronDown />}
              sortServer
              className="border p-0 border-1 rounded-top"
              noDataComponent={
                <p className="my-3 fw-semibold">
                  Belum ada daftar tangkapan nelayan
                </p>
              }
              // pagination
            />
          </CardBody>
        </Card>
      </div>
    </>
  )
}

// Helper component for displaying info cards
const InformationCard = ({ title, info }) => (
  <div style={{ flexBasis: '50%', flexGrow: '1', marginBottom: '14px' }}>
    <Card>
      <CardBody className="d-flex flex-column gap-2">
        <h6>{title}</h6>
        {info?.map(({ label, value }, idx) => (
          <div key={idx} className="row py-1">
            <div className="col-6">
              <span className="fw-semibold text-sm">{label}</span>
            </div>
            <div className="col-6">
              <p className="text-muted text-sm mb-0">{value}</p>
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
  </div>
)

export default DetailCathces
