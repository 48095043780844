import logoSuccess from 'assets/icons/Check.svg'
import LogoPasmik from 'assets/icons/PasarMIKRO-Primary-Logogram-3.svg'

const Success = () => {
  return (
    <div className="flex-centered flex-column" style={{ minHeight: '100vh' }}>
      <div className="flex-centered py-3">
        {' '}
        <img
          style={{ width: '100%', maxWidth: '80px', height: 'auto' }}
          src={LogoPasmik}
          alt="PasarMIKRO logo"
        />{' '}
      </div>
      <div className="flex-centered py-3">
        {' '}
        <img
          style={{ width: '100%', maxWidth: '120px', height: 'auto' }}
          src={logoSuccess}
          alt="PasarMIKRO logo"
        />{' '}
      </div>
      <h3 className="fw-600 text-center py-3">
        Pembayaran anda telah diterima
      </h3>
      <span>Silakan lanjutkan aktivitas anda</span>
    </div>
  )
}

export default Success
