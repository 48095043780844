import emptyAnggota from 'assets/icons/empty-anggota.svg'
import BreadCrumbs from 'components/breadcrumbs'
import { useEffect } from 'react'
import { CheckCircle, ChevronRight } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Row,
  Spinner,
} from 'reactstrap'
import { clearState } from '../forms/formSlice'
import { getGroupCount, getGroupList } from './groupListAction'
import { toggleBanner, toggleModal } from './groupsListSlice'
import ModalCreateGroup from './ModalCreateGroup'
import { Can } from 'context/Acl'
import { BUSINESS_FARMERS, MANAGE } from 'utils/subjectActions'

const GroupsList = () => {
  const { tag, isOpenBanner, loading, bannerMsg, groups, allGroupCount } =
    useSelector((s) => s.groupsList)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearState())
  }, [])

  useEffect(() => {
    if (groups?.length < 1) {
      dispatch(getGroupList())
    }
  }, [groups])

  useEffect(() => {
    if (!allGroupCount) {
      dispatch(getGroupCount())
    }
  }, [allGroupCount])

  return (
    <div className="w-100 px-3">
      <BreadCrumbs
        breadCrumbActive={'Grup'}
        breadCrumbParent={'Daftar Anggota'}
      />
      <ModalCreateGroup />
      <Card>
        <CardBody>
          <div className="w-100">
            <div className="d-flex align-items-center mb-1">
              {loading ? (
                <Spinner color="primary" />
              ) : (
                <>
                  {groups?.totalItems > 0 ? (
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h5 className="fw-semibold">Grup</h5>
                      <Can I={MANAGE} this={BUSINESS_FARMERS}>
                        <Button
                          onClick={() => dispatch(toggleModal())}
                          color="primary"
                        >
                          + Buat Grup
                        </Button>
                      </Can>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            <div>
              <Alert
                isOpen={isOpenBanner}
                toggle={() => dispatch(toggleBanner())}
                color="success"
                className="d-flex align-items-center mt-4 w-100"
              >
                <span>
                  <CheckCircle size={17} /> {bannerMsg}
                </span>
              </Alert>
            </div>
            <Row className="d-flex flex-row justify-content-start mb-3 align-items-stretch pb-3">
              <Col lg="2" md="6" sm="12" className="d-flex">
                <Card body className="w-100">
                  <CardText tag="small" className="fw-semibold">
                    Jumlah Grup
                  </CardText>
                  <CardText tag="h3" className="text-primary mt-2">
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      allGroupCount?.totalGroup
                    )}
                  </CardText>
                </Card>
              </Col>
              <Col lg="2" md="6" sm="12" className="d-flex">
                <Card body className="w-100">
                  <CardText tag="small" className="fw-semibold">
                    Jumlah Anggota
                  </CardText>
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="text-primary h3">
                      {loading ? (
                        <Spinner color="primary" />
                      ) : (
                        allGroupCount?.totalFarmer
                      )}
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          {loading ? (
            <div>
              <Spinner color="primary" />
            </div>
          ) : groups?.totalItems > 0 ? (
            <div className="d-flex flex-column gap-3">
              {groups?.items?.map((e, i) => {
                return (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/farmers/groups/${e?.code}`)
                    }}
                    key={i}
                  >
                    <div className="d-flex flex-column gap-1">
                      <span className="fw-500">{e?.name}</span>
                      <span className="text-muted">
                        Jumlah anggota: {e?.totalMember}
                      </span>
                    </div>
                    <ChevronRight size={20} className="text-secondary" />
                  </div>
                )
              })}
            </div>
          ) : (
            <Card className="bg-light rounded border-0">
              <CardBody>
                <div className="d-flex flex-column align-items-center gap-4">
                  <img alt="Groups" src={emptyAnggota} />
                  <span>
                    Gunakan grup untuk mengatur pekerja lapangan berdasarkan
                    wilayah operasi.
                  </span>
                  <Can I={MANAGE} this={BUSINESS_FARMERS}>
                    <Button
                      onClick={() => dispatch(toggleModal())}
                      color="primary"
                    >
                      + Buat Grup
                    </Button>
                  </Can>
                </div>
              </CardBody>
            </Card>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export default GroupsList
