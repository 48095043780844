import { ButtonIcon } from 'components/Buttons'
import CustomButton from 'components/Buttons/CustomButton'
import LoadingAnimation from 'components/LoadingAnimation'
import { useEffect, useState } from 'react'
import { User } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import {
  Alert,
  CardBody,
  CardText,
  Col,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap'
import { postEditProfile } from '../settingAction'
import {
  useFetchSettingsQuery,
  useGetUserAvatarQuery,
} from '../settingApiSlice'
import { setIsEdit } from '../settingSlice'
import ModalAvatarUpload from './ModalAvatarUpload'
import ModalConfirmasi from './ModalConfirmasi'

const ProfileSetting = () => {
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isSuccessUploadAva, setIsSuccessUploadAva] = useState(false)
  const [tempUserId, setTempUserId] = useState(null)
  // remove photo
  const [isOpenRemovePhoto, setIsOpenRemovePhoto] = useState(false)
  const [data, isLoading, isSuccess] = useOutletContext()
  const { data: avaUser, refetch: refetchAvatar } = useGetUserAvatarQuery(
    tempUserId,
    {
      skip: tempUserId === null,
    },
  )

  const { isEdit } = useSelector((s) => s.setting)
  const { refetch } = useFetchSettingsQuery()

  const hanldeEditProfile = async (name) => {
    try {
      const data = await dispatch(postEditProfile({ name: name }))
      if (data.payload.error === 0) {
        refetch()
        dispatch(setIsEdit(false))
      } else {
        throw data
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fnShowModalAddPhoto = () => {
    setIsOpenModal(!isOpenModal)
  }

  const fnShowModalRemoveFoto = () => {
    setIsOpenRemovePhoto(!isOpenRemovePhoto)
  }

  useEffect(() => {
    if (data?.makerData?.userId) {
      setTempUserId([data?.makerData?.userId])
    }
  }, [data?.makerData?.userId])

  return (
    <>
      <ModalAvatarUpload
        toggle={fnShowModalAddPhoto}
        modal={isOpenModal}
        centered={true}
        keyboard={false}
        backdrop="static"
        setIsSuccessUploadAva={setIsSuccessUploadAva}
        dataName={data?.makerData?.name ?? data?.makerData?.email}
        refetchAvatar={refetchAvatar}
      />

      <ModalConfirmasi
        toggle={fnShowModalRemoveFoto}
        modal={isOpenRemovePhoto}
        centered={true}
        keyboard={false}
        backdrop="static"
        dataName={data?.makerData?.name ?? data?.makerData?.email}
        setIsSuccessUploadAva={setIsSuccessUploadAva}
        refetchAvatar={refetchAvatar}
      />
      <CardBody>
        <Alert
          color="info"
          isOpen={isSuccessUploadAva ? true : false}
          toggle={() => setIsSuccessUploadAva(false)}
          className="px-3"
        >
          <span>{isSuccessUploadAva}</span>
        </Alert>
        <Row>
          <Col sm="12" md="2" lg="3" className="flex-centered">
            {!isLoading && isSuccess ? (
              Object.values(avaUser?.data ?? [])?.length > 0 ? (
                <div className="d-flex justfy-content-center align-items-center flex-column gap-2">
                  <div
                    className="rounded-circle flex-centered mb-3"
                    style={{
                      width: '120px',
                      height: '120px',
                      backgroundColor: '#ECFAFF',
                    }}
                  >
                    <span>
                      <img
                        className="rounded-circle flex-centered mb-3"
                        style={{ width: '120px', height: '120px' }}
                        src={avaUser?.data[0]?.link}
                        alt="avatar"
                      />
                    </span>
                  </div>
                  <CustomButton
                    color="secondary"
                    outline
                    size="sm"
                    onClick={() => {
                      fnShowModalAddPhoto()
                      setIsSuccessUploadAva(false)
                    }}
                  >
                    Ubah Foto
                  </CustomButton>

                  <div
                    onClick={() => {
                      fnShowModalRemoveFoto()
                    }}
                    className="text-danger fw-bold"
                    style={{ cursor: 'pointer' }}
                  >
                    Hapus Foto
                  </div>
                </div>
              ) : (
                <div className="d-flex justfy-content-center align-items-center flex-column gap-2">
                  <div
                    className="rounded-circle flex-centered mb-3"
                    style={{
                      width: '120px',
                      height: '120px',
                      backgroundColor: '#ECFAFF',
                    }}
                  >
                    <span>
                      <User size={60} className="text-primary" />
                    </span>
                  </div>
                  <CustomButton
                    color="secondary"
                    outline
                    size="sm"
                    onClick={fnShowModalAddPhoto}
                  >
                    Tambah Foto
                  </CustomButton>
                </div>
              )
            ) : (
              <LoadingAnimation size="sm" />
            )}
          </Col>
          <Col sm="12" md="10" lg="9">
            <ListGroup flush>
              <ListGroupItem className="py-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <CardText tag="h4">Informasi profil</CardText>
                    <CardText tag="p">
                      Ubah profil dan foto anda di sini
                    </CardText>
                  </div>
                  <div className="d-flex gap-2">
                    <ButtonIcon
                      btnTitle={isEdit ? 'Kembali' : 'Ubah'}
                      btnProps={{
                        className: 'w-auto',
                        size: 'sm',
                        onClick: () =>
                          dispatch(setIsEdit(isEdit ? false : true)),
                      }}
                    />
                    {isEdit ? (
                      <CustomButton
                        disabled={!name}
                        color="primary"
                        onClick={() => hanldeEditProfile(name)}
                      >
                        Kirim
                      </CustomButton>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </ListGroupItem>
              <ListGroupItem className="py-4">
                <Row>
                  <Col sm="4">Nama</Col>
                  <Col sm="8">
                    {!isLoading && isSuccess ? (
                      isEdit ? (
                        <Input
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                          defaultValue={data?.makerData?.name}
                        />
                      ) : (
                        data?.makerData?.name
                      )
                    ) : (
                      <LoadingAnimation size="sm" />
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem className="py-4">
                <Row>
                  <Col sm="4">Alamat Email</Col>
                  <Col sm="8">
                    {!isLoading && isSuccess ? (
                      isEdit ? (
                        <Input
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                          value={data?.makerData?.email}
                          disabled
                        />
                      ) : (
                        data?.makerData?.email
                      )
                    ) : (
                      <LoadingAnimation size="sm" />
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem className="py-4">
                <Row>
                  <Col sm="4">Peran</Col>
                  <Col sm="8">
                    {!isLoading && isSuccess ? (
                      isEdit ? (
                        <Input
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                          value={data?.makerData?.rolesName}
                          disabled
                        />
                      ) : (
                        data?.makerData?.rolesName
                      )
                    ) : (
                      <LoadingAnimation size="sm" />
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </CardBody>
    </>
  )
}

export default ProfileSetting
