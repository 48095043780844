import LogoPasmik from 'assets/images/pmg-logo-beta.png'
import { useAuthAccess } from 'hooks/useAuthAccess'
import { useState } from 'react'
import { AlertCircle, Eye, EyeOff } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useLoaderData, useNavigate } from 'react-router-dom'
import {
  Alert,
  Card,
  CardBody,
  CardTitle,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Spinner,
} from 'reactstrap'
import { emailCheck } from 'utils/emailCheck'
import { URL_FORGET_PASSWORD_STEP_1, URL_LOGIN } from 'utils/pathUrl'
// Redux
import CustomButton from 'components/Buttons/CustomButton'
import ChangePasswordPertama from 'private-views/change-password/ChangePasswordPertama'
import { setData } from '../connect-account/connectAccountSlice'
import { useLoginUserMutation } from './loginApiSlice'
import {
  login,
  loginLoading,
  loginReject,
  resetAlert,
  setLoading,
  toggleAlert,
  toggleModal,
} from './loginSlice'
import ModalConnectAccount from './ModalConnectAccount'
import { setIsV3 } from 'features/private-views/dashboard/dashboardSlice'

const LoginView = () => {
  const { data, isEmail } = useLoaderData()
  const [loginUser] = useLoginUserMutation()

  const { token, alreadychangepass, userNotPartnership } = useAuthAccess()
  const [email, setEmail] = useState(data ?? '')
  const [password, setPassword] = useState('')
  const [isShow, setIsShow] = useState(false)
  const [inputVisibility, setInputVisibility] = useState(false)

  const { loading, isError, error, alertMsg, isOpenAlert } = useSelector(
    (s) => s.auth,
  )

  const dispatch = useDispatch()
  const nav = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      dispatch(loginLoading())
      const res = await loginUser({ email, password }).unwrap()

      if (res?.alreadychangepass === 1 || res?.userNotPartnership) {
        dispatch(login(res))
        dispatch(setIsV3(false))
        nav('/', { replace: true })
      }
      if (res?.alreadychangepass === 0 && !res?.userNotPartnership) {
        setIsShow(true)
        dispatch(login(res))
      }
      if (res?.key) {
        dispatch(setData(res))
        dispatch(setLoading())
        dispatch(toggleModal())
      }
    } catch (error) {
      dispatch(loginReject(error))
    }
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
    if (error !== null) {
      dispatch(resetAlert())
    }
  }

  const handleChangePassword = (e) => {
    setPassword(e.target.value)
    if (error !== null) {
      dispatch(resetAlert())
    }
  }

  const handleSuccessChangePassword = () => {
    setIsShow(false)
    setPassword('')
    setEmail('')
  }

  const renderIcon = () => {
    const size = 14
    if (inputVisibility === false) {
      return <Eye size={size} />
    } else {
      return <EyeOff size={size} />
    }
  }

  if (token !== null && (userNotPartnership || alreadychangepass === 1)) {
    return <Navigate to="/" replace />
  }

  return (
    <Container fluid>
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <ModalConnectAccount />
        {!isShow && (
          <Card style={{ maxWidth: '600px', width: '100%' }}>
            <CardBody>
              <div className="d-flex justify-content-center align-items-center mb-3">
                <img
                  style={{ width: '100%', maxWidth: '177px', height: 'auto' }}
                  src={LogoPasmik}
                  alt="PasarMIKRO logo"
                />
              </div>
              <CardTitle
                className="d-flex justify-content-center mb-4"
                tag="h4"
              >
                Silakan masuk ke akun anda
              </CardTitle>
              <div>
                <Alert
                  isOpen={isOpenAlert}
                  className="mt-3"
                  toggle={() => {
                    dispatch(toggleAlert(false))
                  }}
                  style={{ backgroundColor: '#FFFAEB', borderColor: '#FEF0C7' }}
                >
                  <div className="d-flex gap-2">
                    <div>
                      <AlertCircle className="text-warning" size={16} />
                    </div>
                    <div>{alertMsg}</div>
                  </div>
                </Alert>
              </div>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    value={email}
                    onChange={(e) => handleChangeEmail(e)}
                    // type="email"
                    autoComplete="off"
                    disabled={isEmail}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Password</Label>
                  <InputGroup>
                    <Input
                      onChange={(e) => {
                        handleChangePassword(e)
                      }}
                      type={inputVisibility === false ? 'password' : 'text'}
                      autoComplete="off"
                      className="passwd"
                      invalid={isError}
                    />
                    <InputGroupText
                      onClick={() => setInputVisibility(!inputVisibility)}
                      style={{ cursor: 'pointer' }}
                      className={`${isError ? 'icon-step-2 step-2-invalid' : 'icon-step-2'}`}
                    >
                      {renderIcon()}
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>
                {isError && (
                  <FormGroup>
                    <small className="text-danger d-flex justify-content-start align-items-center">
                      <AlertCircle size={15} className="me-1" />{' '}
                      <span>{error}</span>
                    </small>
                  </FormGroup>
                )}
                {/* {isEmail && (
                  <div
                    onClick={() => {
                      setEmail('')
                      nav(`${URL_LOGIN}`, { replace: true })
                    }}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    <h5 className="text-normal text-primary">
                      Masuk dengan akun lain
                    </h5>
                  </div>
                )} */}
                {isEmail ? (
                  <div
                    onClick={() => {
                      setEmail('')
                      nav(`${URL_LOGIN}`, { replace: true })
                    }}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    <h5 className="text-normal text-primary">
                      Masuk dengan akun lain
                    </h5>
                  </div>
                ) : (
                  <Link
                    to={URL_FORGET_PASSWORD_STEP_1}
                    style={{ textDecoration: 'none' }}
                  >
                    <h5 className="text-normal">Lupa password?</h5>
                  </Link>
                )}
                <CustomButton
                  disabled={loading || !email || !password}
                  block
                  color="primary"
                  type="submit"
                  // onClick={() => {
                  //   dispatch(toggleModal())
                  // }}
                >
                  {loading ? <Spinner size="sm" /> : 'Masuk'}
                </CustomButton>
              </Form>
            </CardBody>
          </Card>
        )}
        {isShow && (
          <ChangePasswordPertama
            token={token}
            oldpassword={password}
            cb={handleSuccessChangePassword}
          />
        )}
      </div>
    </Container>
  )
}

export default LoginView

export function loader({ request }) {
  const url = new URL(request.url)
  const email = url.searchParams.get('email')
  const isEmail = emailCheck(email)

  return {
    data: email ?? null,
    isEmail: isEmail ?? false,
  }
}
