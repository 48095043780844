import arusKasIcon from 'assets/icons/arus-kas.svg'
import IconCompanies from 'assets/icons/icon-companies.svg'
import IconFish from 'assets/icons/icon-fish.svg'
import IconFisheries from 'assets/icons/icon-fisheries.svg'
import IconGears from 'assets/icons/icon-gears.svg'
import IconPorts from 'assets/icons/icon-ports.svg'
import IconShip from 'assets/icons/icon-ship.svg'
import piutangIcon from 'assets/icons/piutang.svg'
import switchVerticalIcon from 'assets/icons/switch-vertical.svg'
import utangIcon from 'assets/icons/utang.svg'
import { Box, CheckCircle, Home, Map, Users } from 'react-feather'
import {
  ARUS_KAS,
  URL_APPROVAL_BUY,
  URL_CATCHES,
  URL_COMPANIES,
  URL_FARMERS,
  URL_FISHERIES,
  URL_GEARS,
  URL_MANAGE_STOCK,
  URL_PIUTANG,
  URL_PORTS,
  URL_SPATIAL,
  URL_UTANG,
  URL_VESSELS,
} from 'utils/pathUrl'
import {
  BUSINESS_AUTOREPO,
  BUSINESS_FARMERS,
  BUSINESS_MYAPPROVAL,
  BUSINESS_TALANGIN,
  BUSINESS_TOPUP,
  BUSINESS_TRANSACTION_PURCHASE,
  BUSINESS_TRANSACTION_SELL,
  BUSINESS_VESSELS,
  BUSINESS_WITHDRAWAL,
  MANAGE,
  READ,
} from 'utils/subjectActions'

export const menus = [
  {
    headTitle: 'TRANSAKSI',
    action: READ,
    subject:
      BUSINESS_TALANGIN ||
      BUSINESS_TOPUP ||
      BUSINESS_TRANSACTION_PURCHASE ||
      BUSINESS_TRANSACTION_SELL ||
      BUSINESS_WITHDRAWAL ||
      BUSINESS_FARMERS ||
      BUSINESS_MYAPPROVAL ||
      BUSINESS_AUTOREPO,
    items: [
      {
        title: 'Beranda',
        icon: <Home size={22} id="beranda" />,
        path: '/',
        action: READ,
        subject:
          BUSINESS_TALANGIN ||
          BUSINESS_TOPUP ||
          BUSINESS_TRANSACTION_PURCHASE ||
          BUSINESS_TRANSACTION_SELL ||
          BUSINESS_WITHDRAWAL ||
          BUSINESS_FARMERS,
      },
      {
        title: 'Persetujuan',
        icon: <CheckCircle size={22} id="persetujuan" />,
        path: URL_APPROVAL_BUY,
        action: READ,
        subject: BUSINESS_MYAPPROVAL,
      },
      {
        title: 'Repo Komoditas',
        icon: (
          <img
            src={switchVerticalIcon}
            id="repo-komoditas"
            alt="Repo"
            loading="lazy"
          />
        ),
        path: '/repo-komoditas',
        action: MANAGE,
        subject: BUSINESS_AUTOREPO,
      },
      {
        title: 'Atur Stok',
        icon: <Box size={22} id="atur-stok" />,
        path: URL_MANAGE_STOCK,
        action: MANAGE,
        subject:
          BUSINESS_TALANGIN ||
          BUSINESS_TOPUP ||
          BUSINESS_TRANSACTION_PURCHASE ||
          BUSINESS_TRANSACTION_SELL ||
          BUSINESS_WITHDRAWAL ||
          BUSINESS_FARMERS,
      },
    ],
  },
  {
    headTitle: 'CATATAN',
    action: READ,
    subject:
      BUSINESS_TALANGIN ||
      BUSINESS_TOPUP ||
      BUSINESS_TRANSACTION_PURCHASE ||
      BUSINESS_TRANSACTION_SELL ||
      BUSINESS_WITHDRAWAL ||
      BUSINESS_FARMERS ||
      BUSINESS_MYAPPROVAL ||
      BUSINESS_AUTOREPO,
    items: [
      {
        title: 'Arus Kas',
        icon: (
          <img src={arusKasIcon} id="arus-kas" alt="Arus Kas" loading="lazy" />
        ),
        path: ARUS_KAS,
        action: READ,
        subject:
          BUSINESS_TALANGIN ||
          BUSINESS_TOPUP ||
          BUSINESS_TRANSACTION_PURCHASE ||
          BUSINESS_TRANSACTION_SELL ||
          BUSINESS_WITHDRAWAL ||
          BUSINESS_FARMERS,
      },
      {
        title: 'Utang',
        icon: <img src={utangIcon} id="utang" alt="Utang" loading="lazy" />,
        path: URL_UTANG,
        action: READ,
        subject:
          BUSINESS_TALANGIN ||
          BUSINESS_TOPUP ||
          BUSINESS_TRANSACTION_PURCHASE ||
          BUSINESS_TRANSACTION_SELL ||
          BUSINESS_WITHDRAWAL ||
          BUSINESS_FARMERS,
      },
      {
        title: 'Piutang',
        icon: (
          <img src={piutangIcon} id="piutang" alt="Piutang" loading="lazy" />
        ),
        path: URL_PIUTANG,
        action: READ,
        subject:
          BUSINESS_TALANGIN ||
          BUSINESS_TOPUP ||
          BUSINESS_TRANSACTION_PURCHASE ||
          BUSINESS_TRANSACTION_SELL ||
          BUSINESS_WITHDRAWAL ||
          BUSINESS_FARMERS,
      },
    ],
  },
  {
    headTitle: 'MANAJEMEN ANGGOTA',
    action: MANAGE,
    subject: BUSINESS_FARMERS,
    items: [
      {
        title: 'Daftar Anggota',
        icon: <Users size={22} id="daftar-anggota" />,
        path: URL_FARMERS,
        action: MANAGE,
        subject: BUSINESS_FARMERS,
      },
      {
        title: 'Persebaran Anggota',
        icon: <Map size={22} id="persebaran-anggota" />,
        path: URL_SPATIAL,
        action: MANAGE,
        subject: BUSINESS_FARMERS,
      },
      // {
      //   title: 'Peran dan Hak Akses',
      //   icon: <Settings size={22} id="peran-dan-hak-akses" />,
      //   path: URL_ACCESS_ROLE,
      //   action: MANAGE,
      //   subject: BUSINESS_FARMERS,
      // },
    ],
  },
  {
    headTitle: 'OPERASI AP2HI',
    action: READ,
    subject: BUSINESS_VESSELS,
    items: [
      {
        title: 'Data Tangkapan',
        icon: (
          <img
            src={IconFish}
            id="data-tangkapan"
            alt="data-tangkapan"
            loading="lazy"
          />
        ),
        path: URL_CATCHES,
        action: READ,
        subject: BUSINESS_VESSELS,
      },
    ],
  },
  {
    headTitle: 'MASTER DATA',
    action: READ,
    subject: BUSINESS_VESSELS,
    items: [
      {
        title: 'Vessel',
        icon: <img src={IconShip} id="vessel" alt="vessel" loading="lazy" />,
        path: URL_VESSELS,
        action: READ,
        subject: BUSINESS_VESSELS,
      },
      {
        title: 'Port',
        icon: <img src={IconPorts} id="port" alt="port" loading="lazy" />,
        path: URL_PORTS,
        action: READ,
        subject: BUSINESS_VESSELS,
      },
      {
        title: 'Gear',
        icon: <img src={IconGears} id="gear" alt="gear" loading="lazy" />,
        path: URL_GEARS,
        action: READ,
        subject: BUSINESS_VESSELS,
      },
      {
        title: 'Fishery',
        icon: (
          <img src={IconFisheries} id="fishery" alt="fishery" loading="lazy" />
        ),
        path: URL_FISHERIES,
        action: READ,
        subject: BUSINESS_VESSELS,
      },
      {
        title: 'Company',
        icon: (
          <img src={IconCompanies} id="company" alt="company" loading="lazy" />
        ),
        path: URL_COMPANIES,
        action: READ,
        subject: BUSINESS_VESSELS,
      },
    ],
  },
]
