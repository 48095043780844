import BreadCrumbs from 'components/breadcrumbs'
import { ButtonIcon } from 'components/Buttons'
import CustomAlertMessage from 'components/CustomAlertMessage'
import { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import {
  ChevronDown,
  Eye,
  MapPin,
  MoreVertical,
  Search,
  Trash2,
} from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from 'reactstrap'
import VesselNotFound from '../../../../assets/icons/vessel-not-found.png'
import { clearState } from '../forms/formSlice'
import ConfirmRemoveModalModal from './ConfirmRemoveModal'
import ModalPemetaan from './ModalPemetaan'
import {
  useDeleteVesselByIdMutation,
  useFetchVesselsQuery,
} from './vesselsListApiSlice'
import {
  clearAlert,
  setAlert,
  setDetailVessel,
  setFetchOption,
  setIsSuccess,
  toggleModalPemetaan,
} from './vesselsListSlice'

const VessselsTable = () => {
  const { tag, isOpenBanner, isSuccess, alertType, alertMessage } = useSelector(
    (s) => s.vesselsList,
  )

  const [deleteVesselById, { isLoading: isLoadingDelete }] =
    useDeleteVesselByIdMutation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  let [page, setPage] = useState({ page: tag.page })
  const [setshowModalDelete, setSetshowModalDelete] = useState(false)
  const [dataDetailRemove, setDataDetailRemove] = useState(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownOpenMap, setDropdownOpenMap] = useState({})
  const [search, setSearch] = useState('')
  const [searchTemp, setSearchTemp] = useState('')
  const debounceTimer = useRef(0)
  const dropdownRef = useRef(null)

  const toggleDropdown = (id) => {
    setDropdownOpenMap((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const onOpenCloseModalDelete = () => {
    setSetshowModalDelete((prev) => !prev)
  }

  const onClickIconRemove = (row) => {
    setDataDetailRemove(row)
    onOpenCloseModalDelete()
  }

  const { data, isLoading, refetch } = useFetchVesselsQuery({
    page: page?.page,
    size: 10,
    searchvalue: searchTemp,
  })

  const menuItems = [
    {
      label: 'Pemetaan',
      icon: <MapPin size={16} />,
      action: (row) => {
        dispatch(setDetailVessel(row))
        dispatch(toggleModalPemetaan())
      },
    },
    {
      label: 'Lihat Detail',
      icon: <Eye size={16} />,
      action: (row) => {
        navigate(`/vessels/${row?.id}`)
      },
    },
    {
      label: 'Hapus',
      icon: <Trash2 size={16} />,
      action: (row) => {
        onClickIconRemove(row)
      },
    },
  ]

  const columns = [
    {
      name: 'Image',
      cell: (row) => {
        return (
          <div className="lex-grow-1 text-center">
            <img
              style={{ width: 50, height: 50, borderRadius: '50%' }}
              src={row?.linkImage !== '' ? row?.linkImage : VesselNotFound}
              id="kapal"
              alt="kapal"
              loading="lazy"
            />
          </div>
        )
      },
    },
    {
      name: 'Vessel Name',
      cell: (row) => {
        return <span>{row?.vesselName}</span>
      },
    },
    {
      name: 'Vessel GT',
      cell: (row) => {
        return <span>{row?.vesselGT}</span>
      },
    },
    {
      name: 'Vessel Gear',
      cell: (row) => {
        return <span>{row?.vesselGear}</span>
      },
    },
    {
      name: 'AP2HI UVI',
      cell: (row) => {
        return <span>{row?.ap2hiUVI}</span>
      },
    },
    {
      name: 'License Number',
      cell: (row) => {
        return <span>{row?.licenseNumber}</span>
      },
    },
    {
      name: 'Certification Status',
      cell: (row) => {
        const parseCertification = row?.certStatus ?? ''
        if (!parseCertification) {
          return <span>-</span>
        }
        const validJson = parseCertification.replace(/'/g, '"')
        const certStatusArray = JSON.parse(validJson)
        const certStatus = certStatusArray.join(', ')

        return <span>{certStatus}</span>
      },
    },
    {
      name: '',
      width: '80px',
      cell: (row) => {
        return (
          <div
            style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}
          >
            <Dropdown
              isOpen={dropdownOpenMap[row.id]}
              toggle={() => toggleDropdown(row.id)}
              direction="down"
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpenMap[row.id]}
                style={{ cursor: 'pointer' }}
              >
                <MoreVertical size={20} />
              </DropdownToggle>
              <DropdownMenu container="body">
                {menuItems.map((item, index) => (
                  <DropdownItem
                    className="my-2"
                    key={index}
                    onClick={() => item.action(row)}
                  >
                    {item.icon}
                    <span className="ms-2">{item.label}</span>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    rows: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    table: {
      style: {},
    },
  }

  useEffect(() => {
    dispatch(clearState())

    if (isSuccess) {
      setTimeout(() => {
        dispatch(setIsSuccess())
      }, 3000)
    }
  }, [isSuccess])

  const onRemoveDataVessel = async (id) => {
    try {
      if (!id) {
        dispatch(
          setAlert({
            type: 'danger',
            message: 'Vessel gagal dihapus!',
          }),
        )

        return
      }

      const res = await deleteVesselById({ id }).unwrap()
      if (res) {
        dispatch(
          setAlert({
            type: 'success',
            message: 'Vessel berhasil di hapus!',
          }),
        )
        refetch()
        onOpenCloseModalDelete()
      }
    } catch (error) {
      console.error('Error saat menghapus vessel', error)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ' && search.length === 0) {
      e.preventDefault()
    }
  }

  const onHandleSearchChange = (text) => {
    setSearch(text)
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(() => {
      if (text.trim() !== '') {
        setSearchTemp(text)
        refetch()
      } else {
        setSearch('')
        setSearchTemp('')
        refetch()
      }
    }, 3000)
  }

  // CLEAR ALERT MESSAGE
  useEffect(() => {
    if (alertMessage) {
      scrollToTop()
      setTimeout(() => {
        dispatch(clearAlert())
      }, 3000)
    }
  }, [alertMessage])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className="w-100 px-3">
      <BreadCrumbs breadCrumbActive="Data Kapal" />
      {/* SHOW ALERT */}
      {alertMessage && (
        <CustomAlertMessage type={alertType} message={alertMessage} />
      )}

      <ModalPemetaan />

      <ConfirmRemoveModalModal
        isOpen={setshowModalDelete}
        onClose={() => onOpenCloseModalDelete()}
        data={dataDetailRemove}
        onRemoveData={(id) => {
          onRemoveDataVessel(id)
        }}
        isLoading={isLoadingDelete}
      />

      {/* {isSuccess && (
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            width: '100%',
            color: '#101828',
            backgroundColor: '#ECFDF3',
            borderColor: '#027948',
            borderWidth: '1px',
            borderStyle: 'solid',
            margin: '14px 0px',
            borderRadius: '10px',
            padding: '20px',
          }}
        >
          <small className="text-success">
            <CheckCircle size={15} /> Nelayan berhasil ditambahkan!
          </small>
        </div>
      )} */}

      <Card className="my-4">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading}
            responsive
            persistTableHead
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada kapal yang didaftarkan
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => {
              return (
                <CardFooter
                  className="border border-top-0"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-sm">
                      {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                    </div>
                    <div className="d-flex flex-centered gap-1">
                      <ButtonIcon
                        btnTitle="Sebelumnya"
                        btnProps={{
                          disabled: page.page === 0,
                          size: 'sm',
                          color: 'secondary',
                          onClick: () => {
                            setPage((prev) => ({ page: prev.page - 1 }))
                            dispatch(
                              setFetchOption({
                                page: page.page - 1,
                              }),
                            )
                            scrollToTop()
                          },
                        }}
                        btnTitleProps={{
                          className: 'text-sm',
                        }}
                      />
                      <ButtonIcon
                        btnTitle="Selanjutnya"
                        btnProps={{
                          disabled:
                            page.page ===
                            Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                          size: 'sm',
                          color: 'secondary',
                          onClick: () => {
                            setPage((prev) => ({ page: prev.page + 1 }))
                            dispatch(
                              setFetchOption({
                                page: page.page + 1,
                              }),
                            )
                            scrollToTop()
                          },
                        }}
                        btnTitleProps={{
                          className: 'text-sm',
                        }}
                      />
                    </div>
                  </div>
                </CardFooter>
              )
            }}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Kapal</h5>

                  <Button
                    onClick={() => navigate('/vessels/add')}
                    size="sm"
                    color="primary"
                  >
                    + Tambah Kapal
                  </Button>
                </div>

                <div className="d-flex justify-content-between align-items-center my-4">
                  <InputGroup style={{ borderColor: '#D0D5DD' }}>
                    <InputGroupText
                      style={{
                        backgroundColor: 'white',
                        borderRightColor: 'white',
                      }}
                    >
                      <Search color="#667085" size={16} />
                    </InputGroupText>
                    <Input
                      placeholder="Cari nama vessel..."
                      onChange={(e) => onHandleSearchChange(e.target.value)}
                      onKeyDown={handleKeyDown}
                      style={{ maxWidth: '250px' }}
                      value={search}
                    />
                  </InputGroup>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default VessselsTable
