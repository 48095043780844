import { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { List } from 'reactstrap'
import { URL_FARMERS, URL_FARMERS_GROUPS } from 'utils/pathUrl'
import { getGroupList } from './groups-list/groupListAction'
import pasmikLogo from 'assets/icons/logo-pasmik-updown.svg'

const FarmersWeb = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { groups } = useSelector((s) => s.groupsList)
  const { ownerData } = useSelector((s) => s.dashboard)

  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)

  useEffect(() => {
    if (groups?.length < 1) {
      dispatch(getGroupList())
    }
  }, [groups])

  return (
    <div className="d-flex w-100">
      <div className="bg-white border border-left-0" style={{ width: '220px' }}>
        <List type="unstyled" className="py-3">
          <li
            className={`hover text-sm menu-categories-approval ${
              location.pathname === `${URL_FARMERS}` && 'active fw-500'
            }`}
            onClick={() => navigate(URL_FARMERS)}
            style={{ cursor: 'pointer' }}
          >
            <div className="d-flex mx-2 align-items-center gap-2">
              <div
                className="rounded-circle flex-centered p-1"
                style={{
                  backgroundColor: '#F2F4F7',
                }}
              >
                <img
                  alt="PasarMIKRO Logo"
                  src={ownerData?.companyLogo ?? pasmikLogo}
                  height={40}
                  width={40}
                />
              </div>
              {ownerData?.companyName}
            </div>
          </li>

          <li
            className={`hover text-sm menu-categories-approval ${
              location.pathname === `${URL_FARMERS_GROUPS}` && 'active fw-500'
            }`}
            onClick={() => {
              navigate(URL_FARMERS_GROUPS)
              toggleOpen()
            }}
            style={{ cursor: 'pointer' }}
          >
            <div className="mx-2 d-flex justify-content-between">
              Grup{' '}
              {groups?.items?.length > 0 || groups?.length > 0 ? (
                <div>
                  {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>
              ) : (
                ''
              )}
            </div>
          </li>
          {isOpen ? (
            groups?.items?.map((e, i) => {
              return (
                <li
                  key={i}
                  className={`hover text-sm menu-categories-approval ${
                    location.pathname === `/farmers/groups/${e?.code}` &&
                    'active fw-500'
                  }`}
                  onClick={() =>
                    navigate(`/farmers/groups/${e?.code}`, {
                      state: { ...e },
                    })
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <div className="ms-3 me-2">{e?.name}</div>
                </li>
              )
            })
          ) : (
            <></>
          )}
        </List>
      </div>
      <Outlet />
    </div>
  )
}

export default FarmersWeb
