import { createAppSlice } from 'app/createAppSlice'
import { getGroupCount, getGroupList } from './groupListAction'

const initialState = {
  isOpenSidebar: false,
  tag: {
    size: 10,
    page: 0,
  },
  tagGroups: {
    size: 10,
    page: 0,
  },
  tags: {
    123: {
      size: 10,
      page: 0,
    },
  },
  isOpenBanner: false,
  isOpenBannerGroups: false,
  details: null,
  isOpenModal: false,
  loading: false,
  bannerMsg: '',
  groups: [],
  allGroupCount: 0,
}

export const groupsListSlice = createAppSlice({
  name: 'groupsList',
  initialState,
  reducers: (create) => ({
    toggleSidebarWithdraw: create.reducer((state, { payload }) => {
      state.isOpenSidebar = !state.isOpenSidebar
    }),
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
    setFetchOptionGroups: create.reducer((state, { payload }) => {
      state.tagGroups.size = payload.size
      state.tagGroups.page = payload.page
    }),
    setFetchOptionSubGroup: create.reducer((state, { payload }) => {
      state.tags[payload.id].size = payload.size
      state.tags[payload.id].page = payload.page
    }),
    toggleBanner: create.reducer((state, { payload }) => {
      state.isOpenBanner = !state.isOpenBanner
    }),
    toggleBannerGroups: create.reducer((state, { payload }) => {
      state.isOpenBannerGroups = !state.isOpenBannerGroups
    }),
    toggleModal: create.reducer((state, { payload }) => {
      state.isOpenModal = !state.isOpenModal
    }),
    setBannerMsg: create.reducer((state, { payload }) => {
      state.bannerMsg = payload
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(getGroupList.pending, (state) => {
        state.loading = true
      })
      .addCase(getGroupList.fulfilled, (state, { payload }) => {
        state.loading = false
        state.groups = payload
      })
      .addCase(getGroupList.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(getGroupCount.pending, (state) => {
        state.loading = true
      })
      .addCase(getGroupCount.fulfilled, (state, { payload }) => {
        state.loading = false
        state.allGroupCount = payload
      })
      .addCase(getGroupCount.rejected, (state, { payload }) => {
        state.loading = false
      })
  },
})

export const {
  setFetchOption,
  toggleSidebarWithdraw,
  toggleBanner,
  toggleModal,
  setFetchOptionGroups,
  setFetchOptionSubGroup,
  toggleBannerGroups,
  setBannerMsg,
} = groupsListSlice.actions

export default groupsListSlice.reducer
