import IPhoto from 'assets/icons/Pak-Tani.png'
import { ButtonIcon } from 'components/Buttons'
import LoadingAnimation from 'components/LoadingAnimation'
import { TableComponent as DataTable } from 'components/TableComponent'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { useLocalStorageDashboard } from 'hooks/useLocalStorageDashboard'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Badge, Card, CardBody, CardFooter, CardText } from 'reactstrap'
import {
  BUSINESS_TRANSACTION_PURCHASE,
  BUSINESS_TRANSACTION_SELL,
  READ,
} from 'utils/subjectActions'
import { TRANSACTION_SELL_OR_BUY } from 'utils/tags'
import SidebarDashboard from '../SidebarDashboard'
import BtnMakeTransaction from './BtnMakeTransaction'

import useGetTrxDashboard from 'hooks/useGetTrxDashboard'
import moment from 'moment-timezone'
import ICTalangin from 'assets/icons/uang-usaha.png'
import ICUangBelanja from 'assets/icons/uang-belanja-wallet.png'
import SidebarFilter from '../SidebarFilter'
import { useDispatch, useSelector } from 'react-redux'
import { setFetchOption, toggleSidebarFilter } from '../dashboardSlice'
import HeaderTable from './HeaderTable'

const TableDashboard = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { isOpenSidebarFilter, tag } = useSelector((s) => s.dashboard)
  let [page, setPage] = useState({ page: tag?.page ?? 0 })

  const abilityBuy = useAbilityAccess({
    action: READ,
    subject: BUSINESS_TRANSACTION_PURCHASE,
  })

  const abilitySell = useAbilityAccess({
    action: READ,
    subject: BUSINESS_TRANSACTION_SELL,
  })

  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [isRefetch, setIsRefetch] = useState(false)

  const handleFilter = (data) => {
    setIsRefetch(true)
  }

  const handleCallback = () => {
    setIsRefetch(true)
  }
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const toggleSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar)
  }

  const trx = useGetTrxDashboard(
    { ...tag },
    {
      skip: abilitySell && !abilityBuy,
    },
  )

  const columns = [
    {
      name: <span>Tanggal transaksi</span>,
      cell: (row) => (
        <div className="d-flex flex-column">
          <span>{moment(row?.createdAt).format('Do MMMM YYYY')}</span>
          <span className="text-muted">
            {moment(row?.createdAt).format('HH:mm')}
          </span>
        </div>
      ),
    },
    {
      name: <span>{tag?.type === 'SELL' ? 'Pembeli' : 'Penjual'}</span>,
      cell: (row) => {
        return (
          <div className="flex-centered gap-2">
            {tag?.type === 'BUY' && (
              <>
                <img
                  src={row?.sellerOrBuyAvatar ?? IPhoto}
                  className="rounded-circle"
                  alt="pak tani"
                  width={20}
                  height={20}
                />
                <span>{row.sellerDetail?.fullName}</span>
              </>
            )}
            {tag?.type === 'SELL' && (
              <>
                <img
                  src={row?.sellerOrBuyAvatar ?? IPhoto}
                  className="rounded-circle"
                  alt="pak tani"
                  width={20}
                  height={20}
                />
                <span>{row.buyerDetail?.fullName}</span>
              </>
            )}
          </div>
        )
      },
    },
    {
      name: <span>Komoditas</span>,
      cell: (row) => (
        <div className="d-flex flex-column py-2">
          <span className="flex-row">{row?.itemDetail?.[0]?.nameItem}</span>
          <span className="text-primary">
            {intl.formatNumber(row?.itemDetail?.[0]?.amount, {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}{' '}
            {row?.itemDetail?.[0]?.unit} x Rp
            {intl.formatNumber(row?.itemDetail?.[0]?.price, {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </span>
          {row?.itemDetail?.length > 1 ? (
            <div className="bg-gray-100 badge rounded-pill my-1">
              <span className="text-dark fw-medium">
                +{' '}
                {intl.formatNumber(row?.itemDetail?.length - 1, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}{' '}
                komoditas lainnya
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      name: <span>Total Transaksi</span>,
      cell: (row) => (
        <span>
          Rp
          {intl.formatNumber(row?.totalAmount, {
            useGrouping: 'always',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </span>
      ),
    },
    // {
    //   name: 'Dibuat oleh',
    //   cell: (row) => {
    //     return (
    //       <div className="flex-centered gap-2">
    //         <img
    //           src={row?.requesterAvatar ?? IPhoto}
    //           alt="pak tani"
    //           width={20}
    //           height={20}
    //           className="rounded-circle"
    //         />
    //         <span>
    //           {' '}
    //           {row?.requesterName
    //             ? row?.requesterName
    //             : row?.orderDetail?.type === 'BUY'
    //               ? row?.buyerDetail?.fullName
    //               : row?.sellerDetail?.fullName}
    //         </span>
    //       </div>
    //     )
    //   },
    // },
    {
      name: <span>Status</span>,
      cell: (row) => {
        if (row.status?.toLowerCase() === 'selesai') {
          return (
            <div className="badge rounded-pill bg-success-50 text-success px-2">
              {row.status}
            </div>
          )
        }

        if (row.status?.toLowerCase() === 'menunggu proses penjual') {
          return (
            <div className="badge rounded-pill bg-warning-50 text-warning-700 px-2">
              {row.status}
            </div>
          )
        }
        if (row.status?.toLowerCase() === 'menunggu approval admin') {
          return (
            <div className="badge rounded-pill bg-warning-50 text-warning-700 px-2">
              {row.status}
            </div>
          )
        }

        if (row.status?.toLowerCase() === 'diproses') {
          return (
            <div className="badge rounded-pill bg-primary-50 text-primary px-2">
              {row.status}
            </div>
          )
        }

        if (row.status?.toLowerCase() === 'dibatalkan') {
          return (
            <div className="badge rounded-pill bg-danger-50 text-danger-700 px-2">
              {row.status}
            </div>
          )
        }
        if (row.status?.toLowerCase() === 'menunggu pembayaran pembeli') {
          return (
            <div className="badge rounded-pill bg-warning-50 text-warning-700 px-2">
              {row.status}
            </div>
          )
        }
      },
    },
    {
      name: <span>Metode pembayaran</span>,
      cell: (row) => (
        <div
          className={`badge rounded-pill ${!row?.creditRequested ? 'bg-primary-50' : 'bg-warning-50'} d-flex align-items-center gap-1 px-2 `}
        >
          <img
            width={12}
            height={12}
            src={!row?.creditRequested ? ICUangBelanja : ICTalangin}
            alt={!row?.creditRequested ? 'Uang Belanja' : 'Talangin'}
          />
          <span
            className={`${!row?.creditRequested ? 'text-primary' : 'text-dark'}`}
          >
            {!row?.creditRequested ? 'Uang Belanja' : 'Talangin'}
          </span>
        </div>
      ),
    },
    {
      name: '',
      selector: (row) => (
        <div
          className="fw-600"
          onClick={() => {
            toggleSidebar()
            setSelectedRow(row)
          }}
          style={{ cursor: 'pointer' }}
        >
          Lihat Detail
        </div>
      ),
      center: true,
    },
  ]

  useEffect(() => {
    if (isRefetch) {
      trx?.refetch()
      setIsRefetch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetch])

  return (
    <>
      <SidebarFilter
        handleFilter={handleFilter}
        open={isOpenSidebarFilter}
        toggle={() => dispatch(toggleSidebarFilter())}
      />
      <SidebarDashboard
        isOpen={isOpenSidebar}
        toggle={toggleSidebar}
        toggleModal={toggle}
        row={selectedRow}
        isOpenModal={isOpen}
        handleCallback={handleCallback}
        options={tag}
      />
      <Card>
        <CardBody className="py-0 px-3 d-flex justify-content-between mt-4">
          <CardText tag="h4">Transaksi</CardText>
          <BtnMakeTransaction />
        </CardBody>
        <CardBody>
          {(abilityBuy || abilitySell) && (
            <DataTable
              columns={columns}
              data={trx?.data?.items}
              progressPending={trx?.isLoading || trx?.isFetching}
              paginationTotalRows={trx?.data?.totalItems}
              progressComponent={<LoadingAnimation />}
              pagination
              paginationServer
              paginationComponent={(props) => {
                return (
                  <CardFooter
                    className="border border-top-0"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="text-sm">
                        {`Halaman ${tag?.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                      </div>
                      <div className="flex-centered gap-1">
                        <ButtonIcon
                          btnTitle="Sebelumnya"
                          btnProps={{
                            disabled: tag?.page === 0,
                            size: 'sm',
                            color: 'secondary',
                            onClick: () => {
                              setPage((prev) => ({ page: prev.page - 1 }))
                              dispatch(
                                setFetchOption({
                                  page: page.page - 1,
                                }),
                              )
                            },
                          }}
                          btnTitleProps={{
                            className: 'text-sm',
                          }}
                        />
                        <ButtonIcon
                          btnTitle="Selanjutnya"
                          btnProps={{
                            disabled:
                              tag?.page ===
                              Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                            size: 'sm',
                            color: 'secondary',
                            onClick: () => {
                              setPage((prev) => ({ page: prev.page + 1 }))
                              dispatch(
                                setFetchOption({
                                  page: page.page + 1,
                                }),
                              )
                            },
                          }}
                          btnTitleProps={{
                            className: 'text-sm',
                          }}
                        />
                      </div>
                    </div>
                  </CardFooter>
                )
              }}
              subHeader={true}
              subHeaderAlign={'center'}
              subHeaderComponent={
                <HeaderTable
                  abilityBuy={abilityBuy}
                  abilitySell={abilitySell}
                  type={tag?.type}
                />
              }
            />
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default TableDashboard
