import CustomButton from 'components/Buttons/CustomButton'
import { Phone } from 'react-feather'
import { Card, CardBody, Modal } from 'reactstrap'

const ModalPendingTopup = ({ isOpen, toggle }) => {
  return (
    <Modal centered size="sm" isOpen={isOpen} toggle={toggle}>
      <Card>
        <CardBody className="d-flex flex-column align-items-center">
          <p className="fw-semibold">Transaksi Diproses</p>
          <p className="small">
            Transaksi anda sedang dalam proses. Kami akan memberi notifikasi
            apabila proses sudah selesai.
          </p>
          <CustomButton
            block
            outline
            color="primary"
            onClick={() =>
              window.open('https://wa.me/+6285157570025', '_blank')
            }
          >
            <Phone size={17} /> <span> Hubungi CS</span>
          </CustomButton>
        </CardBody>
      </Card>
    </Modal>
  )
}

export default ModalPendingTopup
