import checkBox from 'assets/icons/check-box.svg'
import checkedBox from 'assets/icons/checked-box.svg'
import merge from 'assets/icons/merge.svg'

import IPhoto from 'assets/icons/Pak-Tani.png'
import { ButtonIcon } from 'components/Buttons'
import CustomButton from 'components/Buttons/CustomButton'
import { LoadingCenter } from 'components/Loading'
import SidebarDetails from 'components/SidebarDetails'
import { menuStock } from 'constants'
import { useEffect, useRef, useState } from 'react'
import { TableComponent as DataTable } from 'components/TableComponent'
import {
  AlignCenter,
  CheckCircle,
  Copy,
  Eye,
  MoreVertical,
  Search,
  Trash2,
  X,
} from 'react-feather'
import { useDispatch } from 'react-redux'
import {
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap'
import {
  createStock,
  getInventory,
  getItemsInventory,
  mergeInventory,
  removeInventory,
  splitInventory,
  transformInventory,
  updateInventory,
} from './API/manageStockAction'
import {
  ActionModal,
  AddStockModal,
  AddStockSidebar,
  ConfirmRemoveModal,
  ConfirmRemoveMultipleModal,
  DetailModal,
  EditStockModal,
  IDStockModal,
  ItemModal,
  ItemUnitModal,
  MergeStockModal,
  SortModal,
  SplitStockModal,
} from './Components'
import './styles.css'

export default function ManageStock() {
  const actionMenuRef = useRef(null)
  const dispatch = useDispatch()
  const debounceTimer = useRef(0)
  const [showModal, setShowModal] = useState({
    menuOption: false,
    detail: false,
    qrCode: false,
    remove: false,
    filter: false,
    edit: false,
    add: false,
    item: false,
    itemUnit: false,
    merge: false,
    split: false,
    confirmRemove: false,
  })
  const [selectedSort, setSelectedSort] = useState({})
  const [inventoryList, setInventoryList] = useState([])
  const [inventoryMergeList, setInventoryMergeList] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPage] = useState(0)
  const [selectedStock, setSelectedStock] = useState({})
  const [searchInventory, setSearchInventory] = useState('')
  const [selectedInventoryList, setSelectedInventoryList] = useState([])
  const [itemsList, setItemsList] = useState([])
  const [dataStock, setDataStock] = useState({ unit: 'Kg', description: '' })
  const [selectedUnit, setSelectedUnit] = useState({})
  const [selectedEditAction, setSelectedEditAction] = useState({})
  const [position, setPosition] = useState({})
  const [locationBlocked, setLocationBlocked] = useState(false)
  const [splitItems, setSplitItems] = useState([])
  const [enableSplit, setEnableSplit] = useState(false)
  const [indexSelectedSplitStock, setIndexSelectedSplitStock] = useState(null)
  const [loading, setLoading] = useState({
    first: false,
  })
  const [toast, setToast] = useState({
    show: false,
    title: '',
    type: '',
  })

  useEffect(() => {
    setLoading((prevState) => ({ ...prevState, first: true }))
    fetchInventory(0)
    fetchItems()
    // getMyLocation()
  }, [])

  const fetchItems = () => {
    dispatch(getItemsInventory()).then((res) => {
      setItemsList(res?.payload?.items)
    })
  }

  const fetchInventory = (page, search, sortBy, asc) => {
    dispatch(
      getInventory({
        page: page,
        search: search,
        sortBy: sortBy,
        asc: asc,
      }),
    ).then((res) => {
      // if (page > 0) {
      //   setInventoryMergeList((prevInventory) => [
      //     ...prevInventory,
      //     ...(res?.payload?.items ?? []),
      //   ])
      // } else {
      //   setInventoryMergeList(res?.payload?.items)
      // }
      setInventoryList(res?.payload?.items)
      setLoading((prevState) => ({ ...prevState, first: false }))
      setTotalPage(res?.payload?.totalPages)
      setCurrentPage(res?.payload?.currentPage)
    })
  }

  const onSelectFilter = (item) => {
    setSelectedSort(item)
    fetchInventory(0, searchInventory, item?.type, item?.mode)
    setTimeout(() => {
      onShowHideModal('filter', false)
    }, 500)
  }

  const onShowHideModal = (key, value, editType, index) => {
    if (editType) {
      setSelectedEditAction(editType)
    }
    if (index) {
      setIndexSelectedSplitStock(index)
    }
    if (key === 'split' && value) {
      addSplitItem()
    }
    setShowModal((prevState) => ({
      ...prevState,
      menuOption: false,
      [key]: value,
    }))
  }

  const addSplitItem = () => {
    if (splitItems?.[0]?.amount == 1) {
      return
    }
    let newItems = []
    const currentSplitAmount = splitItems?.length || 1
    let totalAmount = 0
    for (let i = 0; i <= currentSplitAmount; i++) {
      if (i == currentSplitAmount) {
        newItems.unshift({
          nameItem: selectedStock?.nameItem || selectedStock?.SKU,
          itemId: selectedStock?.itemId || selectedStock?.item?.id,
          amount: selectedStock?.amount - totalAmount,
          unit: selectedStock?.unit,
          desc: selectedStock?.description || '',
        })
      } else {
        const amountAdded = Math.floor(
          selectedStock?.amount / (currentSplitAmount + 1),
        )
        newItems.unshift({
          nameItem: selectedStock?.nameItem || selectedStock?.SKU,
          itemId: selectedStock?.itemId || selectedStock?.item?.id,
          amount: amountAdded,
          unit: selectedStock?.unit,
          desc: selectedStock?.description || '',
        })
        totalAmount += amountAdded
      }
    }
    setSplitItems(newItems)
    setEnableSplit(newItems[0]?.amount > 1)
  }

  const onSelectStock = (item, mode) => {
    // setInventoryMergeList((prevList) =>
    //   prevList?.map((el) =>
    //     el.id === item.id ? { ...el, selected: !el?.selected } : el,
    //   ),
    // )
    setSelectedInventoryList((prevList) => {
      if (prevList.includes(item.id)) {
        return prevList.filter((id) => id !== item.id)
      } else {
        return [...prevList, item.id]
      }
    })
  }

  const onSelectStockMenu = (item) => {
    setSelectedStock({ ...item?.item, ...item })
    setInventoryList((prevList) =>
      prevList.map((el) =>
        el.id === item.id
          ? { ...el, showSelectedMenu: !el.showSelectedMenu }
          : { ...el, showSelectedMenu: false },
      ),
    )
  }

  const onClickNextBack = (type) => {
    if (type === 'next') {
      fetchInventory(
        currentPage + 1,
        searchInventory,
        selectedSort?.type,
        selectedSort?.mode,
      )
    } else {
      fetchInventory(
        currentPage - 1,
        searchInventory,
        selectedSort?.type,
        selectedSort?.mode,
      )
    }
  }

  const handleSearchChange = (text) => {
    setSearchInventory(text)
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(() => {
      if (text.trim() !== '') {
        fetchInventory(0, text, selectedSort?.type, selectedSort?.mode)
      } else {
        fetchInventory(0, '', selectedSort?.type, selectedSort?.mode)
      }
    }, 3000)
  }

  const onSelectItem = (item) => {
    if (indexSelectedSplitStock >= 0) {
      let newItems = [...splitItems]
      newItems[indexSelectedSplitStock] = {
        ...newItems[indexSelectedSplitStock],
        itemId: item?.id,
        nameItem: item?.nameItem,
      }
      setSplitItems(newItems)
    }
    onChangeDataStock('nameItem', item?.nameItem)
    onChangeDataStock('itemId', item?.id)
    onChangeDataStock('linkFoto', item?.linkFoto)
    onChangeSelectedDataStock('nameItem', item?.nameItem)
    onChangeSelectedDataStock('itemId', item?.id)
    onChangeSelectedDataStock('linkFoto', item?.linkFoto)
    onShowHideModal('item', false)
  }

  const onSelectItemUnit = (item) => {
    setSelectedUnit(item)
    onChangeDataStock('unit', item?.title)
    onChangeSelectedDataStock('unit', item?.title)
    onShowHideModal('itemUnit', false)
    onChangeSelectedDataStock('unit', item?.title)
  }

  const onChangeSelectedDataStock = (key, value) => {
    if (key === 'amount') {
      setSelectedStock((prevState) => ({
        ...prevState,
        [key]: Number(value),
      }))
    } else {
      setSelectedStock((prevState) => ({
        ...prevState,
        [key]: value,
      }))
    }
  }

  const onChangeDataStock = (key, value) => {
    if (key === 'amount') {
      setDataStock((prevState) => ({
        ...prevState,
        [key]: Number(value),
      }))
    } else {
      setDataStock((prevState) => ({
        ...prevState,
        [key]: value,
      }))
    }
  }

  const getMyLocation = async () => {
    try {
      const location = window.navigator && window.navigator.geolocation

      if (!location) {
        throw new Error('Geolocation not supported')
      }

      const result = await new Promise((resolve, reject) => {
        location.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords?.latitude,
              longitude: position.coords?.longitude,
              locationBlocked: false,
            })
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              resolve({
                locationBlocked: true,
              })
            } else {
              reject(error)
            }
          },
        )
      })

      if (result.locationBlocked) {
        setLocationBlocked(true)
      } else {
        setPosition({
          latitude: result.latitude,
          longitude: result.longitude,
        })
        setLocationBlocked(false)
      }

      return result
    } catch (error) {
      console.error('Error getting location:', error)
      throw error
    }
  }

  const onCreateStock = async () => {
    const result = await getMyLocation()
    if (result?.locationBlocked) {
      return
    }
    setLoading((prevState) => ({ ...prevState, create: true }))
    let payload = {
      amount: dataStock?.amount,
      description: dataStock?.description,
      images: [{ url: dataStock?.linkFoto }],
      itemId: dataStock?.itemId,
      latitude: position?.latitude,
      longitude: position?.longitude,
      unit: dataStock?.unit,
    }
    if (locationBlocked) {
      alert('Please enable location services to create stock.')
      setLoading((prevState) => ({ ...prevState, create: false }))
      return
    }
    dispatch(createStock(payload))
      .then((res) => {
        fetchInventory(0)
        onShowHideModal('add', false)
        setDataStock({})
        setLoading((prevState) => ({ ...prevState, create: false }))
        setToast({
          show: true,
          title: 'Stok baru berhasil ditambahkan',
          type: 'success',
        })
        setTimeout(() => {
          setToast({ show: false })
        }, 2000)
      })
      .catch((err) => {
        console.log(err, 'err')
        setLoading((prevState) => ({ ...prevState, create: false }))
      })
  }

  const onUpdateStock = async () => {
    const result = await getMyLocation()
    if (result?.locationBlocked) {
      return
    }
    setLoading((prevState) => ({ ...prevState, update: true }))
    let payload = {
      amount: selectedStock?.amount,
      description: selectedStock?.description,
      images: [{ url: selectedStock?.linkFoto }],
      itemId: selectedStock?.itemId,
      latitude: position?.latitude,
      longitude: position?.longitude,
      unit: selectedStock?.unit,
      inventoryId: selectedStock?.id,
    }

    if (locationBlocked) {
      alert('Please enable location services to create stock.')
      return
    }
    if (selectedEditAction !== 'general') {
      dispatch(updateInventory(payload))
        .then((res) => {
          fetchInventory(0)
          onShowHideModal('edit', false)
          setSelectedStock((prevState) => ({
            ...prevState,
            amount: selectedStock?.amount,
            unit: selectedStock?.unit,
            item: { ...prevState?.item, linkFoto: selectedStock?.linkFoto },
            description: selectedStock?.description,
          }))
          setLoading((prevState) => ({ ...prevState, update: false }))
        })
        .catch((err) => {
          console.log(err, 'err')
          setLoading((prevState) => ({ ...prevState, update: false }))
        })
    } else {
      dispatch(transformInventory(payload))
        .then((res) => {
          fetchInventory(0)
          onShowHideModal('edit', false)
          setLoading((prevState) => ({ ...prevState, update: false }))
        })
        .catch((err) => {
          console.log(err, 'err')
          setLoading((prevState) => ({ ...prevState, update: false }))
        })
    }
  }

  const onSplitStock = async () => {
    const result = await getMyLocation()
    if (result?.locationBlocked) {
      return
    }
    setLoading((prevState) => ({ ...prevState, split: true }))
    let payload = {
      arrNew: splitItems,
      latitude: position?.latitude,
      longitude: position?.longitude,
      inventoryId: selectedStock?.id,
    }
    if (locationBlocked) {
      alert('Please enable location services to create stock.')
      return
    }
    dispatch(splitInventory(payload))
      .then((res) => {
        fetchInventory(0)
        onShowHideModal('split', false)
        setSplitItems([])
        setToast({
          show: true,
          title: 'Stok berhasil dipisah.',
          type: 'success',
        })
        setTimeout(() => {
          setToast({ show: false })
        }, 2000)
        setLoading((prevState) => ({ ...prevState, split: false }))
      })
      .catch((err) => {
        console.log(err, 'err')
        setLoading((prevState) => ({ ...prevState, split: false }))
      })
  }

  const onMergeStock = async () => {
    const result = await getMyLocation()
    if (result?.locationBlocked) {
      return
    }
    setLoading((prevState) => ({ ...prevState, merge: true }))
    let payload = {
      arrInventoryIds: selectedInventoryList,
      description: dataStock?.description,
      images: [{ url: dataStock?.linkFoto }],
      latitude: position?.latitude,
      longitude: position?.longitude,
      newAmount: Number(dataStock?.amount),
      unit: selectedStock?.unit,
      newItemId: dataStock?.itemId,
    }
    if (locationBlocked) {
      alert('Please enable location services to create stock.')
      return
    }
    dispatch(mergeInventory(payload))
      .then((res) => {
        fetchInventory(0)
        onShowHideModal('add', false)
        onShowHideModal('merge', false)
        onShowHideModal('addStock', false)
        setSelectedInventoryList([])
        setDataStock({})
        setSelectedStock({})
        setLoading((prevState) => ({ ...prevState, merge: false }))
        setToast({
          show: true,
          title: 'Stok berhasil digabungkan.',
          type: 'success',
        })
        setTimeout(() => {
          setToast({ show: false })
        }, 2000)
      })
      .catch((err) => {
        console.log(err, 'err')
        setLoading((prevState) => ({ ...prevState, merge: false }))
      })
  }

  const onRemoveStock = async (id) => {
    const result = await getMyLocation()
    if (result?.locationBlocked) {
      return
    }
    let payload = {
      arrInventoryIds: selectedInventoryList,
    }
    if (typeof id === 'number') {
      payload['arrInventoryIds'] = [id]
    }
    if (locationBlocked) {
      alert('Please enable location services to create stock.')
      return
    }
    dispatch(removeInventory(payload))
      .then((res) => {
        fetchInventory(0)
        onShowHideModal('remove', false)
        onShowHideModal('confirmRemove', false)
        onShowHideModal('confirmRemoveMultiple', false)
        onShowHideModal('detail', false)
        setSelectedInventoryList([])
        setToast({
          show: true,
          title: 'Stok berhasil dihapus.',
          type: 'success',
        })
        setTimeout(() => {
          setToast({ show: false })
        }, 2000)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const onChangeValueStock = (key, value, index) => {
    const sanitizedValue = value.replace(/^0+(?!$)/, '')

    let newItems = [...splitItems]
    newItems[index] = {
      ...newItems[index],
      [key]: sanitizedValue,
    }

    setSplitItems(newItems)
  }

  const handleClickOutside = (event) => {
    if (
      actionMenuRef.current &&
      !actionMenuRef.current.contains(event.target)
    ) {
      onSelectStockMenu(selectedStock)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const columns = [
    {
      name: 'Komoditas',
      cell: (row) => {
        const isSelected = selectedInventoryList.includes(row.id)
        return (
          <div className="d-flex align-items-center">
            <img
              src={isSelected ? checkedBox : checkBox}
              style={{ width: 15, height: 15 }}
              className="button small-margin-right"
              onClick={() => onSelectStock(row)}
            />
            <span>{row?.item?.nameItem}</span>
          </div>
        )
      },
    },
    {
      name: 'Jumlah',
      cell: (row) => {
        return (
          <span>
            {row?.amount} {row?.unit || row?.item?.unit}
          </span>
        )
      },
    },
    {
      name: 'Lokasi',
      cell: (row) => {
        return row?.district ||
          row?.subDistrict ||
          row?.city ||
          row?.province ? (
          <>
            <div className="d-flex flex-column">
              {row?.district || row?.subDistrict ? (
                <span>{row?.district || row?.subDistrict}</span>
              ) : null}
              {row?.city || row?.province ? (
                <span className="fw-light" style={{ color: '#667085' }}>
                  Jakarta
                </span>
              ) : null}
            </div>
          </>
        ) : (
          <span>-</span>
        )
      },
    },
    {
      name: 'Gambar',
      cell: (row) => {
        return (
          <img
            src={row?.item?.linkFoto ?? IPhoto}
            alt="pak tani"
            width={30}
            height={30}
            className="rounded-circle"
          />
        )
      },
    },
    {
      name: '',
      maxWidth: '5px',
      cell: (row) => {
        return (
          <>
            <MoreVertical
              color="#98A2B3"
              className="button"
              onClick={() => onSelectStockMenu(row)}
            />
            {row?.showSelectedMenu && (
              <div className="overlay show-overlay"></div>
            )}
            {row?.showSelectedMenu ? (
              <div className="action-menu" ref={actionMenuRef}>
                {menuStock?.map((el, i) => {
                  return (
                    <div
                      className="mb-3 mt-3 pl-2 button d-flex align-items-center"
                      onClick={() => {
                        onShowHideModal(el?.modal, true, el?.editType)
                        onSelectStockMenu(row)
                      }}
                    >
                      {el?.iconType === 'img' ? (
                        <img
                          src={el?.img}
                          style={{ width: 15, height: 15, marginRight: 10 }}
                        />
                      ) : el?.iconType === 'icon' && el?.icon === 'split' ? (
                        <Copy size={15} style={{ marginRight: 10 }} />
                      ) : el?.icon === 'eye' ? (
                        <Eye size={15} style={{ marginRight: 10 }} />
                      ) : (
                        <Trash2
                          size={15}
                          style={{ marginRight: 10 }}
                          color="#B42318"
                        />
                      )}
                      <CardText
                        style={{
                          fontSize: 12,
                          color: el?.icon === 'trash' ? '#B42318' : null,
                        }}
                      >
                        {el?.title}
                      </CardText>
                    </div>
                  )
                })}
              </div>
            ) : null}
          </>
        )
      },
    },
  ]

  return (
    <div className="w-100 px-3">
      {renderModal()}
      {showModal?.add ? (
        <Container className="custom-container pb-5 mt-4">
          <Card className="w-100">
            <AddStockModal
              locationBlocked={locationBlocked}
              isOpen={showModal?.add}
              onClose={() => onShowHideModal('add', false)}
              items={itemsList}
              dataStock={dataStock}
              onShowItem={onShowHideModal}
              selectedUnit={selectedUnit}
              selectedStock={selectedStock}
              onChangeDataStock={onChangeDataStock}
              loading={loading?.create}
              onCreateStock={() => {
                if (showModal?.merge) {
                  onMergeStock()
                } else {
                  onCreateStock()
                }
              }}
            />
          </Card>
        </Container>
      ) : loading?.first ? (
        <LoadingCenter />
      ) : (
        <Card className="mt-4">
          <CardBody>
            <DataTable
              columns={columns}
              data={inventoryList}
              // progressPending={isLoading}

              paginationTotalRows={10}
              className="border p-0 border-1 rounded-top scrollable-container"
              noDataComponent={
                <p className="my-3 fw-semibold">
                  Belum ada stok yang ditambahkan.
                </p>
              }
              pagination
              paginationServer
              paginationComponent={(props) => {
                return (
                  <CardFooter
                    className="border border-top-0"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="text-sm">{`Halaman ${currentPage + 1} dari ${totalPages + 1}`}</div>
                      <div className="d-flex flex-centered gap-1">
                        <ButtonIcon
                          btnTitle="Sebelumnya"
                          btnProps={{
                            disabled: currentPage === 0,
                            size: 'sm',
                            color: 'secondary',
                            onClick: () => onClickNextBack('back'),
                          }}
                          btnTitleProps={{
                            className: 'text-sm',
                          }}
                        />
                        <ButtonIcon
                          btnTitle="Selanjutnya"
                          btnProps={{
                            disabled: currentPage === totalPages,
                            size: 'sm',
                            color: 'secondary',
                            onClick: () => onClickNextBack('next'),
                          }}
                          btnTitleProps={{
                            className: 'text-sm',
                          }}
                        />
                      </div>
                    </div>
                  </CardFooter>
                )
              }}
              subHeader={true}
              subHeaderComponent={
                <div className="container-fluid">
                  <div className="row justify-content-between mb-1">
                    <div className="col-md-3">
                      <div className="mb-2">
                        <h5 className="fw-semibold">Atur Stok</h5>
                      </div>
                      {selectedInventoryList?.length ? (
                        <InputGroup className="small-padding-vertical">
                          <Input
                            disabled
                            value={`${selectedInventoryList?.length} Stok terpilih`}
                            style={{ backgroundColor: 'white' }}
                          />
                          <InputGroupText>
                            <Trash2
                              size={16}
                              className="button regular-margin-right"
                              onClick={() =>
                                onShowHideModal('confirmRemoveMultiple', true)
                              }
                            />
                            <img
                              src={merge}
                              style={{ width: 16, height: 16 }}
                              className="button"
                              onClick={() => onShowHideModal('addStock', true)}
                            />
                          </InputGroupText>
                        </InputGroup>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-4">
                      <div
                        className="mb-2 text-md-right"
                        style={{ textAlign: 'right' }}
                      >
                        <CustomButton
                          onClick={() => onShowHideModal('add', true)}
                          size="sm"
                          color="primary"
                          style={{
                            borderRadius: 8,
                            paddingRight: 18,
                            paddingLeft: 18,
                          }}
                        >
                          + Tambah Stok
                        </CustomButton>
                      </div>
                      <div className="d-flex small-padding-vertical mb-2">
                        <CustomButton
                          outline
                          className="d-flex align-items-center small-margin-right"
                          style={{ borderColor: '#D0D5DD' }}
                          onClick={() => onShowHideModal('filter', true)}
                        >
                          <AlignCenter
                            size={16}
                            className="small-margin-right"
                          />
                          <span
                            className="fw-semibold"
                            style={{ fontSize: 14 }}
                          >
                            Filter
                          </span>
                        </CustomButton>
                        <InputGroup style={{ borderColor: '#D0D5DD' }}>
                          <InputGroupText
                            style={{
                              backgroundColor: 'white',
                              borderRightColor: 'white',
                            }}
                          >
                            <Search color="#667085" size={16} />
                          </InputGroupText>
                          <Input
                            placeholder="Cari"
                            onChange={(text) =>
                              handleSearchChange(text.target.value)
                            }
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                  {toast?.show ? (
                    <Card
                      className="small-padding mb-4"
                      style={{
                        backgroundColor: '#ECFDF3',
                        borderColor: '#027948',
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <CheckCircle
                            size={16}
                            className="small-margin-right"
                            color="#006386"
                          />
                          <CardText style={{ fontSize: 14 }}>
                            {toast?.title}
                          </CardText>
                        </div>
                        <X
                          className="button"
                          size={16}
                          color="#667085"
                          onClick={() => setToast({ show: false })}
                        />
                      </div>
                    </Card>
                  ) : null}
                </div>
              }
            />
          </CardBody>
        </Card>
      )}
    </div>
  )

  function renderModal() {
    return (
      <>
        <ActionModal
          isOpen={showModal?.menuOption}
          onClose={() => onShowHideModal('menuOption', false)}
          onSelectMenu={(item) =>
            onShowHideModal(item?.modal, true, item?.editType)
          }
        />
        <SidebarDetails
          open={showModal?.qrCode}
          title="Detail Informasi"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => onShowHideModal('qrCode', false)}
        >
          <IDStockModal
            isOpen={showModal?.qrCode}
            onClose={() => onShowHideModal('qrCode', false)}
            selectedStock={selectedStock}
          />
        </SidebarDetails>
        <SortModal
          isOpen={showModal?.filter}
          onClose={() => onShowHideModal('filter', false)}
          onSelect={(item) => onSelectFilter(item)}
          selectedSortId={selectedSort?.id}
        />
        <SidebarDetails
          open={showModal?.edit}
          title={'Ubah Data'}
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => onShowHideModal('edit', false)}
        >
          <EditStockModal
            isOpen={showModal?.edit}
            onClose={() => onShowHideModal('edit', false)}
            selectedEditAction={selectedEditAction}
            selectedStock={selectedStock}
            onChangeSelectedDataStock={onChangeSelectedDataStock}
            onShowItem={onShowHideModal}
            onClickUpdate={onUpdateStock}
            loading={loading?.update}
          />
        </SidebarDetails>
        <SidebarDetails
          open={showModal?.split}
          title="Detail Informasi"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => {
            setSplitItems([])
            onShowHideModal('split', false)
          }}
        >
          <SplitStockModal
            isOpen={showModal?.split}
            selectedStock={selectedStock}
            splitItems={splitItems}
            enableSplit={enableSplit}
            addSplitItem={addSplitItem}
            onShowItem={onShowHideModal}
            onSelectItemUnit={(item, index) => onSelectItemUnit(item, index)}
            setIndexSelectedSplitStock={(index) =>
              setIndexSelectedSplitStock(index)
            }
            onChangeValue={onChangeValueStock}
            onSplitStock={onSplitStock}
            loading={loading.split}
          />
        </SidebarDetails>
        <SidebarDetails
          open={showModal?.merge}
          title="Gabungkan Stok"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => {
            onShowHideModal('merge', false)
          }}
        >
          <MergeStockModal
            selectedList={selectedInventoryList}
            isOpen={showModal?.merge}
            onClose={() => onShowHideModal('merge', false)}
            selectedStock={selectedStock}
            data={inventoryList}
            onSelectStock={(item) => onSelectStock(item)}
            currentPage={currentPage}
            totalPage={totalPages}
            onClickPagination={(type) => onClickNextBack(type)}
            onMergeStock={onMergeStock}
            onShowHideModal={onShowHideModal}
            loading={loading.merge}
          />
        </SidebarDetails>
        <SidebarDetails
          isBack
          handleBack={() => {
            onShowHideModal('merge', true)
            onShowHideModal('addStock', false)
          }}
          open={showModal?.addStock}
          title="Gabungkan Menjadi"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => {
            onShowHideModal('addStock', false)
          }}
        >
          <AddStockSidebar
            onClose={() => onShowHideModal('addStock', false)}
            selectedStock={selectedStock}
            data={inventoryList}
            onShowItem={onShowHideModal}
            currentPage={currentPage}
            totalPage={totalPages}
            onClickPagination={(type) => onClickNextBack(type)}
            onMergeStock={onMergeStock}
            onShowHideModal={onShowHideModal}
            loading={loading.addStock}
            dataStock={dataStock}
            onChangeDataStock={onChangeDataStock}
          />
        </SidebarDetails>
        <SidebarDetails
          open={showModal?.merge}
          title="Gabungkan Stok"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => {
            onShowHideModal('merge', false)
          }}
        >
          <MergeStockModal
            selectedList={selectedInventoryList}
            isOpen={showModal?.merge}
            onClose={() => onShowHideModal('merge', false)}
            selectedStock={selectedStock}
            data={inventoryList}
            onSelectStock={(item) => onSelectStock(item)}
            currentPage={currentPage}
            totalPage={totalPages}
            onClickPagination={(type) => onClickNextBack(type)}
            onMergeStock={onMergeStock}
            onShowHideModal={onShowHideModal}
            loading={loading.merge}
          />
        </SidebarDetails>
        <ItemModal
          isOpen={showModal?.item}
          itemsList={itemsList}
          onClose={() => onShowHideModal('item', false)}
          onSelectItem={(item) => onSelectItem(item)}
        />
        <ItemUnitModal
          isOpen={showModal?.itemUnit}
          onClose={() => onShowHideModal('itemUnit', false)}
          onSelectItemUnit={(item) => onSelectItemUnit(item)}
        />
        <ConfirmRemoveModal
          isOpen={showModal?.confirmRemove}
          onClose={() => onShowHideModal('confirmRemove', false)}
          onSelectItemUnit={(item) => onSelectItemUnit(item)}
          onRemoveStock={() => onRemoveStock(selectedStock?.id)}
        />
        <ConfirmRemoveMultipleModal
          selectedInventoryList={selectedInventoryList}
          isOpen={showModal?.confirmRemoveMultiple}
          onClose={() => onShowHideModal('confirmRemoveMultiple', false)}
          onSelectItemUnit={(item) => onSelectItemUnit(item)}
          onRemoveStock={() => onRemoveStock()}
        />
        <SidebarDetails
          open={showModal?.detail}
          title="Detail Informasi"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => onShowHideModal('detail', false)}
        >
          <DetailModal
            // isOpen={showModal?.detail}
            onClose={() => onShowHideModal('detail', false)}
            selectedStock={selectedStock}
            onClickMenu={() => onShowHideModal('menuOption', true)}
          />
        </SidebarDetails>
      </>
    )
  }
}
