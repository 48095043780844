import BreadCrumbs from 'components/breadcrumbs'
import { ButtonIcon } from 'components/Buttons'
import { useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown, Search } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardFooter, Input, InputGroup, InputGroupText, Spinner } from 'reactstrap'
import { useFetchPortsQuery } from './portsListApiSlice'
import { setFetchOption } from './portsListSlice'

const PortsTable = () => {
  const { tag } = useSelector((s) => s.catchesList)

  const dispatch = useDispatch()

  let [page, setPage] = useState({ page: tag.page })
  const [search, setSearch] = useState('')
  const [searchTemp, setSearchTemp] = useState('')
  const debounceTimer = useRef(0)

  const { data, isLoading, refetch } = useFetchPortsQuery({
    page: page?.page,
    size: 10,
    searchvalue: searchTemp,
  })

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const columns = [
    {
      name: 'Id',
      cell: (row) => {
        return <span>{row?.id ?? '-'}</span>
      },
    },
    {
      name: 'Port Name',
      cell: (row) => {
        return <span>{row?.name ?? '-'}</span>
      },
    },
    {
      name: 'District',
      cell: (row) => {
        return <span>{row?.district ?? '-'}</span>
      },
    },
    {
      name: 'Latitude',
      cell: (row) => {
        return <span>{row?.latitude ?? '-'}</span>
      },
    },
    {
      name: 'Longitude',
      cell: (row) => {
        return <span>{row?.longitude ?? '-'}</span>
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    rows: {
      style: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    table: {
      style: {},
    },
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ' && search.length === 0) {
      e.preventDefault()
    }
  }

  const onHandleSearchChange = (text) => {
    setSearch(text)
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(() => {
      if (text.trim() !== '') {
        setSearchTemp(text)
        refetch()
      } else {
        setSearch('')
        setSearchTemp('')
        refetch()
      }
    }, 3000)
  }

  return (
    <div className="w-100 px-3">
      <BreadCrumbs breadCrumbActive="Data Ports" />

      <Card className="my-4">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading}
            responsive
            persistTableHead
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada daftar ports yang didaftarkan
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <CardFooter
                className="border border-top-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-sm">
                    {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                  </div>
                  <div className="d-flex flex-centered gap-1">
                    <ButtonIcon
                      btnTitle="Sebelumnya"
                      btnProps={{
                        disabled: page.page === 0,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setPage((prev) => ({ page: prev.page - 1 }))
                          dispatch(setFetchOption({ page: page.page - 1 }))
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                    <ButtonIcon
                      btnTitle="Selanjutnya"
                      btnProps={{
                        disabled:
                          page.page ===
                          Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () => {
                          setPage((prev) => ({ page: prev.page + 1 }))
                          dispatch(setFetchOption({ page: page.page + 1 }))
                          scrollToTop()
                        },
                      }}
                      btnTitleProps={{ className: 'text-sm' }}
                    />
                  </div>
                </div>
              </CardFooter>
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Ports</h5>
                </div>

                <div className="d-flex justify-content-between align-items-center my-4">
                  <InputGroup style={{ borderColor: '#D0D5DD' }}>
                    <InputGroupText
                      style={{
                        backgroundColor: 'white',
                        borderRightColor: 'white',
                      }}
                    >
                      <Search color="#667085" size={16} />
                    </InputGroupText>
                    <Input
                      placeholder="Cari nama port..."
                      onChange={(e) => onHandleSearchChange(e.target.value)}
                      onKeyDown={handleKeyDown}
                      style={{ maxWidth: '250px' }}
                      value={search}
                    />
                  </InputGroup>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default PortsTable
