import change from 'assets/icons/change.svg'
import barcodeMenu from 'assets/icons/menu-barcode.svg'
import merge from 'assets/icons/merge.svg'

export const SECTIONSBCATRANSFER = [
  {
    title: 'Menggunakan ATM BCA',
    show: false,
    content: [
      'Masukkan kartu ATM dan PIN BCA kamu.',
      'Pilih menu “Transaksi Lainnya”.',
      'Pilih menu “Transfer”.',
      'Pilih “Ke Rek BCA”.',
      'Masukkan nomor rekening tujuan.',
      'Masukkan nominal transfer.',
      'Konfirmasi transfer.',
    ],
  },
  {
    title: 'Menggunakan BCA Mobile',
    show: false,
    content: [
      'Login ke akun BCA mobile kamu.',
      'Pilih menu “m-Transfer”.',
      'Pilih “Transfer Antar Rekening”',
      `Jika belum terdaftar, silakan daftarkan nomor rekeningnya terlebih dahulu.`,
      'Masukkan nominal transfer.',
      'Klik “Lanjut” dan selesaikan transfer.',
    ],
  },
  {
    title: 'Menggunakan myBCA',
    show: false,
    content: [
      'Login ke akun my BCA kamu.',
      'Pilih menu “Transaksi”.',
      'Pilih submenu “Transfer”.',
      'Masukkan nomor rekening tujuan.',
      'Masukkan nominal transfer.',
      'Klik “Lanjut”.',
      'Pastikan data transfer sudah benar, lalu pilih “Ya” untuk memverifikasi transaksi.',
      'Tunggu notifikasi transaksi berhasil.',
    ],
  },
  {
    title: 'Menggunakan KlikBCA',
    show: false,
    content: [
      'Kunjungi website ini KlikBCA.',
      'Login ke akun KlikBCA kamu.',
      'Pilih menu “Transfer Dana”.',
      'Pilih “Transfer ke Rekening BCA”.',
      'Pilih nomor rekening dari daftar, daftarkan jika belum.',
      'Masukkan nominal transfer.',
      'Aktifkan KeyBCA, masukkan pin dan tekan angka 2.',
      'Masukkan 8 digit dari Respon KeyBCA APPLI 2.',
      'Klik Lanjutkan.',
      'Tunggu informasi rekening tujuan.',
      'Nyalakan KeyBCA kembali.',
      'Klik angka 1.',
      'Tunggu 8 digit di kotak Respon KeyBCA APPLI 1.',
      'Pilih "Kirim".',
    ],
  },
]

export const menuStock = [
  {
    id: 1,
    title: 'Lihat Detail',
    icon: 'eye',
    screen: '/manage-stock/edit',
    type: 'edit',
    editType: 'general',
    modal: 'detail',
    iconType: 'icon',
    img: change,
  },
  {
    id: 2,
    title: 'Lihat ID stok',
    icon: 'menu',
    screen: '/manage-stock',
    type: 'detail',
    modal: 'qrCode',
    iconType: 'img',
    img: barcodeMenu,
  },
  {
    id: 3,
    title: 'Pisah stok',
    icon: 'copy',
    screen: '/manage-stock/split',
    modal: 'split',
    iconType: 'icon',
    icon: 'split',
  },
  {
    id: 4,
    title: 'Gabungkan stok',
    icon: 'merge',
    screen: '/manage-stock/merge',
    modal: 'merge',
    iconType: 'img',
    img: merge,
  },
  {
    id: 5,
    title: 'Ubah',
    icon: 'vegetables',
    screen: '/manage-stock/edit',
    type: 'edit',
    editType: 'general',
    modal: 'edit',
    iconType: 'img',
    img: change,
  },
  {
    id: 6,
    title: 'Hapus',
    icon: 'trash',
    screen: '/manage-stock/remove',
    type: 'remove',
    modal: 'confirmRemove',
    iconType: 'icon',
    icon: 'trash',
    iconColor: '#B42318',
  },
]

export const sortStock = [
  {
    id: 1,
    title: 'Nama stok (A-Z)',
    type: 'name',
    mode: 'asc',
  },
  {
    id: 2,
    title: 'Nama stok (Z-A)',
    type: 'name',
    mode: 'desc',
  },
  {
    id: 3,
    title: 'Stok terkecil',
    type: 'amount',
    mode: 'asc',
  },
  {
    id: 4,
    title: 'Stok terbesar',
    type: 'amount',
    mode: 'desc',
  },
]

export const itemUnitList = [
  {
    id: 1,
    title: 'Kg',
  },
  {
    id: 2,
    title: 'Pcs',
  },
  {
    id: 3,
    title: 'Buah',
  },
  {
    id: 4,
    title: 'Kardus',
  },
  {
    id: 5,
    title: 'Karung',
  },
  {
    id: 6,
    title: 'Ikat',
  },
  {
    id: 7,
    title: 'Peti',
  },
  {
    id: 8,
    title: 'Kotak',
  },
  {
    id: 9,
    title: 'Pak',
  },
  {
    id: 10,
    title: 'Lusin',
  },
  {
    id: 11,
    title: 'Liter',
  },
  {
    id: 12,
    title: 'Ekor',
  },
  {
    id: 13,
    title: 'Sak',
  },
]

export const WPP_OPTIONS = [
  { value: 'WPP-RI 571', label: 'WPP-RI 571' },
  { value: 'WPP-RI 572', label: 'WPP-RI 572' },
  { value: 'WPP-RI 573', label: 'WPP-RI 573' },
  { value: 'WPP-RI 711', label: 'WPP-RI 711' },
  { value: 'WPP-RI 712', label: 'WPP-RI 712' },
  { value: 'WPP-RI 713', label: 'WPP-RI 713' },
  { value: 'WPP-RI 714', label: 'WPP-RI 714' },
  { value: 'WPP-RI 715', label: 'WPP-RI 715' },
  { value: 'WPP-RI 716', label: 'WPP-RI 716' },
]

export const CERTIFICATION_OPTIONS = [
  { value: 'In FIP', label: 'In FIP' },
  { value: 'MSC Certified', label: 'MSC Certified' },
  { value: 'In Asssessment', label: 'In Asssessment' },
  { value: 'Fairtrade Certified', label: 'Fairtrade Certified' },
  { value: 'CPIB Certified', label: 'CPIB Certified' },
]

export const VESSEL_OTHER_STATUS_OPTIONS = [
  // { value: 'copySuratIzin', label: 'Copy surat izin' },
  { value: 'nameChangedStatus', label: 'Name change' },
  { value: 'radioStatus', label: 'Radio' },
  { value: 'vmsChange', label: 'VMS Change' },
  { value: 'sharkPolicy', label: 'Shark policy' },
  { value: 'trackerStatus', label: 'Tracker' },
  { value: 'iuuList', label: 'IUU list' },
  { value: 'flagStateChange', label: 'Flag state change' },
  { value: 'codeOfConduct', label: 'Code of conduct' },
  { value: 'eLogBook', label: 'E-logbook' },
  { value: 'sticker', label: 'Sticker' },
]
