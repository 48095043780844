import { createAppSlice } from 'app/createAppSlice'

const currentYear = new Date().getFullYear()
const startDateDefault = new Date(currentYear, 0, 1)
const endDateDefault = new Date(currentYear, 11, 31)

const initialState = {
  tag: {
    size: 10,
    page: 0,
  },
  details: null,
  isOpenSidebarFilter: false,
}

export const catchesListSlice = createAppSlice({
  name: 'catchesList',
  initialState,
  reducers: (create) => ({
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
    setToggleSidebarFilter: create.reducer((state, { payload }) => {
      state.isOpenSidebarFilter = !state.isOpenSidebarFilter
    }),
  }),
})

export const { setFetchOption, setToggleSidebarFilter } =
  catchesListSlice.actions

export default catchesListSlice.reducer
