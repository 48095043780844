import { useEffect, useState } from 'react'
import { ChevronDown, MapPin } from 'react-feather'
import { Button, Card, CardText, CardTitle, Input } from 'reactstrap'
import UploadImageCard from '../Card/UploadImageCard'
import CustomButton from 'components/Buttons/CustomButton'

export default function AddStockModal({
  isOpen,
  onClose,
  onChangeDataStock,
  dataStock,
  onShowItem,
  onCreateStock,
  loading,
  locationBlocked,
}) {
  const [isButtonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    if (dataStock?.amount && dataStock?.itemId && dataStock.unit) {
      setButtonDisabled(false)
    } else {
      setButtonDisabled(true)
    }
  }, [dataStock])
  return (
    <>
      <div className="regular-padding-horizontal regular-padding-top d-flex justify-content-between align-items-center">
        <CardTitle className="fw-semibold">Tambah Stok</CardTitle>
      </div>
      {locationBlocked ? (
        <div className="regular-padding-horizontal small-padding-vertical">
          <Card
            className="small-padding"
            style={{ backgroundColor: '#F3FEFF', borderColor: '#8ABBCC' }}
          >
            <div className="d-flex">
              <MapPin className="small-margin-right" color="#006386" />
              <CardText style={{ fontSize: 14 }}>
                Untuk mencatat lokasi dari stok yang ditambahkan, harap aktifkan
                layanan lokasi di perangkat anda.
              </CardText>
            </div>
          </Card>
        </div>
      ) : null}

      <div className="regular-padding">
        <div>
          <CardText className="no-padding fw-semibold">
            Komoditas
            <span style={{ color: '#b42218' }}>*</span>
          </CardText>
          <div className="bordered mt-2 d-flex justify-content-between align-items-center">
            <Input
              style={{ borderColor: 'white', caretColor: 'transparent' }}
              className="border-input button"
              placeholder="Pilih komoditas"
              onClick={() => {
                onShowItem('item', true)
              }}
              value={dataStock?.nameItem}
            />
            <ChevronDown color="#667085" style={{ paddingRight: 10 }} />
          </div>
        </div>
        {dataStock?.nameItem ? (
          <div className="mt-4">
            <UploadImageCard
              onChangeDataStock={onChangeDataStock}
              uploadedImage={dataStock?.linkFoto}
            />
          </div>
        ) : null}
        <div className="d-flex justify-content-between">
          <div className="mt-4" style={{ width: '49%' }}>
            <CardText className="no-padding fw-semibold">
              Jumlah<span style={{ color: '#b42218' }}>*</span>
            </CardText>
            <div className="bordered mt-2 d-flex justify-content-between align-items-center">
              <Input
                style={{ borderColor: 'white' }}
                placeholder="Masukkan jumlah"
                onChange={(text) =>
                  onChangeDataStock('amount', text.target.value)
                }
              />
            </div>
          </div>
          <div className="mt-4" style={{ width: '49%' }}>
            <CardText className="no-padding fw-semibold">
              Satuan<span style={{ color: '#b42218' }}>*</span>
            </CardText>
            <div className="bordered mt-2 d-flex justify-content-between align-items-center">
              <Input
                style={{ borderColor: 'white', caretColor: 'transparent' }}
                className="border-input button"
                placeholder="Kg"
                value={dataStock?.unit}
                onClick={() => {
                  onShowItem('itemUnit', true)
                }}
              />
              <ChevronDown color="#667085" style={{ paddingRight: 10 }} />
            </div>
          </div>
        </div>

        <div className="mt-4">
          <CardText className="no-padding fw-semibold">
            Catatan tambahan (opsional)
          </CardText>
          <div className="d-flex align-items-center mt-2">
            <div className="bordered mt-2" style={{ width: '100%' }}>
              <Input
                style={{ borderColor: 'white', height: 200 }}
                className="border-input"
                type="textarea"
                placeholder="Cth: Kopi ini dirawat dengan baik oleh anggota berkualitas"
                onChange={(text) =>
                  onChangeDataStock('description', text.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="mt-4" style={{ textAlign: 'right' }}>
          <CustomButton
            onClick={onCreateStock}
            disabled={isButtonDisabled || loading}
            style={{
              backgroundColor: '#006386',
              fontSize: 14,
            }}
            className="fw-semibold"
          >
            Simpan
          </CustomButton>
        </div>
      </div>
    </>
  )
}
