import { useEffect, useState } from 'react'
import { CheckCircle } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Card, CardBody } from 'reactstrap'
import { getVesselsDetail } from '../list-vessels/vesselsListAction'
import { useFetchVesselsDetailQuery } from '../list-vessels/vesselsListApiSlice'
import ModalChangeProfil from './ModalChangeProfil'
import { toggleBannerDetail, toggleModalChangeProfile } from './detailSlice'

import BreadCrumbs from 'components/breadcrumbs'
import { VESSEL_OTHER_STATUS_OPTIONS } from 'constants'
import IconAvatar from '../../../../assets/icons/icon-avatar.png'
import IconCheckStatus from '../../../../assets/icons/icon-check.svg'
import IconXStatus from '../../../../assets/icons/icon-x.svg'
import VesselNotFound from '../../../../assets/icons/vessel-not-found.png'
import ModalPemetaan from '../list-vessels/ModalPemetaan'
import { toggleModalPemetaan } from '../list-vessels/vesselsListSlice'

const DetailVessel = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const vesselId = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )
  // TEMP TILL BE ADJUST
  const { data } = useFetchVesselsDetailQuery({ id: vesselId })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isClick, setIsClick] = useState(false)
  const [imageVessel, setImageVessel] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const { isOpenBanner } = useSelector((s) => s.farmersDetail)
  const { isOpenModalPemetaan } = useSelector((s) => s.vesselsList)

  const { token } = useSelector((s) => s.auth)

  // UNUSED FOR AT THE MOMENT
  // const getSrc = (link = '') => {
  //   setIsLoading(true)
  //   const imageUrl = link
  //   const bearerToken = token

  //   axios
  //     .get(imageUrl, {
  //       headers: {
  //         Authorization: `Bearer ${bearerToken}`,
  //       },
  //       responseType: 'blob',
  //     })
  //     .then((response) => {
  //       const imgUrl = URL.createObjectURL(response.data)
  //       if (imgUrl) {
  //         setImageVessel(imageUrl)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  //     .finally(() => {
  //       setIsLoading(false)
  //     })
  // }

  useEffect(() => {
    if (vesselId) {
      dispatch(getVesselsDetail())
    }

    // if (data?.linkImage) {
    //   getSrc(data?.linkImage)
    // }
  }, [vesselId, data])

  const certStatusRaw = data?.certStatus
  const cleanedCertStatus =
    certStatusRaw && certStatusRaw.replace(/[\[\]']/g, '').trim().length > 0
      ? certStatusRaw
          .replace(/[\[\]']/g, '')
          .split(',')
          .map((status) => status.trim())
      : []

  return (
    <>
      <div className="w-100 px-3 gap-10">
        <BreadCrumbs
          breadCrumbTitle="Master Kapal"
          breadCrumbParent2="Manajemen Kapal"
          breadCrumbActive="Lihat Detail"
        />

        <ModalPemetaan row={data} />

        <ModalChangeProfil row={data || {}} />

        <div>
          <Alert
            isOpen={isOpenBanner}
            toggle={() => dispatch(toggleBannerDetail())}
            color="success"
            className="d-flex align-items-center my-4 w-100"
          >
            <span>
              <CheckCircle size={17} /> Data diri berhasil diubah.
            </span>
          </Alert>
        </div>

        {/* <div className="mt-3">
          <h5>Detail Kapal {data?.vesselName}</h5>
        </div> */}

        <div
          className="col-12"
          style={{
            display: 'flex',
            gap: '16px',
          }}
        >
          {/* Left Column */}
          <div
            style={{ flexBasis: '50%', flexGrow: '1', marginBottom: '14px' }}
          >
            <Card>
              <CardBody className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Data Kapal</h5>
                  <h6
                    onClick={() => dispatch(toggleModalChangeProfile())}
                    style={{ cursor: 'pointer' }}
                    className="text-primary"
                  >
                    Ubah
                  </h6>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '50px 50px 20px 50px',
                  }}
                >
                  <img
                    src={
                      data?.linkImage !== '' ? data?.linkImage : VesselNotFound
                    }
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: '50%',
                    }}
                  />
                  <h5 style={{ marginTop: 10 }}>{data?.vesselName}</h5>
                </div>

                <div className="border border-b" />

                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">
                      Vessel Registration
                    </span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.licenseNumber ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">UVI</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.ap2hiUVI ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">Owner Name</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.ownerName ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">Vessel Flag</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.vesselFlag ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">Vessel GT</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.vesselGT ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">Gear Type</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.vesselGear ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">Vessel Type</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.vesselType ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">MOTR Mark</span>
                  </div>
                  <div className="col-6">
                    <p className="text-sm text-muted mb-0">
                      {data?.motrMark?.trim() ? data?.motrMark : '-'}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="text-sm fw-semibold">
                      Certification Status
                    </span>
                  </div>
                  <div className="col-6">
                    {cleanedCertStatus.length > 0
                      ? cleanedCertStatus.map((status, index) => (
                          <p key={index} className="text-sm text-muted mb-1">
                            <img
                              src={IconCheckStatus}
                              loading="lazy"
                              alt="icon"
                            />{' '}
                            {status}
                          </p>
                        ))
                      : '-'}
                  </div>
                </div>
              </CardBody>
            </Card>

            <div
              className="mt-2"
              style={{
                gap: '16px',
              }}
            >
              <div className="col-12">
                <div
                  style={{
                    flexBasis: '50%',
                    flexGrow: '1',
                    marginBottom: '14px',
                  }}
                >
                  <Card>
                    <CardBody className="d-flex flex-column gap-2">
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <h5>Nelayan</h5>
                          <h6
                            onClick={() => dispatch(toggleModalPemetaan())}
                            style={{ cursor: 'pointer' }}
                            className="text-primary"
                          >
                            Ubah
                          </h6>
                        </div>
                        {data?.uservessels?.length > 0 ? (
                          data?.uservessels?.map((item, index) => (
                            <div
                              key={index}
                              className="text-muted my-2 d-flex align-items-center"
                              style={{
                                justifyContent: 'flex-start',
                              }}
                            >
                              <img
                                src={IconAvatar}
                                loading="lazy"
                                alt="Avatar"
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 10,
                                }}
                              />
                              <p className='text-sm' style={{ margin: 0 }}>
                                {item?.verifiedprofile?.name ?? '-'}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="text-muted my-2">{'-'}</p>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div
            style={{ flexBasis: '50%', flexGrow: '1', marginBottom: '14px' }}
          >
            <Card>
              <CardBody className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Pemetaan Kapal</h5>
                </div>
                <div>
                  <span className="text-sm fw-semibold">Companies</span>
                  {data?.companiesList?.length
                    ? data?.companiesList?.map((item, index) => (
                        <p key={index} className="text-sm text-muted m-0">
                          - {item?.companiesData?.name}
                        </p>
                      ))
                    : '-'}
                </div>
                <div>
                  <span className="text-sm fw-semibold">Fisheries</span>
                  {data?.fisheriesList?.length
                    ? data?.fisheriesList?.map((item, index) => (
                        <p key={index} className="text-sm text-muted m-0">
                          - {item?.fisheriesData?.name}
                        </p>
                      ))
                    : '-'}
                </div>
                <div>
                  <span className="text-sm fw-semibold">Ports</span>
                  {data?.portsList?.length
                    ? data?.portsList?.map((item, index) => (
                        <p key={index} className="text-sm text-muted m-0">
                          - {item?.portsData?.name}
                        </p>
                      ))
                    : '-'}
                </div>
                <div>
                  <span className="text-sm fw-semibold">WPP-RI</span>
                  <p className="text-sm text-muted m-0">
                    {data?.wppRIList ?? '-'}
                  </p>
                </div>
                <div>
                  <span className="text-sm fw-semibold">District</span>
                  <p className="text-sm text-muted m-0">
                    {data?.district ?? '-'}
                  </p>
                </div>
              </CardBody>
            </Card>

            <div
              className="mt-2"
              style={
                {
                  // gap: '16px',
                }
              }
            >
              <div className="col-12">
                <div
                  style={{
                    flexBasis: '50%',
                    flexGrow: '1',
                    marginBottom: '14px',
                  }}
                >
                  <Card>
                    <CardBody className="d-flex flex-column gap-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Other Status</h5>
                      </div>

                      <div className="container">
                        {VESSEL_OTHER_STATUS_OPTIONS.map((option, index) => (
                          <div className="row" key={index}>
                            <div className="col-6 p-0">
                              <span className="text-sm fw-semibold">
                                {option.label}
                              </span>
                            </div>
                            <div className="col-6">
                              <p className="text-sm text-muted mb-0">
                                {data?.[option.value] ? (
                                  <img
                                    src={IconCheckStatus}
                                    loading="lazy"
                                    alt="icon"
                                  />
                                ) : (
                                  <img
                                    src={IconXStatus}
                                    loading="lazy"
                                    alt="icon"
                                  />
                                )}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailVessel
