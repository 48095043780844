import Sawah from 'assets/images/photo-sawah.jpeg'
import { ArrowLeft } from 'react-feather'
import { useNavigate, useRouteError } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import CustomButton from './Buttons/CustomButton'
import Page404 from './Page404'

const PageForbidden = () => {
  let error = useRouteError()
  let nav = useNavigate()

  if (error?.status === 404) {
    return <Page404 />
  }

  return (
    <Container>
      <Row>
        <Col className="m-auto order-1 order-sm-1 order-md-0 " sm="12" md="6">
          <div className="p-2 custom-class">
            <div>
              <h1 className="mb-3" style={{ color: 'rgba(16, 24, 40, 1)' }}>
                Anda tidak memiliki akses ke halaman ini.
              </h1>
              <h5 className="mb-3" style={{ color: 'rgba(71, 84, 103, 1)' }}>
                Silakan kontak admin anda, atau gunakan akun yang memiliki
                akses.
              </h5>
            </div>
            <div>
              <CustomButton
                onClick={() => nav(-1)}
                color="secondary"
                className="btn-sm-block mb-2 me-1"
                outline
              >
                {/* . If the user click “Kembali” then direct to previous page that users have visited */}
                <ArrowLeft size={20} className="me-1" />
                Kembali
              </CustomButton>
              <CustomButton
                tag="a"
                href="/"
                color="primary"
                className="btn-sm-block mb-2 ms-1"
              >
                {/* If the user click “Beranda” then direct to Dashboard Page */}
                Beranda
              </CustomButton>
            </div>
          </div>
        </Col>
        <Col
          className="d-flex m-auto order-0 order-sm-0 order-md-1"
          sm="12"
          md="6"
        >
          <img src={Sawah} alt="logo" className="img-page-forbidden" />
        </Col>
      </Row>
    </Container>
  )
}
export default PageForbidden
