import { ButtonIcon } from 'components/Buttons'
import CustomButton from 'components/Buttons/CustomButton'
import { TableComponent as DataTable } from 'components/TableComponent'
import { useEffect, useState } from 'react'
import {
  ArrowRightCircle,
  CheckCircle,
  ChevronDown,
  Edit2,
  MoreVertical,
  Trash2,
} from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap'
import { clearState } from '../forms/formSlice'
import { useFetchFarmersQuery } from './farmersListApiSlice'
import {
  setBannerMsg,
  setFetchOption,
  toggleBanner,
  toggleModal,
  toggleModalDelete,
} from './farmersListSlice'
import ModalChangeName from './ModalChangeName'
import BreadCrumbs from 'components/breadcrumbs'
import ModalConfirmDelete from './ModalConfirmDelete'
import ModalChangeRole from '../staffList/ModalChangeRole'
import { toggleModal as toggleModalChangeRole } from '../staffList/staffListSlice'
import emptyAnggota from 'assets/icons/empty-anggota.svg'
import { Can } from 'context/Acl'
import { BUSINESS_FARMERS, MANAGE } from 'utils/subjectActions'

const FarmersTable = ({ groupList }) => {
  const { tag, isOpenBanner, bannerMsg } = useSelector((s) => s.farmersList)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const dataState = location.state

  let [page, setPage] = useState({ page: tag.page })
  const [selectedRow, setSelectedRow] = useState(null)
  const code = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )

  const { data, isLoading, refetch } = useFetchFarmersQuery(
    {
      groupCode: code,
      page: page?.page,
      status: 'JOIN',
    },
    { skip: !code },
  )

  const { data: dataPending, isLoading: loadingPending } = useFetchFarmersQuery(
    {
      groupCode: code,
      page: page?.page,
      status: 'PENDING',
    },
    { skip: !code },
  )

  const columns = [
    {
      name: 'Nama',
      cell: (row) => {
        return <span>{row?.name}</span>
      },
    },
    {
      name: 'Nomor Telepon',
      cell: (row) => {
        return <span>{row?.phoneNumber}</span>
      },
    },
    {
      name: 'Status',
      cell: (row) => {
        return (
          <Badge
            className={
              row?.status === 'VERIFIED' ? 'badge-verify' : 'badge-danger'
            }
          >
            {row?.status === 'VERIFIED'
              ? 'Terverifikasi'
              : 'Belum Terverfikasi'}
          </Badge>
        )
      },
    },
    {
      name: <span>Peran</span>,
      cell: (row) => {
        return <span>{row?.roleName}</span>
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <div className="d-flex gap-2">
            <Can I={MANAGE} this={BUSINESS_FARMERS}>
              <span
                style={{ cursor: 'pointer' }}
                className="fw-semibold text-center"
                onClick={() => {
                  dispatch(toggleModalChangeRole())
                  setSelectedRow(row)
                }}
              >
                Ubah peran
              </span>
            </Can>
            <span
              style={{ cursor: 'pointer' }}
              className="fw-semibold text-center"
              onClick={() => navigate(`/farmers/${row?.userId}`)}
            >
              Lihat Detail
            </span>
          </div>
        )
      },
    },
  ]

  const handleFinishedChangeRole = () => {
    dispatch(setBannerMsg('Peran berhasil diubah.'))
    dispatch(toggleBanner())
    refetch()
  }

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {},
    },
    rows: {
      style: {},
    },
  }

  useEffect(() => {
    dispatch(clearState())
  }, [])

  return (
    <div className="w-100 px-3">
      <BreadCrumbs
        breadCrumbActive={
          data?.data?.groupName ??
          data?.data?.items[0]?.groupname ??
          dataState?.name
        }
        breadCrumbParent={'Daftar Anggota'}
        breadCrumbParent2={'Grup'}
      />
      <ModalChangeName />
      <ModalConfirmDelete />
      <ModalChangeRole
        isSubGroup
        row={selectedRow}
        handleFinishedChangeRole={handleFinishedChangeRole}
      />
      <Card>
        <CardBody>
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center mb-1">
              {isLoading ? (
                <Spinner color="primary" />
              ) : (
                <h5 className="fw-semibold">
                  {data?.data?.groupName ??
                    data?.data?.items[0]?.groupname ??
                    dataState?.name}{' '}
                  <Can I={MANAGE} this={BUSINESS_FARMERS}>
                    <UncontrolledDropdown group>
                      <DropdownToggle className="bg-transparent border-0">
                        <MoreVertical size={16} className="text-secondary" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => dispatch(toggleModal())}>
                          <Edit2 size={16} className="me-2" /> Ubah Nama Grup
                        </DropdownItem>
                        <DropdownItem
                          className="text-danger"
                          onClick={() => dispatch(toggleModalDelete())}
                        >
                          <Trash2 size={16} className="me-2" /> Hapus Grup
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Can>
                </h5>
              )}

              {data?.data?.totalItems < 1 ? (
                <></>
              ) : (
                <CustomButton
                  onClick={() =>
                    navigate('/farmers/check', { state: dataState })
                  }
                  size="sm"
                  color="primary"
                >
                  + Tambah Anggota
                </CustomButton>
              )}
            </div>
            <div>
              <Alert
                isOpen={isOpenBanner}
                toggle={() => dispatch(toggleBanner())}
                color="success"
                className="d-flex align-items-center mt-4 w-100"
              >
                <span>
                  <CheckCircle size={17} /> {bannerMsg}
                </span>
              </Alert>
            </div>
            {data?.data?.totalItems < 1 && dataPending?.data?.totalItems < 1 ? (
              <></>
            ) : (
              <Row className="d-flex flex-row justify-content-start mb-3 align-items-stretch">
                <Col lg="2" md="6" sm="12" className="d-flex">
                  <Card body className="w-100">
                    <CardText tag="small" className="fw-semibold">
                      Jumlah Anggota
                    </CardText>
                    <CardText tag="h3" className="text-primary mt-2">
                      {isLoading ? (
                        <Spinner color="primary" />
                      ) : (
                        data?.data?.totalItems
                      )}
                    </CardText>
                  </Card>
                </Col>
                <Col lg="2" md="6" sm="12" className="d-flex">
                  <Card
                    onClick={() => navigate(`/farmers/pending/${code}`)}
                    body
                    style={{ cursor: 'pointer' }}
                    className="w-100"
                  >
                    <CardText tag="small" className="fw-semibold">
                      Menunggu Konfirmasi
                    </CardText>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <div className="text-primary h3">
                        {loadingPending ? (
                          <Spinner color="primary" />
                        ) : (
                          dataPending?.data?.totalItems
                        )}
                      </div>
                      <div>
                        <ArrowRightCircle size={20} />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
          {data?.data?.totalItems > 0 ? (
            <DataTable
              customStyles={customStyles}
              columns={columns}
              data={data?.data?.items}
              progressPending={isLoading}
              responsive
              persistTableHead
              paginationTotalRows={data?.data?.totalItems}
              progressComponent={<Spinner />}
              sortIcon={<ChevronDown />}
              sortServer
              className="border p-0 border-1 rounded-top"
              noDataComponent={
                <p className="my-3 fw-semibold">
                  Belum ada pengguna yang didaftarkan
                </p>
              }
              pagination
              paginationServer
              paginationComponent={(props) => {
                return (
                  <CardFooter
                    className="border border-top-0"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="text-sm">
                        {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                      </div>
                      <div className="d-flex flex-centered gap-1">
                        <ButtonIcon
                          btnTitle="Sebelumnya"
                          btnProps={{
                            disabled: page.page === 0,
                            size: 'sm',
                            color: 'secondary',
                            onClick: () => {
                              setPage((prev) => ({ page: prev.page - 1 }))
                              dispatch(
                                setFetchOption({
                                  page: page.page - 1,
                                }),
                              )
                            },
                          }}
                          btnTitleProps={{
                            className: 'text-sm',
                          }}
                        />
                        <ButtonIcon
                          btnTitle="Selanjutnya"
                          btnProps={{
                            disabled:
                              page.page ===
                              Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                            size: 'sm',
                            color: 'secondary',
                            onClick: () => {
                              setPage((prev) => ({ page: prev.page + 1 }))
                              dispatch(
                                setFetchOption({
                                  page: page.page + 1,
                                }),
                              )
                            },
                          }}
                          btnTitleProps={{
                            className: 'text-sm',
                          }}
                        />
                      </div>
                    </div>
                  </CardFooter>
                )
              }}
            />
          ) : (
            <Card className="bg-light rounded border-0">
              <CardBody>
                <div className="d-flex flex-column align-items-center gap-4">
                  <img alt="Groups" src={emptyAnggota} />
                  <span>Belum ada anggota di grup ini.</span>
                  <CustomButton
                    onClick={() =>
                      navigate('/farmers/check', { state: dataState })
                    }
                    color="primary"
                  >
                    + Tambah Anggota
                  </CustomButton>
                </div>
              </CardBody>
            </Card>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export default FarmersTable
