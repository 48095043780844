import { createApiSlice } from 'app/createApiSlice'
import {
  API_COMPANIES,
  API_FISHERIES,
  API_GEARS,
  API_SPECIES_LIST,
} from 'utils/apiList'

export const formFisheriesApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'formFisheries/api',
  endpoints(builder) {
    return {
      creationFisheries: builder.mutation({
        query(payload) {
          return {
            url: API_FISHERIES,
            method: 'POST',
            body: payload,
          }
        },
      }),
      updateFisheries: builder.mutation({
        query({ payload, id }) {
          return {
            url: `${API_FISHERIES}/${id}`,
            method: 'POST',
            body: payload,
          }
        },
      }),
      fetchFormFisheries: builder.query({
        query({ page, size = 10 }) {
          return {
            url: API_FISHERIES,
            params: {
              page,
              size,
            },
          }
        },
      }),
      // SPECIES LIST
      fetchAllSpecies: builder.query({
        query() {
          return {
            url: API_SPECIES_LIST,
          }
        },
      }),
      // GEARS LIST
      fetchAllGears: builder.query({
        query() {
          return {
            url: API_GEARS,
          }
        },
      }),
      // COMPANIES LIST
      fetchAllCompanies: builder.query({
        query({ page, size = 10, searchvalue }) {
          return {
            url: API_COMPANIES,
            params: {
              page,
              size,
              searchvalue,
            },
          }
        },
      }),
    }
  },
})

export const {
  useCreationFisheriesMutation,
  useUpdateFisheriesMutation,
  useCreationFisheriesUsersMutation,
  useFetchFormFisheriesQuery,
  useLazyFetchFormFisheriesQuery,
  useFetchAllSpeciesQuery,
  useFetchAllGearsQuery,
  useFetchAllCompaniesQuery,
} = formFisheriesApiSlice
