import IPhoto from 'assets/icons/Pak-Tani.png'
import { ButtonIcon } from 'components/Buttons'
import { TableComponent as DataTable } from 'components/TableComponent'
import moment from 'moment-timezone'
import { useState } from 'react'
import { CheckCircle } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Badge, Card, CardFooter, CardText } from 'reactstrap'
import SidebarTalanginInvoice from './SidebarTalanginInvoice'
import {
  setSelectedRow,
  toggleBanner,
  toggleSidebarTalangin,
} from './talanginInvoiceSlice'
// import ICFilter from 'assets/icons/filter-icon.svg'
// import ModalFilter from './components/ModalFilter'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import useGetTrxTalanginQuery from 'hooks/useGetTrxTalanginQuery'
import { Navigate } from 'react-router-dom'
import { URL_406 } from 'utils/pathUrl'
import { setPage as setPageRedux } from './talanginSlice'

const TalanginInvoice = ({ action, subject }) => {
  const abilityCreate = useAbilityAccess({ action, subject })

  const intl = useIntl()
  const dispatch = useDispatch()
  const talanginTable = useSelector((s) => s.talanginTable)
  let [page, setPage] = useState({ page: talanginTable?.page })
  // let [searchData, setSearchData] = useState(talanginTable?.search)

  const dataTalangin = useGetTrxTalanginQuery({
    ...talanginTable,
    ...page,
  })
  const {
    isOpenBanner,
    isOpenSidebar,
    msgBanner,
    selectedRow,
    justRolledOver,
  } = useSelector((e) => e.talanginInvoice)

  const toggleBanners = () => dispatch(toggleBanner())

  const toggleSidebar = () => dispatch(toggleSidebarTalangin())

  // handle Open Filter
  // const [isOpenFilter, setisOpenFilter] = useState(false)
  // const openFilter = () => {
  //   setisOpenFilter(!isOpenFilter)
  // }

  const conditionalRowStyles = [
    {
      when: (row) => justRolledOver === row?.id,
      style: {
        backgroundColor: '#F3FEFF',
      },
    },
  ]

  const columns = [
    {
      name: 'Penjual',
      cell: (row) => (
        <div className="flex-centered gap-2">
          <img
            src={row?.sellerAvatar ?? IPhoto}
            alt="pak tani"
            width={20}
            height={20}
            className="rounded-circle"
          />
          <span>{row?.sellerName}</span>
        </div>
      ),
      // sortable: true,
    },
    {
      name: 'Jatuh Tempo',
      cell: (row) => <span>{moment(row?.dueDate).format('LL')}</span>,
    },
    {
      name: 'Komoditas',
      cell: (row) => {
        return (
          <div className="d-flex flex-column">
            <span className="flex-row">{`${row?.escrowpayment?.order?.orderitem?.[0]?.item?.nameItem} • ${row?.escrowpayment?.order?.orderitem?.[0]?.amount} ${row?.escrowpayment?.order?.orderitem?.[0]?.unit}`}</span>
            <span className="fw-light">
              {row?.escrowpayment?.order?.orderitem?.length > 1
                ? `dan ${row?.escrowpayment?.order?.orderitem?.length - 1} komoditas lainnya`
                : ''}
            </span>
          </div>
        )
      },
    },
    {
      name: 'Total Transaksi',
      cell: (row) => (
        <span>
          Rp
          {intl.formatNumber(
            row?.escrowpayment?.escrowcreditv2?.useFreeTx
              ? row?.escrowpayment?.escrowcreditv2?.totalVAT +
                  (row?.escrowpayment?.amount +
                    (row?.previousUserPinjamanId
                      ? row?.escrowpayment?.escrowcreditv2?.margin2
                      : row?.escrowpayment?.escrowcreditv2?.margin) ||
                    row?.amountPlusInterest) +
                  (row?.escrowpayment?.escrowcreditv2?.feeTx -
                    row?.escrowpayment?.escrowcreditv2?.feeTxVAT) +
                  (row?.partialpaymentData?.fine || row?.fineData?.totalFine)
              : row?.escrowpayment?.escrowcreditv2?.feeTxVAT +
                  row?.escrowpayment?.escrowcreditv2?.totalVAT +
                  (row?.escrowpayment?.amount +
                    (row?.previousUserPinjamanId
                      ? row?.escrowpayment?.escrowcreditv2?.margin2
                      : row?.escrowpayment?.escrowcreditv2?.margin) ||
                    row?.amountPlusInterest) +
                  (row?.escrowpayment?.escrowcreditv2?.feeTx -
                    row?.escrowpayment?.escrowcreditv2?.feeTxVAT) +
                  (row?.partialpaymentData?.fine || row?.fineData?.totalFine),
            {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            },
          )}
        </span>
      ),
    },
    {
      name: 'Dibayar oleh',
      cell: (row) =>
        row?.pmbactions ? (
          <div className="flex-centered gap-2">
            <img
              src={row?.paidOffUserAvatar ?? IPhoto}
              alt="pak tani"
              width={20}
              height={20}
              className="rounded-circle"
            />
            <span>{row?.pmbactions?.verifiedprofile?.name ?? '-'}</span>
          </div>
        ) : (
          <>
            <div className="">-</div>
          </>
        ),
    },
    {
      name: 'Status',
      cell: (row) => {
        let tdd = moment(row.lateDueDate ?? row.dueDate).tz('Asia/Jakarta')
        let diffInDays = moment().tz('Asia/Jakarta').diff(tdd, 'days')

        // Terlambat x hari:
        if (
          (row.status?.toLowerCase() === 'pending' ||
            row.status?.toLowerCase() === 'disbursed') &&
          diffInDays > 0
        ) {
          return (
            <Badge size="sm" disabled className="badge-danger">
              Terlambat {diffInDays + 1} hari
            </Badge>
          )
        }

        // Lunas
        if (
          row.status?.toLowerCase() === 'rollover' ||
          row.status?.toLowerCase() === 'paidoff'
        ) {
          return (
            <Badge size="sm" disabled className="badge-verify">
              Lunas
            </Badge>
          )
        }

        // Jatuh Tempo Hari Ini
        if (
          (row.status?.toLowerCase() === 'pending' ||
            row.status?.toLowerCase() === 'disbursed') &&
          diffInDays === 0
        ) {
          return (
            <Badge size="sm" className="badge-danger">
              Jatuh tempo hari ini
            </Badge>
          )
        }

        // Diperpanjang (x) hari
        if (
          (row.status?.toLowerCase() === 'pending' ||
            row.status?.toLowerCase() === 'disbursed') &&
          row.previousUserPinjamanId &&
          diffInDays < 0
        ) {
          return (
            <Badge size="sm" className="badge-process">
              Diperpanjang {row?.escrowpayment?.escrowcreditv2?.loanDays2} hari
            </Badge>
          )
        }

        // Belum Lunas
        if (
          (row.status?.toLowerCase() === 'pending' ||
            row.status?.toLowerCase() === 'disbursed') &&
          !row.previousUserPinjamanId &&
          diffInDays < 0
        ) {
          return (
            <Badge size="sm" className="badge-warning">
              Belum Lunas
            </Badge>
          )
        }
      },
    },
    {
      name: '',
      selector: (row) => (
        <div
          onClick={() => {
            toggleSidebar()
            dispatch(setSelectedRow(row))
          }}
          style={{ cursor: 'pointer' }}
        >
          Lihat Detail
        </div>
      ),
      center: true,
    },
  ]

  if (!abilityCreate) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <>
      <div className="w-100 px-3">
        <SidebarTalanginInvoice
          isOpen={isOpenSidebar}
          toggle={toggleSidebar}
          row={selectedRow}
        />
        <Card body className="mt-4">
          <CardText tag="h5" className="py-2 fw-semibold">
            Tagihan Talangin
          </CardText>
          {isOpenBanner && (
            <Alert color="success" isOpen={isOpenBanner} toggle={toggleBanners}>
              <CheckCircle className="text-success me-2" size={16} />
              <span>{msgBanner}</span>
            </Alert>
          )}
          <DataTable
            columns={columns}
            data={dataTalangin?.data?.items}
            // selectableRows
            // onSelectedRowsChange={handleChange}
            progressPending={
              dataTalangin?.isLoading || dataTalangin?.isFetching
            }
            paginationTotalRows={dataTalangin?.data?.totalItems}
            conditionalRowStyles={conditionalRowStyles}
            pagination
            paginationServer
            paginationComponent={(props) => {
              return (
                <CardFooter
                  className="border border-top-0"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-sm">
                      {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                    </div>
                    <div className="flex-centered gap-1">
                      <ButtonIcon
                        btnTitle="Sebelumnya"
                        btnProps={{
                          disabled: page.page === 0,
                          size: 'sm',
                          color: 'secondary',
                          onClick: () => {
                            setPage((prev) => ({ page: prev.page - 1 }))
                            dispatch(setPageRedux({ page: page.page - 1 }))
                          },
                        }}
                        btnTitleProps={{
                          className: 'text-sm',
                        }}
                      />
                      <ButtonIcon
                        btnTitle="Selanjutnya"
                        btnProps={{
                          disabled:
                            page.page ===
                            Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                          size: 'sm',
                          color: 'secondary',
                          onClick: () => {
                            setPage((prev) => ({ page: prev.page + 1 }))
                            dispatch(setPageRedux({ page: page.page + 1 }))
                          },
                        }}
                        btnTitleProps={{
                          className: 'text-sm',
                        }}
                      />
                    </div>
                  </div>
                </CardFooter>
              )
            }}
            // subHeader={true}
            // subHeaderAlign={'center'}
            // subHeaderComponent={
            //   <Row className="w-100">
            //     <Col md="9">
            //       {/* <div className="d-flex align-items-center mb-1">
            //           <ButtonIcon
            //             btnTitle="Filter"
            //             icon={
            //               <img
            //                 width="20px"
            //                 height="15px"
            //                 src={ICFilter}
            //                 alt="icon"
            //                 className="ml-2"
            //               />
            //             }
            //             btnProps={{
            //               className: 'w-auto d-flex me-2 flex-centered ',
            //               size: 'sm',
            //               color: 'secondary',
            //               onClick: openFilter,
            //             }}
            //             btnTitleProps={{
            //               className: 'fw-medium me-2',
            //             }}
            //           >
            //             {talanginTable?.status && talanginTable?.sortBy ? (
            //               <span
            //                 style={{
            //                   height: 20,
            //                   width: 20,
            //                   textDecoration: 'none',
            //                 }}
            //                 className="rounded-circle badge-blues flex-centered text-xs text-center"
            //               >
            //                 2
            //               </span>
            //             ) : talanginTable?.status ||
            //               talanginTable?.sortDir ||
            //               talanginTable?.sortBy ? (
            //               <span
            //                 style={{
            //                   height: 20,
            //                   width: 20,
            //                   textDecoration: 'none',
            //                 }}
            //                 className="rounded-circle badge-blues flex-centered text-xs text-center"
            //               >
            //                 1
            //               </span>
            //             ) : (
            //               ''
            //             )}
            //           </ButtonIcon>
            //         </div> */}
            //     </Col>
            //     <Col>
            //       {/* <InputGroup className="input-group-merge" size="sm">
            //           <InputGroupText className="bg-transparent border-right-0">
            //             <Search size={18} />
            //           </InputGroupText>
            //           <Input
            //             className="d-flex align-items-center float-right border-left-0"
            //             type="text"
            //             bsSize="sm"
            //             id="search-input"
            //             placeholder="Cari"
            //             onChange={(e) => {
            //               setSearchData(e.target.value)
            //               dispatch(
            //                 setFilter({
            //                   search: e.target.value,
            //                 }),
            //               )
            //             }}
            //             value={searchData}
            //           />
            //         </InputGroup> */}
            //     </Col>
            //   </Row>
            // }
          />
        </Card>
      </div>
      {/* <ModalFilter handleClick={openFilter} modal={isOpenFilter} /> */}
    </>
  )
}

export default TalanginInvoice
