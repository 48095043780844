import AmountInput from 'components/AmountInput'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  Container,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
} from 'reactstrap'
import { pendingTopup } from './topupAction'
import { usePostOnePaymentMutation } from './topupApiSlice'
import CustomButton from 'components/Buttons/CustomButton'

const TopupOnePayment = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [amount, setAmount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [postOnePayment, result] = usePostOnePaymentMutation()

  const handleTotalAmount = (amount) => {
    setAmount(
      Number(
        amount?.replace?.(/\./g, '')?.replace?.(/\,/g, '.')?.slice?.(0, 15),
      ),
    )
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const body = {
        amount: amount,
        purpose: 'TOPUP',
        redirectToUrl: `${window.location.origin}/`,
      }
      const res = await postOnePayment(body)
      if (res?.error) {
        setErrMsg(res?.error?.data?.message ?? res?.error.message)
      } else if (res?.data?.link) {
        navigate(-1, { replace: true })
        window.open(res?.data?.link)
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.response?.data?.message ?? error.message)
    } finally {
      setLoading(false)
    }
  }

  const handlePending = async () => {
    try {
      const res = await dispatch(pendingTopup())
      if (res?.payload?.link) {
        navigate(-1, { replace: true })
        window.open(res?.payload?.link)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handlePending()
  }, [])

  useEffect(() => {
    setErrMsg('')
  }, [amount])

  return (
    <Container className="custom-container pb-5 mt-4">
      <Card body className="w-100">
        <p className="fw-600">Tambah uang belanja</p>
        <FormGroup>
          <Label>Jumlah</Label>
          <InputGroup>
            <InputGroupText className="bg-transparent">Rp</InputGroupText>
            <AmountInput
              placeholder="Masukkan jumlah topup"
              name="amountInput"
              id="amountInput"
              max={100000000000}
              cb={handleTotalAmount}
            />
          </InputGroup>
        </FormGroup>
        {amount && amount < 10000 && amount !== 0 ? (
          <FormGroup>
            <small className="text-danger text-center">
              <AlertCircle size={15} /> Minimum topup sebesar Rp 10.000
            </small>
          </FormGroup>
        ) : (
          <></>
        )}
        {errMsg ? (
          <FormGroup>
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </FormGroup>
        ) : (
          <></>
        )}
        <CustomButton
          disabled={!amount || loading || amount < 10000}
          onClick={() => handleSubmit()}
          color="primary"
          block
        >
          Lanjutkan Pembayaran
        </CustomButton>
      </Card>
    </Container>
  )
}

export default TopupOnePayment
