import { createApiSlice } from 'app/createApiSlice'
import { API_COMPANIES } from 'utils/apiList'

export const companiesListApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'companiesList/api',
  endpoints(builder) {
    return {
      fetchCompanies: builder.query({
        query({ page, size = 10, searchvalue }) {
          return {
            url: API_COMPANIES,
            params: {
              page,
              size,
              searchvalue,
            },
          }
        },
      }),
      deleteCompanyById: builder.mutation({
        query({ id }) {
          return {
            url: `${API_COMPANIES}/${id}`,
            method: 'DELETE',
          }
        },
      }),
    }
  },
})

export const {
  useFetchCompaniesQuery,
  useLazyFetchCompaniesQuery,
  useDeleteCompanyByIdMutation,
} = companiesListApiSlice
